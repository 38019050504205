import React, { useState } from 'react';
import { Button, Col, Row } from 'antd';
import { RouteComponentProps } from 'react-router';
import { TableScroll } from 'client/ui/table/TableScroll';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { CurrentInstanceApi } from '../CurrentInstanceApi';
import { ColumnProps, SorterResult } from 'antd/lib/table';
import { ICurrentInstance } from 'common/schema/CurrentInstance';
import { ISODate } from 'client/ui/display/ISODate';
import { Link } from 'react-router-dom';
import { CurrentInstanceFormModal } from '../view/CurrentInstanceFormModal';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { ICurrentInstancesListQueryDto } from 'common/dto/CurrentInstanceDto';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { ButtonConfirm } from 'client/ui/button/ButtonConfirm';
import { CurrentInstanceDeleteButton } from '../components/CurrentInstanceDeleteButton';
import { ColumnSortDirections } from 'common/dto/shared/ColumnSortDirections';

export interface CurrentInstanceListPageProps extends RouteComponentProps {}

/**
 * Pagina Con l'elenco delle istanze dell'archivio corrente (C-Prot)
 */
export function CurrentInstanceListPage(props: CurrentInstanceListPageProps) {
  const [query, setQuery] = useState<ICurrentInstancesListQueryDto>({
    page: 1,
    size: 20,
    orderBy: 'createdAt',
    orderDirection: ColumnSortDirections.descend
  });

  const { response, error, loading } = useApiQuery(CurrentInstanceApi.list, {
    data: { query }
  });

  const columns: ColumnProps<ICurrentInstance>[] = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      width: 100,
      sorter: true
    },
    {
      key: 'name',
      dataIndex: 'name',
      className: 'bold-column',
      title: 'Nome',
      sorter: true
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Data di Registrazione',
      sorter: true,
      render: createdAt => <ISODate date={createdAt} />
    },
    {
      key: 'actions',
      align: 'right',
      className: 'actions',
      width: 220,
      render: (_, instance) => (
        <Link to={`/current-instance/view/${instance.id}`}>
          <Button size="small" icon="eye">
            Dettaglio
          </Button>
        </Link>
      )
    }
  ];

  if (error)
    return (
      <AlertPage
        message="Impossibile caricare l'elenco delle Istanze di Archivio Corrente"
        description={error.message}
        type="error"
        showIcon
      />
    );

  return (
    <PageLayout
      hasScrollable
      hasMenu
      fullTable
      title={
        <CustomCardTitle
          image="instance"
          title="Elenco Istanze Archivio Corrente | C-PROT"
        />
      }
      extra={
        <CurrentInstanceFormModal
          buttonType="primary"
          buttonIcon="plus"
          instanceId="create"
        />
      }
    >
      <TableScroll
        loading={tableLoaderSpin(loading || !response)}
        dataSource={response?.data.items ?? []}
        columns={columns}
        onChange={(pagination, filters, sorter) => {
          const { field, order } = sorter as SorterResult<ICurrentInstance>;
          setQuery({
            ...query,
            page: pagination.current || query.page,
            size: pagination.pageSize || query.size,
            orderBy: order ? field.toString() : 'createdAt',
            orderDirection: order
              ? ColumnSortDirections[order]
              : ColumnSortDirections.descend
          });
        }}
        pagination={{
          size: 'small',
          position: 'bottom',
          showSizeChanger: true,
          total: response?.data.meta.total,
          pageSize: query.size,
          current: query.page
        }}
      />
    </PageLayout>
  );
}
