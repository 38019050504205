import React from 'react';
import clx from 'classnames';
import { Layout } from 'antd';
const { Content } = Layout;
import { MainLayout } from './MainLayout';
import { CardProps } from 'antd/lib/card';
import { PageCard } from './PageCard';
import './PageLayout.scss';

export interface PageLayoutProps extends CardProps {
  /**
   * hasMenu? - Specifica che è presente il menu principale
   * ps al momento l'Header con logo etc. viene sempre mostrata
   */
  hasMenu?: boolean;
  /**
   * Link alla pagina del pulsante "Torna" / "Indietro" da mostrare nel menu
   */
  linkBack?: string;
  /**
   * Testo che segue l'icona pulsante "Torna" / "Indietro" da mostrare nel menu
   * se non viene inserito mostra "Torna all'elenco"
   */
  textBack?: React.ReactNode;
  /**
   * hasFooterMenu? - Specifica che è presente in basso il menu contestuale della pagina
   */
  hasFooterMenu?: boolean;
  /**
   * hasScrollable? - Impostare se all'interno della pagina (props.children)
   * è presente il componente <Scrollable> oppure <ColScroll> (probabilmente sempre)
   * Modifica gli stili (con flex) portare il body della card principale a tutta altezza pagina
   */
  hasScrollable?: boolean;
  /**
   * fixedHeader? - Modifica gli stili per fissare la barra superiore dell'applicazione
   * da absolute a fixed TODO: verificare dove è utilizzato
   */
  fixedHeader?: boolean;
  /**
   * excludeCard? - esclude dal render il componente PageCard
   * per mostrare la pagina senza la card principale
   * Utilizzato per Pagine Documenti e Fascicoli che hanno una Card differente
   * Non compatibile con fullTable
   */
  excludeCard?: boolean;
  /**
   * fullTable? - interface per PageCard
   * Per mostrare una Tabella che riempie completamente il body della Card principale
   * Non compatibile con excludeCard
   */
  fullTable?: boolean;
}

/**
 * Layout della pagina principale dell'applicazione.
 * Contiene la testata, il footer fisso e la Card principale pella pagina.
 */
export function PageLayout(props: PageLayoutProps) {
  const {
    hasMenu,
    linkBack,
    textBack,
    hasFooterMenu,
    hasScrollable,
    fixedHeader,
    excludeCard,
    fullTable,
    ...othersProps
  } = props;
  const mainLayautProps = { hasMenu, linkBack, textBack, fixedHeader };

  return (
    <MainLayout {...mainLayautProps}>
      <Layout
        className={clx('page-layout', {
          'has-menu': hasMenu,
          'has-footer-menu': hasFooterMenu
        })}
      >
        <Content
          className={clx('main-page-content', {
            'has-scrollable': hasScrollable,
            'exclude-card': excludeCard
          })}
        >
          {props.excludeCard ? (
            props.children
          ) : (
            <PageCard
              fullTable={fullTable}
              hasFooterBar={hasFooterMenu}
              hasScrollable={hasScrollable}
              {...othersProps}
            >
              {props.children}
            </PageCard>
          )}
        </Content>
      </Layout>
    </MainLayout>
  );
}
