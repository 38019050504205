import { Reducer } from 'redux';
import { createAction, ActionTypes } from 'client/core/store/actions';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from 'client/core/store/RootReducer';
import { IUser } from 'common/schema/User';

export const AuthActions = {
  loginSucceed: createAction('@auth/loginSucceed', (nonce: string) => ({
    nonce
  })),
  tokenRefreshed: createAction('@auth/tokenRefreshed', (nonce: string) => ({
    nonce
  })),
  logout: createAction('@auth/logout'),
  tokenExpired: createAction('@auth/tokenExpired'),
  currentLoaded: createAction('@auth/currentLoaded', (current: IUser) => ({
    current
  }))
};

const IS_LOGGED_KEY = 'mdc_isLogged';

export interface AuthState {
  isLogged: boolean;
  /**
   * Viene fornito un nonce randomico ogni volta che viene effettuato un nuovo
   * login (chiamata ad access token).
   */
  nonce: string | null;
  isTokenExpired: boolean;
  current: IUser | null;
}

/**
 * Carichiamo i dati iniziali dal `sessionStorage` in caso siano
 * presenti.
 */
function initialStateFromLocalStorage() {
  const isLogged = window.localStorage.getItem(IS_LOGGED_KEY);
  return { isLogged: isLogged != null };
}

export const AuthReducer: Reducer<
  AuthState,
  ActionTypes<typeof AuthActions>
> = (
  state = {
    isLogged: false,
    nonce: null,
    isTokenExpired: false,
    current: null,
    ...initialStateFromLocalStorage()
  },
  action
) => {
  switch (action.type) {
    /**
     * Al login, salviamo il token.
     */
    case '@auth/loginSucceed':
      window.localStorage.setItem(IS_LOGGED_KEY, 'true');
      return {
        ...state,
        isLogged: true,
        nonce: action.payload.nonce,
        isTokenExpired: false,
        current: null
      };

    /**
     * Quando il token viene refreshato correttamente, non reimposto il
     * `current` perché è ancora valido (almeno parzialmente) il precedente.
     */
    case '@auth/tokenRefreshed':
      window.localStorage.setItem(IS_LOGGED_KEY, 'true');
      return {
        ...state,
        isLogged: true,
        nonce: action.payload.nonce,
        isTokenExpired: false
      };

    /**
     * Utente corrente caricato. Quando questa richiesta ha esito positivo,
     * vuol dire che l'utente è autorizzato quindi possiamo impostare il token.
     */
    case '@auth/currentLoaded':
      window.localStorage.setItem(IS_LOGGED_KEY, 'true');
      return {
        ...state,
        isLogged: true,
        isTokenExpired: false,
        current: action.payload.current
      };

    /**
     * In caso di scadenza del token lo registriamo.
     */
    case '@auth/tokenExpired':
      return { ...state, isTokenExpired: true };

    /**
     * Rimozione del token dalla sessione.
     */
    case '@auth/logout':
      window.localStorage.removeItem(IS_LOGGED_KEY);
      return {
        ...state,
        isLogged: false,
        nonce: null,
        isTokenExpired: false,
        current: null
      };

    default:
      return state;
  }
};

/**
 * Hook per sapere se si è loggati.
 */
export const useAuth = () =>
  useSelector(
    (state: RootState) => ({
      logged: state.auth.isLogged,
      nonce: state.auth.nonce
    }),
    shallowEqual
  );

export const useCurrentUser = () =>
  useSelector((state: RootState) => state.auth.current, shallowEqual);
