import { apiClient } from 'client/core/network/apiClient';
import {
  GlobalConservationPolicyDto,
  IGlobalConservationPolicyQueryDto
} from 'common/dto/GlobalConservationPolicyDto';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';
import { IGlobalConservationPolicy } from 'common/schema/GlobalConservationPolicy';
import { GlobalConservationPolicyProcessResultQueryDto } from 'common/dto/GlobalConservationPolicyProcessResultDto';
import { IConservationPolicyProcessResult } from 'common/schema/ConservationPolicyProcessResult';
import { IGlobalConservationPolicyProcess } from 'common/schema/GlobalConservationPolicyProcess';

export const GlobalConservationPolicyApi = {
  list: (opts: { query: IGlobalConservationPolicyQueryDto }) =>
    apiClient.get<PaginatedResult<IGlobalConservationPolicy>>(
      `/global-conservation-policy`,
      { params: opts.query }
    ),

  find: (opts: { id: number }) =>
    apiClient.get<IGlobalConservationPolicy>(
      `/global-conservation-policy/${opts.id}`
    ),

  create: (opts: { input: GlobalConservationPolicyDto }) =>
    apiClient.post<IGlobalConservationPolicy>(
      `/global-conservation-policy`,
      opts.input
    ),

  delete: (opts: { id: number }) =>
    apiClient.delete(`/global-conservation-policy/${opts.id}`),

  update: (opts: { id: number; input: GlobalConservationPolicyDto }) =>
    apiClient.put<IGlobalConservationPolicy>(
      `/global-conservation-policy/${opts.id}`,
      opts.input
    ),

  actions: {
    active: (opts: { id: number }) =>
      apiClient.post(`/global-conservation-policy/${opts.id}/actions/active`),

    disable: (opts: { id: number }) =>
      apiClient.post(`/global-conservation-policy/${opts.id}/actions/disable`)
  },

  listLogs: (opts: {
    policyId: number;
    processId: number;
    query: GlobalConservationPolicyProcessResultQueryDto;
  }) =>
    apiClient.get<PaginatedResult<IConservationPolicyProcessResult>>(
      `/global-conservation-policy/${opts.policyId}/processes/${opts.processId}/results`,
      { params: opts.query }
    )
};
