import React, { Suspense, useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
  useHistory
} from 'react-router';
import { Spin, Modal } from 'antd';
import { useAuth } from 'client/components/auth/AuthModule';
import { LoginRoute } from 'client/components/auth/login/LoginRoute';
import { LogoutButton } from 'client/components/auth/logout/LogoutButton';
import { AuthCurrentLoader } from 'client/components/auth/current-loader/AuthCurrentLoader';
import { NotFoundPage } from 'client/components/not-found/NotFoundPage';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { CurrentInstanceListPage } from 'client/components/current-instance/list/CurrentInstanceListPage';
import { CurrentInstanceViewPage } from 'client/components/current-instance/view/CurrentInstanceViewPage';
import { DepositInstanceViewPage } from 'client/components/deposit-instance/view/DepositInstanceViewPage';
import { DepositListPage } from 'client/components/deposit/list/DepositListPage';
import { DepositPolicyEditPage } from 'client/components/deposit-policy/edit/DepositPolicyEditPage';
import { DepositViewPage } from 'client/components/deposit/view/DepositViewPage';
import { ConservationInstanceListPage } from 'client/components/conservation-policy/instance/list/ConservationInstanceListPage';
import { ConservationInstancePolicyListPage } from 'client/components/conservation-policy/instance/view/ConservationInstancePolicyListPage';
import { DepositPolicyListPage } from 'client/components/deposit-policy/list/DepositPolicyListPage';
import { UsersListPage } from 'client/components/users/list/UsersListPage';
import { UserEditPage } from 'client/components/users/edit/UserEditPage';
import { DashboardPage } from 'client/components/dashboard/DashboardPage';
import { GlobalConservationPolicyEditPage } from 'client/components/global-conservation-policy/edit/GlobalConservationPolicyEditPage';
import { GlobalConservationPolicyProcessLogListPage } from 'client/components/global-conservation-policy/log/GlobalConservationPolicyProcessLogListPage';
import { GlobalConservationPolicyListPage } from 'client/components/global-conservation-policy/list/GlobalConservationPolicyListPage';
// import { AppRoutes } from './app/AppRoutes';
// import { LoginRoute } from 'client/components/auth/login/LoginRoute';
// import { PrivateRoute } from 'client/components/auth/PrivateRoute';

interface Props extends RouteComponentProps {}

export const Routes = () => {
  const { logged } = useAuth();
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      Modal.destroyAll();
    });
    return () => {
      unlisten();
    };
  }, []);

  return (
    <AuthCurrentLoader>
      {!logged ? (
        <Switch>
          <Route path="/login" component={LoginRoute} />
          <Redirect to={{ pathname: '/login' }} />
        </Switch>
      ) : (
        <Switch>
          {/* Istanze Applicative */}
          <Route
            path="/current-instance/list"
            component={CurrentInstanceListPage}
          />
          <Route
            path="/current-instance/view/:instanceId"
            component={CurrentInstanceViewPage}
          />
          <Route
            path="/deposit-instance/view"
            component={DepositInstanceViewPage}
          />
          {/* Utenti */}
          <Route path="/users/list" component={UsersListPage} />
          <Route path="/users/edit/:id" component={UserEditPage} />
          {/* Versamenti */}
          <Route path="/deposit/list" component={DepositListPage} />
          <Route path="/deposit/view/:id" component={DepositViewPage} />
          <Route
            path="/deposit-policy/list"
            component={DepositPolicyListPage}
          />
          <Route
            path="/deposit-policy/edit/:id"
            component={DepositPolicyEditPage}
          />
          {/* Conservazione */}
          <Route
            path="/conservation/instance/list"
            component={ConservationInstanceListPage}
          />
          <Route
            path="/conservation/instance/:instanceId/policies"
            component={ConservationInstancePolicyListPage}
          />
          {/* <Route
            path="/conservation/area/list/category"
            component={ConservationAreaCategoryPage}
          />
          <Route
            path="/conservation/area/list"
            component={ConservationAreaListPage}
          /> */}
          <Route path="/home" component={DashboardPage} />
          {/* Policy globali di conservazione */}
          <Route
            path="/global-conservation-policy/list"
            component={GlobalConservationPolicyListPage}
          />
          <Route
            path="/global-conservation-policy/process-log/:id"
            component={GlobalConservationPolicyProcessLogListPage}
          />

          <Route
            path="/global-conservation-policy/edit/:id"
            component={GlobalConservationPolicyEditPage}
          />

          {/* <Route path="/admin" component={AdminRoute} /> DepositViewPage */}
          <Redirect from="/login" exact to="/" />
          <Redirect from="/" exact to="/home" />
          <Route component={NotFoundPage} />
        </Switch>
      )}
    </AuthCurrentLoader>
  );
};
