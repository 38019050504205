import { Row, Col, Form, Radio } from 'antd';
import { FormItem } from 'client/ui/form/FormItem';
import { CheckboxInputItem } from 'client/ui/form/input/CheckboxInputItem';
import { DatePickerInputItem } from 'client/ui/form/input/DatePickerInputItem';
import { NumberInputItem } from 'client/ui/form/input/NumberInputItem';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';
import { GlobalPolicyType } from 'common/schema/GlobalConservationPolicy';
import { useField } from 'formik';
import React from 'react';
import { ExpirationTypeSelectInputItem } from '../ExpirationTypeSelectInputItem';
import { CreatedAtInputForm } from './CreatedAtInputForm';
import { PrintedAtInputForm } from './PrintedAtInputForm';
import { ProtocolAtInputForm } from './ProtocolAtInputForm';
import { ProtocolTypeCheckListInput } from './ProtocolTypeCheckListInput';
import { FormatSelectInputItem } from '../FormatSelectInputItem';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { ConservationStateRadioInput } from '../ConservationStateRadioInput';
import { TypologySelectInputItem } from '../TypologySelectInputItem';

export interface IGlobalConservationPolicyFormProps {
  disabled?: boolean;
}

export function GlobalConservationPolicyForm(
  props: IGlobalConservationPolicyFormProps
) {
  const { disabled } = props;
  const [policyType] = useField<GlobalPolicyType>('type');

  return (
    <>
      {policyType.value && (
        <ConservationStateRadioInput disabled={disabled} name="depositState" />
      )}
      {policyType.value == GlobalPolicyType.Document ? (
        <>
          <ProtocolTypeCheckListInput disabled={disabled} />
          <TypologySelectInputItem disabled={disabled} />
          <FormatSelectInputItem disabled={disabled} />
          {/* TODO VALIDAZIONE   */}
          <FormItem label="Firme digitali" names={['signed', 'notSigned']}>
            <Row>
              <Col span={8}>
                <CheckboxInput disabled={disabled} name="signed">
                  Documenti firmati
                </CheckboxInput>
              </Col>
              <Col span={8}>
                <CheckboxInput disabled={disabled} name="notSigned">
                  Documenti non firmati
                </CheckboxInput>
              </Col>
            </Row>
          </FormItem>
          <FormItem label="Marche Temporali" names={['marked', 'notMarked']}>
            <Row>
              <Col span={8}>
                <CheckboxInput disabled={disabled} name="marked">
                  Documenti marcati
                </CheckboxInput>
              </Col>
              <Col span={8}>
                <CheckboxInput disabled={disabled} name="notMarked">
                  Documenti non marcati
                </CheckboxInput>
              </Col>
            </Row>
          </FormItem>
          <ExpirationTypeSelectInputItem
            disabled={disabled}
            name="expirationDateFilterType"
          />
          <CreatedAtInputForm disabled={disabled} />
          <ProtocolAtInputForm disabled={disabled} />
        </>
      ) : policyType.value == GlobalPolicyType.Stamp ? (
        <>
          <Row gutter={12}>
            <Col span={10}>
              <NumberInputItem
                disabled={disabled}
                label="Anno di stampa"
                placeholder="Anno di stampa"
                name="printYear"
                style={{ width: '100%' }}
              />
            </Col>
          </Row>
          <PrintedAtInputForm disabled={disabled} />
        </>
      ) : null}
    </>
  );
}
