import React from 'react';
import {
  IConservationPolicyProcessResult,
  ConservationPolicyProcessResultType
} from 'common/schema/ConservationPolicyProcessResult';
import { Tag, Row, Col, Divider } from 'antd';

export interface ILogOperationTagProps {
  log: IConservationPolicyProcessResult;
}

export function LogOperationTag(props: ILogOperationTagProps) {
  const { log } = props;
  return (
    <>
      {log.created && <Tag>Creata</Tag>}
      {log.activated && <Tag>Attivata</Tag>}
      {log.disabled && <Tag>Disattivata</Tag>}
      {!(log.activated || log.disabled || log.created) &&
        log.type === ConservationPolicyProcessResultType.Success && (
          <Tag>Nessuna</Tag>
        )}
    </>
  );
}
