import { yup } from 'common/validation/initYup';
import {
  GlobalPolicyDepositState,
  GlobalPolicyExpirationType,
  GlobalPolicyPeriodType,
  GlobalPolicyType,
  PolicyInterestDateType
} from 'common/schema/GlobalConservationPolicy';
import { StringSchema, NumberSchema, DateSchema } from 'yup';
import { ColumnSortDirections } from './shared/ColumnSortDirections';

/** TODO Migliorare valiadazione con condizioni */
export const globalConservationPolicyValidator = yup
  .object({
    description: yup.string().required().label('Descrizione'),
    code: yup.string().required().label('Codice policy'),
    periodicity: yup
      .string()
      .required()
      .oneOfEnum(GlobalPolicyPeriodType)
      .label('Periodicità policy') as StringSchema<GlobalPolicyPeriodType>,
    /* n. giorno di esecuzione (da 1 a 7 oppure da 1 a 31 (?)) */
    executionDay: yup
      .number()
      .label('Giorno esecuzione')
      .when(
        'periodicity',
        (periodicity: GlobalPolicyPeriodType, schema: NumberSchema) => {
          // Se settimanale, indicare il giorno (1-7)
          if (periodicity === GlobalPolicyPeriodType.Weekly) {
            return schema.required().min(1).max(7);
          }
          // Se è mensile o annuale, va indicato il giorno del mese (1-31)
          if (
            periodicity === GlobalPolicyPeriodType.Yearly ||
            periodicity === GlobalPolicyPeriodType.Monthly
          )
            return schema.required().min(1).max(31);
          return schema.strip(true);
        }
      ),

    /* n. mese di esecuzione (da 1 a 12) */
    executionMonth: yup
      .number()
      .label('Mese esecuzione')
      .when(
        'periodicity',
        (periodicity: GlobalPolicyPeriodType, schema: NumberSchema) => {
          if (periodicity === GlobalPolicyPeriodType.Yearly)
            return schema.required().min(1).max(12);
          return schema.notRequired().nullable().strip(true);
        }
      ),
    /** Data esecuzione policy (se periodicity è O) */
    executionDate: yup
      .date()
      .keepOnlyIf('periodicity', GlobalPolicyPeriodType.Fixed, {
        required: true
      })
      .label('Data esecuzione'),

    /** (A) Protocollo in Arrivo */
    protocolIncoming: yup
      .boolean()
      .required()
      .label('Selezione protocolli in arrivo')
      .when('type', (type: GlobalPolicyType, schema: any) =>
        type === GlobalPolicyType.Document
          ? schema
          : schema.notRequired().default(false)
      ),
    /** (P) Protocollo in uscita */
    protocolOutgoing: yup
      .boolean()
      .required()
      .label('Selezione protocolli in uscita')
      .when('type', (type: GlobalPolicyType, schema: any) =>
        type === GlobalPolicyType.Document
          ? schema
          : schema.notRequired().default(false)
      ),
    /** (I) Protocollata interno */
    protocolInternal: yup
      .boolean()
      .required()
      .label('Selezione protocolli interni')
      .when('type', (type: GlobalPolicyType, schema: any) =>
        type === GlobalPolicyType.Document
          ? schema
          : schema.notRequired().default(false)
      ),
    /** (G) Protocollato grigio*/
    grey: yup
      .boolean()
      .required()
      .label('Selezione protocolli grigi')
      .when('type', (type: GlobalPolicyType, schema: any) =>
        type === GlobalPolicyType.Document
          ? schema
          : schema.notRequired().default(false)
      ),
    typology: yup
      .string()
      .max(1000)
      .label('Tipologia')
      .keepOnlyIf('type', GlobalPolicyType.Document),
    format: yup
      .array()
      .of(yup.string().max(256))
      .default([])
      .keepOnlyIf('type', GlobalPolicyType.Document)
      .label('Estensione del file'),
    signed: yup
      .boolean()
      .default(false)
      .label('Firmati')
      .when('type', (type: GlobalPolicyType, schema: any) =>
        type === GlobalPolicyType.Document
          ? schema
          : schema.notRequired().default(false)
      ),
    marked: yup
      .boolean()
      .default(false)
      .label('Marcati')
      .when('type', (type: GlobalPolicyType, schema: any) =>
        type === GlobalPolicyType.Document
          ? schema
          : schema.notRequired().default(false)
      ),
    notSigned: yup
      .boolean()
      .default(false)
      .label('Non Firmati')
      .when('type', (type: GlobalPolicyType, schema: any) =>
        type === GlobalPolicyType.Document
          ? schema
          : schema.notRequired().default(false)
      ),
    notMarked: yup
      .boolean()
      .default(false)
      .label('Non Marcati')
      .when('type', (type: GlobalPolicyType, schema: any) =>
        type === GlobalPolicyType.Document
          ? schema
          : schema.notRequired().default(false)
      ),

    expirationDateFilterType: yup
      .string()
      .oneOfEnum(GlobalPolicyExpirationType)
      .keepOnlyIf('type', GlobalPolicyType.Document) as StringSchema<
      GlobalPolicyExpirationType
    >,

    createdAtFilterType: yup
      .string()
      .oneOfEnum(PolicyInterestDateType)
      .keepOnlyIf('type', GlobalPolicyType.Document) as StringSchema<
      PolicyInterestDateType
    >,
    createdAtFrom: yup
      .date()
      .label('Da data creazione')
      .keepOnlyIf(
        'createdAtFilterType',
        [PolicyInterestDateType.Range, PolicyInterestDateType.Single],
        { required: true }
      )
      .keepOnlyIf('type', GlobalPolicyType.Document),
    createdAtTo: yup
      .date()
      .label('A data creazione')
      .keepOnlyIf('createdAtFilterType', PolicyInterestDateType.Range, {
        required: true
      })
      .keepOnlyIf('type', GlobalPolicyType.Document),
    /** giorni quando tipo data creazione = n. giorni prima (DaysBefore)*/
    createdAtDaysRange: yup
      .number()
      .label('Giorni dalla creazione')
      .keepOnlyIf('createdAtFilterType', PolicyInterestDateType.DaysBefore, {
        required: true
      })
      .keepOnlyIf('type', GlobalPolicyType.Document),

    protocolDateFilterType: yup
      .string()
      .oneOfEnum(PolicyInterestDateType)
      .keepOnlyIf('type', GlobalPolicyType.Document) as StringSchema<
      PolicyInterestDateType
    >,
    protocolDateFrom: yup
      .date()
      .label('Da data protocollazione')
      .keepOnlyIf(
        'protocolDateFilterType',
        [PolicyInterestDateType.Single, PolicyInterestDateType.Range],
        { required: true }
      )
      .keepOnlyIf('type', GlobalPolicyType.Document),
    protocolDateTo: yup
      .date()
      .label('A data protocollazione')
      .keepOnlyIf('protocolDateFilterType', PolicyInterestDateType.Range, {
        required: true
      })
      .keepOnlyIf('type', GlobalPolicyType.Document),
    /** numero di giorni data protocollo se tipo di protocollo = P */
    protocolDateDaysRange: yup
      .number()
      .label('Giorni dalla protocollazione')
      .keepOnlyIf('protocolDateFilterType', PolicyInterestDateType.DaysBefore, {
        required: true
      })
      .keepOnlyIf('type', GlobalPolicyType.Document),

    type: yup
      .string()
      .required()
      .oneOfEnum(GlobalPolicyType)
      .label('Tipo di Policy') as StringSchema<GlobalPolicyType>,

    printDateFilterType: yup
      .string()
      .oneOfEnum(PolicyInterestDateType)
      .keepOnlyIf('type', GlobalPolicyType.Stamp) as StringSchema<
      PolicyInterestDateType
    >,
    printDateFrom: yup
      .date()
      .label('Da data stampa')
      .keepOnlyIf(
        'printDateFilterType',
        [PolicyInterestDateType.Single, PolicyInterestDateType.Range],
        { required: true }
      )
      .keepOnlyIf('type', GlobalPolicyType.Stamp),
    printDateTo: yup
      .date()
      .label('A data stampa')
      .keepOnlyIf('printDateFilterType', PolicyInterestDateType.Range, {
        required: true
      })
      .keepOnlyIf('type', GlobalPolicyType.Stamp),
    printYear: yup
      .number()
      .min(1900)
      .label('Anno stampa')
      .keepOnlyIf('type', GlobalPolicyType.Stamp),
    /** numero di giorni data stampa se tipo di protocollo = P */
    printDateDaysRange: yup
      .number()
      .label('Giorni dalla stampa')
      .keepOnlyIf('printDateFilterType', PolicyInterestDateType.DaysBefore, {
        required: true
      })
      .keepOnlyIf('type', GlobalPolicyType.Stamp),

    /** non conservato, R (rifiutato), F(fallito)*/
    depositState: yup
      .string()
      .required()
      .label('Stato conservazione')
      .oneOfEnum(GlobalPolicyDepositState) as StringSchema<
      GlobalPolicyDepositState
    >
  })
  .noUnknown()
  .required();

export type GlobalConservationPolicyDto = yup.InferType<
  typeof globalConservationPolicyValidator
>;

// Query elenco
export const globalConservationPolicyQueryValidator = yup
  .object({
    description: yup.string().notRequired().label('Descrizione policy'),
    types: yup
      .array()
      .of(
        yup.string().required().oneOfEnum(GlobalPolicyType) as StringSchema<
          GlobalPolicyType
        >
      )
      .notRequired()
      .label('Tipi di policy'),
    state: yup
      .object({
        active: yup.boolean().notRequired(),
        disable: yup.boolean().notRequired()
      })
      .notRequired(),
    size: yup.number().positive().integer().default(10),
    page: yup.number().positive().integer().default(1),
    orderBy: yup.string().notRequired().nullable().default('createdAt'),
    orderDirection: yup
      .string()
      .oneOfEnum(ColumnSortDirections)
      .notRequired()
      .nullable()
      .default(ColumnSortDirections.descend)
  })
  .noUnknown();

export type IGlobalConservationPolicyQueryDto = yup.InferType<
  typeof globalConservationPolicyQueryValidator
>;
