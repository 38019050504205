import React, { useState } from 'react';
import { Button, Icon, Tag } from 'antd';
import { RouteComponentProps } from 'react-router';
import { TableScroll } from 'client/ui/table/TableScroll';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { Link } from 'react-router-dom';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { CurrentInstanceApi } from 'client/components/current-instance/CurrentInstanceApi';
import { ConservationPolicyApi } from '../../ConservationPolicyApi';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { IConservationPolicy } from 'common/schema/ConservationPolicy';
import { ColumnProps, SorterResult } from 'antd/lib/table';
import { ConservationTogglePolicyButton } from '../../detail/ConservationTogglePolicyButton';
import { IConservationPolicyQueryDto } from 'common/dto/ConservationPolicyDto';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import {
  createColumnFilter,
  FilterInputType,
  getFilter
} from 'client/ui/table/TableColumnFilter';

interface Params {
  instanceId: string;
}

export interface ConservationInstancePolicyListProps
  extends RouteComponentProps<Params> {}

/**
 * Pagina di Gestione delle Policy di Conservazione
 * di un'Istanza Applicativa di C-Prot
 */
export function ConservationInstancePolicyListPage(
  props: ConservationInstancePolicyListProps
) {
  const instanceId = parseInt(props.match.params.instanceId, 10);

  // Query per la paginazione
  const [query, setQuery] = useState<IConservationPolicyQueryDto>({
    instanceId,
    pageNumber: 1,
    pageSize: 20
  });

  const instance = useApiQuery(CurrentInstanceApi.find, {
    data: {
      id: String(instanceId),
      query: { includeMonitoring: false }
    }
  });
  const policies = useApiQuery(ConservationPolicyApi.find, {
    data: { query }
  });

  if (instance.error || policies.error) {
    return (
      <AlertPage
        message="Impossibile caricare l'elenco delle Policy di conservazione"
        description={getNetworkErrorMessage(policies.error)}
        type="error"
        showIcon
      />
    );
  }

  const columns: ColumnProps<IConservationPolicy>[] = [
    {
      title: 'ID',
      dataIndex: 'uid',
      width: 60
    },
    {
      title: 'Nome Policy',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      title: 'Amministrazione',
      dataIndex: 'organizationName',
      width: '16%',
      ellipsis: true
    },
    {
      title: 'A.O.O.',
      dataIndex: 'areaName',
      width: '16%',
      ellipsis: true
    },
    // {
    //   title: 'Classe Documentale',
    //   dataIndex: 'typologyName',
    //   className: 'bold-column',
    //   width: '16%',
    //   ellipsis: true
    // },
    // {
    //   title: 'Policy',
    //   dataIndex: 'policy',
    //   key: 'policy',
    //   width: 100,
    //   render: (_, record) => (
    //     <>{record.enabled == 'presente' && <Tag color="green">presente</Tag>}</>
    //   )
    // },
    {
      title: 'Stato',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 60,
      render: (_, record) => (
        <>
          {record.enabled ? (
            <Icon
              type="check-circle"
              style={{ fontSize: '18px', color: 'green' }}
            />
          ) : null}
        </>
      )
    },
    {
      title: '',
      key: 'type',
      align: 'center',
      width: 70,
      render: (_, policy) =>
        policy.globalId ? <Tag>Globale</Tag> : <Tag color="blue">Locale</Tag>
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      width: 150,
      render: (_, policy) =>
        !policy.globalId && (
          <div style={{ margin: '4px 0' }}>
            <ConservationTogglePolicyButton
              instance={instance.response!.data.instance}
              policy={policy}
            />
          </div>
        )
    }
  ];

  return (
    <PageLayout
      hasScrollable
      hasMenu
      fullTable
      linkBack="/conservation/instance/list"
      title={
        <CustomCardTitle
          titleLabel="Policy di Conservazione dell'Istanza:"
          image="conservation"
          title={instance.response?.data.instance.name}
        />
      }
      // extra={
      //   <Link to="/conservation/instance/list">
      //     <Button>Torna all'elenco</Button>
      //   </Link>
      // }
    >
      <TableScroll
        loading={tableLoaderSpin(!instance.response || !policies.response)}
        dataSource={policies.response?.data.items ?? []}
        columns={columns}
        onChange={(pagination, filters) => {
          const policyName = getFilter(filters, 'name') as string;

          setQuery({
            ...query,
            policyName,
            pageNumber: pagination.current || query.pageNumber,
            pageSize: pagination.pageSize || query.pageSize
          });
        }}
        pagination={{
          size: 'small',
          position: 'bottom',
          pageSizeOptions: ['5', '10', '20'],
          showSizeChanger: true,
          total: policies.response?.data.meta.total,
          pageSize: query.pageSize,
          current: query.pageNumber
        }}
      />
    </PageLayout>
  );
}
