import { message } from 'antd';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ButtonConfirm } from 'client/ui/button/ButtonConfirm';
import React from 'react';
import { useHistory } from 'react-router';
import { CurrentInstanceApi } from '../CurrentInstanceApi';

export interface ICurrentInstanceDeleteButtonProps {
  targetId: number | null | undefined;
  redirect?: boolean;
  text: string;
}

export function CurrentInstanceDeleteButton(
  props: ICurrentInstanceDeleteButtonProps
) {
  const { targetId, redirect, text } = props;

  const history = useHistory();

  const deleteInstance = useApiMutation(CurrentInstanceApi.delete, {
    data: { id: targetId! },
    invalidates: [CurrentInstanceApi.list]
  });

  const handleDelete = async () => {
    try {
      await deleteInstance({});
      message.success('Istanza scollegata con successo');
      if (redirect) history.push('/current-instance/list');
    } catch (error) {
      message.error(
        `Si è verificato un errore durante l'operazione. Riprovare.`
      );
    }
  };

  return (
    <ButtonConfirm
      type="danger"
      ghost
      title="Sei sicuro di voler rimuovere l'istanza?"
      children={<>{text}</>}
      okButtonProps={{ type: 'danger', ghost: true }}
      disabled={!targetId}
      onConfirm={async () => await handleDelete()}
    />
  );
}
