import { message } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ButtonConfirm } from 'client/ui/button/ButtonConfirm';
import React from 'react';
import { useHistory } from 'react-router';
import { DepositPolicyApi } from '../../DepositPolicyApi';

export interface IDepositPolicyDeleteButtonProps {
  targetId: number | null | undefined;
  redirect?: boolean;
  text: string;
  size?: ButtonProps['size'];
}

export function DepositPolicyDeleteButton(
  props: IDepositPolicyDeleteButtonProps
) {
  const { targetId, redirect, text, size } = props;

  const history = useHistory();

  const deleteInstance = useApiMutation(DepositPolicyApi.delete, {
    data: { id: targetId! },
    invalidates: [DepositPolicyApi.find]
  });

  const handleDelete = async () => {
    try {
      await deleteInstance({});
      if (redirect) history.push('/deposit-policy/list');
      message.success('Policy eliminata con successo');
    } catch (error) {
      message.error(
        `Si è verificato un errore durante l'operazione. Riprovare.`
      );
    }
  };

  return (
    <ButtonConfirm
      type="danger"
      size={size}
      icon="delete"
      title="Sei sicuro di voler eliminare la policy?"
      children={text}
      okButtonProps={{ type: 'danger' }}
      disabled={!targetId}
      onConfirm={async () => await handleDelete()}
    />
  );
}
