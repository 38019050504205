import { Role } from './Role';

const Admin = new Role('Admin', 'Amministratore', [
  'users.read',
  'users.write',
  'users.delete',
  'instances.read',
  'instances.write',
  'instances.delete',
  'instances.sync',
  'policies.read',
  'policies.write',
  'deposit.analyze',
  'deposit.execute',
  'deposit.read',
  'deposit.write',
  'conservation-policies.enable',
  'conservation-policies.read',
  'global-conservation-policy.read',
  'global-conservation-policy.write',
  'current-area.read',
  'dashboard.read'
]);

const System = new Role('System', 'Utente di Sistema', []);

export const Roles = {
  Admin,
  System
};

export type RoleName = keyof typeof Roles;

/**
 * Opzioni per i Ruoli. Da utilizzare lato FE.
 */
export const RolesOptions = [
  { value: Admin.name, label: Admin.displayName },
  { value: System.name, label: System.displayName }
];
