import * as React from 'react';
import { Tag, Icon } from 'antd';
import {
  IDeposit,
  DepositState,
  DepositProcessingState
} from 'common/schema/Deposit';
import { TagProps } from 'antd/lib/tag';
import './DepositBadge.scss';

export interface IDepositBadgeProps {
  deposit: IDeposit;
}

function mapDepositProcessToTagProps(deposit: IDeposit): TagProps {
  if (deposit.state == DepositState.Draft) {
    return { color: 'purple', children: <Icon type="file" /> };
  }
  if (deposit.state == DepositState.Fail) {
    return { color: 'red', children: <Icon type="warning" /> };
  }
  if (deposit.state == DepositState.Complete) {
    return { color: 'green', children: <Icon type="check" /> };
  }
  switch (deposit.processing) {
    case DepositProcessingState.Completed:
      return { color: 'green', children: <Icon type="check" /> };
    case DepositProcessingState.Failed:
      return {
        color: 'red',
        children: (
          <>
            <Icon type="warning" /> Fallito
          </>
        )
      };
    case DepositProcessingState.Queued:
      return {
        color: 'geekblue',
        children: (
          <>
            <Icon type="clock-circle" /> In Coda
          </>
        )
      };
    case DepositProcessingState.Paused:
      return {
        color: 'geekblue',
        children: (
          <>
            <Icon type="pause-circle" /> In Pausa
          </>
        )
      };
    case DepositProcessingState.Running:
      return {
        color: 'blue',
        children: (
          <>
            <Icon type="sync" spin /> In Corso
          </>
        )
      };
    case DepositProcessingState.Stalled:
      return {
        color: 'cyan',
        children: (
          <>
            <Icon type="pause-circle" /> In Stallo
          </>
        )
      };
  }
}

function mapDepositToTagProps(state: DepositState): TagProps {
  switch (state) {
    case DepositState.Start:
      return { color: 'cyan', children: 'Caricamento' };
    case DepositState.Draft:
      return { color: 'purple', children: 'Proposta' };
    case DepositState.Send:
      return { color: 'blue', children: 'Versamento' };
    case DepositState.SentPartial:
      return { color: 'orange', children: 'Versato Parzialmente' };
    case DepositState.SentComplete:
      return { color: 'blue', children: 'Versato' };
    case DepositState.Cleanup:
      return { color: 'geekblue', children: 'Eliminazione' };
    case DepositState.CleanedPartial:
      return { color: 'orange', children: 'Eliminati Parzialmente' };
    case DepositState.Fail:
      return { color: 'red', children: 'Fallito' };
    case DepositState.Complete:
      return { color: 'green', children: 'Completato' };
  }
}

/**
 * Badge per mostrare lo stato di un versamento.
 */
export function DepositBadge(props: IDepositBadgeProps) {
  const { ...tagStatusProps } = mapDepositToTagProps(props.deposit.state);
  const { ...tagProgressProps } = mapDepositProcessToTagProps(props.deposit);
  return (
    <>
      <Tag className="left-side" {...tagStatusProps} />
      <Tag className="right-side" {...tagProgressProps} />
      {props.deposit.requestedPause && <p>Metto in pausa...</p>}
    </>
  );
}

export interface DepositsStateBadgeProps {
  state: DepositState;
}

export function DepositStateBadge(props: DepositsStateBadgeProps) {
  const tagProps = mapDepositToTagProps(props.state);

  return <Tag {...tagProps} />;
}
