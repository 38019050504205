import React from 'react';
import './StaticFooter.scss';

import { Layout } from 'antd';
const { Footer } = Layout;

/**
 * Footer fisso per l'arma dei carabinieri.
 */
export function StaticFooter() {
  return (
    <Footer className="static-footer">
      <div>Copyright © 2020 Ver. {process.env.VERSION}</div>
      <div>
        Comando Generale dell'Arma dei Carabinieri - III Reparto - SM -
        Direzione Telematica
      </div>
    </Footer>
  );
}
