import { apiClient } from 'client/core/network/apiClient';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';
import { ICategoryIndexSelectableQueryDto } from 'common/dto/CategoryIndexDto';
import { ICategoryIndex } from 'common/schema/CategoryIndex';

export const CategoryIndexApi = {
  listSelectable: (opts: { query: ICategoryIndexSelectableQueryDto }) =>
    apiClient.get<ICategoryIndex[]>(
      `/category-indexes/projections/selectable`,
      {
        params: opts.query
      }
    )
};
