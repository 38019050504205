import React from 'react';
import {
  IGlobalConservationPolicyProcess,
  GlobalConservationPolicyProcessState
} from 'common/schema/GlobalConservationPolicyProcess';
import { Icon, Tag } from 'antd';
import { assertNever } from 'server/utils/typings/assertNever';
import './GlobalConservationPolicyProcessStateTag.scss';

export interface IGlobalConservationPolicyProcessStateTagProps {
  process: IGlobalConservationPolicyProcess | undefined;
}

function mapToTagProps(state: GlobalConservationPolicyProcessState) {
  switch (state) {
    case GlobalConservationPolicyProcessState.Running:
      return { color: 'blue', children: 'In Corso' };
    case GlobalConservationPolicyProcessState.Queued:
      return { color: 'geekblue', children: 'In Coda' };
    case GlobalConservationPolicyProcessState.Complete:
      return { color: 'green', children: 'Completato' };
    case GlobalConservationPolicyProcessState.Failed:
      return { color: 'red', children: 'Errore' };
    case GlobalConservationPolicyProcessState.Partial:
      return { color: 'orange', children: 'Parziale' };
    default:
      assertNever(state);
  }
}
function mapIconToTagProps(state: GlobalConservationPolicyProcessState) {
  switch (state) {
    case GlobalConservationPolicyProcessState.Running:
      return { color: 'blue', children: <Icon type="sync" spin /> };
    case GlobalConservationPolicyProcessState.Queued:
      return { color: 'geekblue', children: <Icon type="clock-circle" /> };
    case GlobalConservationPolicyProcessState.Complete:
      return { color: 'green', children: <Icon type="check" /> };
    case GlobalConservationPolicyProcessState.Failed:
      return { color: 'red', children: <Icon type="warning" /> };
    case GlobalConservationPolicyProcessState.Partial:
      return { color: 'orange', children: <Icon type="warning" /> };
    default:
      assertNever(state);
  }
}

export function GlobalConservationPolicyProcessStateTag(
  props: IGlobalConservationPolicyProcessStateTagProps
) {
  const { process } = props;
  if (!process) return null;

  return (
    <>
      <Tag className="left-side" {...mapToTagProps(process.state)} />
      <Tag
        className="right-side no-margin"
        {...mapIconToTagProps(process.state)}
      />
    </>
  );
}
