import { GlobalConservationPolicy } from 'server/schema/organigram/global-conservation-policy/GlobalConservationPolicy.entity';
import {
  GlobalConservationPolicyProcessState,
  IGlobalConservationPolicyProcess
} from 'common/schema/GlobalConservationPolicyProcess';
import {
  IGlobalConservationPolicy,
  GlobalPolicyDepositState
} from 'common/schema/GlobalConservationPolicy';
import { message } from 'antd';

export class GlobalConservationPolicyLogic {
  /**
   * Ritorna vero se il processo è considerato finito o nullo
   */
  static isProcessEnd(
    lastProcess: IGlobalConservationPolicyProcess | undefined | null
  ) {
    // Se non c'è nessun processo, posso crearne uno nuovo
    if (!lastProcess) {
      return true;
    }

    // Se il processo è terminato (completed/failed), posso crearne uno nuovo
    if (
      [
        GlobalConservationPolicyProcessState.Complete,
        GlobalConservationPolicyProcessState.Failed,
        GlobalConservationPolicyProcessState.Partial
      ].includes(lastProcess.state)
    ) {
      return true;
    }

    // Altrimenti, devo attendere
    return false;
  }

  static canBeDeleted(policy: IGlobalConservationPolicy) {
    if (!policy.lastProcess) {
      return { allowed: true };
    }

    if (policy.active) {
      return {
        allowed: false,
        message: 'La policy deve essere disattivata per poter essere eliminata.'
      };
    }

    if (
      policy.lastProcess.state != GlobalConservationPolicyProcessState.Complete
    ) {
      return {
        allowed: false,
        message:
          'Il processo deve essere completato con successo su tutte le istanze per eliminare la policy'
      };
    }

    return {
      allowed: true
    };
  }
}
