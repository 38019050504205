import React from 'react';
import { Popconfirm, Button } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { ButtonProps } from 'antd/lib/button';
import { NativeButtonProps } from 'antd/lib/button/button';

export interface ButtonConfirmProps extends PopconfirmProps {
  loading?: boolean;
  icon?: ButtonProps['icon'];
  type?: ButtonProps['type'];
  size?: ButtonProps['size'];
  children?: React.ReactNode;
  ghost?: boolean;
  // okButtonProps?: NativeButtonProps;
  // disabled?: boolean;
}

export function ButtonConfirm(props: ButtonConfirmProps) {
  const {
    placement,
    loading,
    type,
    size,
    children,
    icon,
    ghost,
    okButtonProps,
    disabled,
    ...otherProps
  } = props;

  const buttonProps = { type, icon, loading, ghost, disabled, size, children };

  if (disabled) return <Button {...buttonProps} />;

  return (
    <Popconfirm
      placement={placement ? placement : 'topRight'}
      {...otherProps}
      okButtonProps={okButtonProps ?? { type: 'primary' }}
    >
      <Button {...buttonProps} />
    </Popconfirm>
  );
}
