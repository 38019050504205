import * as React from 'react';
import { Modal, Alert, message, Button } from 'antd';
import { Formik } from 'formik';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { CurrentInstanceApi } from '../CurrentInstanceApi';
import {
  currentInstanceCreateValidator,
  CurrentInstanceCreateDto,
  currentInstanceUpdateValidator,
  CurrentInstanceUpdateDto
} from 'common/dto/CurrentInstanceDto';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { useHistory } from 'react-router';
import { FormikForm } from 'client/ui/form/FormikForm';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';
import { useState } from 'react';
import { ButtonType } from 'antd/lib/button';
import { ModalFooter } from 'client/ui/modal/ModalFooter';
import { CcTooltip } from 'client/ui/tooltip/CcTooltip';

export interface ICurrentInstanceFormModalProps {
  instanceId: string;
  buttonType: ButtonType;
  buttonText?: string;
  buttonIcon: string;
  title?: string;
  tooltipsText?: string;
}

/**
 * Form Modale per la creazione e la modifica di un'istanza dell'archivio
 * corrente. Contiene il pulsante per l'apertura.
 */
export function CurrentInstanceFormModal(
  props: ICurrentInstanceFormModalProps
) {
  const { instanceId } = props;
  const id = instanceId === 'create' ? null : parseInt(instanceId, 10);

  const [visible, setVisible] = useState(false);
  const actionText =
    props.buttonText != null
      ? props.buttonText
      : id == null
      ? 'Aggiungi Istanza'
      : 'Modifica Istanza';

  const { response, error, loading, refetch } = useApiQuery(
    CurrentInstanceApi.find,
    {
      skip: id == null,
      data: {
        id: String(id!),
        query: {
          includeMonitoring: false
        }
      }
    }
  );

  const history = useHistory();
  const validator = id
    ? currentInstanceUpdateValidator
    : currentInstanceCreateValidator;

  // Modifica
  const create = useApiMutation(CurrentInstanceApi.create, {
    invalidates: [CurrentInstanceApi.find, CurrentInstanceApi.list]
  });
  const update = useApiMutation(CurrentInstanceApi.update, {
    invalidates: [CurrentInstanceApi.find, CurrentInstanceApi.list]
  });

  // TODO: spostare render Errore qui è al posto del pulsante
  if (error)
    return (
      <Alert
        showIcon
        type="error"
        message="Impossibile caricare l'istanza'"
        description={getNetworkErrorMessage(error)}
      />
    );

  const button = (
    <Button
      type={props.buttonType}
      icon={props.buttonIcon}
      disabled={id != null && loading}
      onClick={() => setVisible(true)}
    >
      {actionText}
    </Button>
  );

  return (
    <>
      {props.tooltipsText ? (
        <CcTooltip placement="topLeft" title={props.tooltipsText}>
          {button}
        </CcTooltip>
      ) : (
        button
      )}
      <Formik<CurrentInstanceCreateDto | CurrentInstanceUpdateDto>
        initialValues={
          response && response.data
            ? response.data.instance
            : ({ name: '' } as any)
        }
        enableReinitialize
        validate={schemaToValidator(validator)}
        onSubmit={async (rawValues, helpers) => {
          const values = await validator.validate(rawValues);
          try {
            if (id) {
              await update({ data: { id: id!, input: values } });
              message.success('Istanza aggiornata con successo. ');
            } else {
              const result = await create({
                data: { input: { ...values } as any }
              });
              message.success('Istanza creata con successo. ');
              helpers.resetForm();
            }
            refetch();
            setVisible(false);
          } catch (e) {
            console.error('errors', e);
            message.error(
              "Si è verificato un errore durante l'aggiornamento dell'istanza."
            );
          }
        }}
      >
        {formik => (
          <Modal
            title={props.title ? props.title : actionText}
            visible={visible}
            footer={null}
            onCancel={() => {
              formik.resetForm();
              setVisible(false);
            }}
          >
            <FormikForm layout="vertical">
              <TextInputItem name="name" label="Nome" />
              <TextInputItem
                // Passando una stringa casuale si disabilita l'autocomplete di google
                autoComplete="disable-autocomplete"
                name="frontendUrl"
                label="Indirizzo di Frontend dell'Istanza Applicativa"
              />
              <TextInputItem
                autoComplete="disable-autocomplete"
                name="backendUrl"
                label="Indirizzo Tool di Amministrazione dell'Istanza"
              />
              <TextInputItem
                autoComplete="disable-autocomplete"
                name="apiUrl"
                label="Indirizzo API/WebService dell'Istanza Applicativa"
              />
              <TextInputItem
                autoComplete="disable-autocomplete"
                name="connectionString"
                label="Stringa di Connessione"
                itemProps={{
                  help: id
                    ? 'La stringa di connessione non viene mostrata per ragioni di sicurezza. Inserire un valore per sovrascriverla.'
                    : undefined
                }}
              />
              <ModalFooter>
                <Button htmlType="reset" onClick={() => setVisible(false)}>
                  Annulla
                </Button>
                <Button type="primary" htmlType="submit">
                  Salva
                </Button>
              </ModalFooter>
            </FormikForm>
          </Modal>
        )}
      </Formik>
    </>
  );
}
