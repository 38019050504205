import { Col, Row } from 'antd';
import { CheckboxInputItem } from 'client/ui/form/input/CheckboxInputItem';
import React from 'react';

export interface IProtocolTypeCheckListInputProps {
  disabled?: boolean;
}

export function ProtocolTypeCheckListInput(
  props: IProtocolTypeCheckListInputProps
) {
  const { disabled } = props;
  const style = { display: 'inline-flex', paddingRight: '12px' };
  return (
    <Row gutter={12}>
      <Col
        span={24}
        className="ant-form-item-label"
        style={{ paddingBottom: '5px' }}
      >
        <label title="Tipo documento">Tipo documento</label>
      </Col>
      <Col span={24} style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={style}>
          <CheckboxInputItem
            disabled={disabled}
            label=""
            title="Arrivo"
            name="protocolIncoming"
          />
        </div>
        <div style={style}>
          <CheckboxInputItem
            disabled={disabled}
            label=""
            title="Partenza"
            name="protocolOutgoing"
          />
        </div>
        <div style={style}>
          <CheckboxInputItem
            disabled={disabled}
            label=""
            title="Interni"
            name="protocolInternal"
          />
        </div>
        <div style={style}>
          <CheckboxInputItem
            disabled={disabled}
            label=""
            title="Non Protocollati"
            name="grey"
          />
        </div>
      </Col>
    </Row>
  );
}
