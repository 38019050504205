import React from 'react';
import { Row, Col, Alert, Card } from 'antd';
import { CardDashboard } from 'client/ui/card/CardDashboard';
import { StatisticTitle } from 'client/ui/statistic/StatisticTitle';
import { StatisticCC } from 'client/ui/statistic/StatisticCC';
import moment from 'moment';
import { IconVector } from 'client/ui/icon/IconVector';
import { IDashboadConservationData } from 'common/schema/Dashboard';

export interface DashboardConservationDetailProps {
  conservationDetail: IDashboadConservationData;
}

/**
 * Elemento per mostrare i dati delle Policy Globali di Conservazione
 *
 */
export function DashboardConservationDetail(
  props: DashboardConservationDetailProps
) {
  const { conservationDetail } = props;
  return (
    <CardDashboard
      title={
        <span>
          <IconVector image="conservation" /> Conservazione
        </span>
      }
    >
      <Card size="small" style={{ margin: '12px 0' }}>
        <Row gutter={48}>
          <Col span={24}>
            <StatisticTitle>
              Policy Globali di Versamento in Conservazione
            </StatisticTitle>
          </Col>
          {/* <Col span={8}>
            <StatisticCC
              title="Totali"
              // TODO
              value={20}
            />
          </Col> */}
          <Col span={12}>
            <StatisticCC
              title="Abilitate"
              value={conservationDetail.globalPolicy.activated}
            />
          </Col>
          <Col span={12}>
            <StatisticCC
              title="Disabilitate"
              value={conservationDetail.globalPolicy.disabled}
            />
          </Col>
        </Row>
      </Card>
    </CardDashboard>
  );
}
