import { yup } from 'common/validation/initYup';
import {
  DepositPolicyRule,
  DepositPolicyRuleKind,
  DepositPolicyRules
} from 'common/schema/DepositPolicy';
import { mapValues, pickBy } from 'lodash';
import { ColumnSortDirections } from './shared/ColumnSortDirections';

const filterEmptyRules = <T extends {}>(obj: T) => {
  return pickBy(obj, item => (item as any).value != null);
};

const depositPolicyRuleValidator = yup.object<DepositPolicyRule>({
  value: yup.mixed().required()
});

export const createDepositPolicyValidator = yup
  .object({
    description: yup.string().required().label('Descrizione'),
    rules: yup.lazy((obj: object) =>
      yup.object({
        id_instance_eq: depositPolicyRuleValidator,
        ...mapValues(filterEmptyRules(obj), () => depositPolicyRuleValidator)
      })
    )
  })
  .noUnknown();

export type CreateDepositPolicyDto = yup.InferType<
  typeof createDepositPolicyValidator
>;

// Query elenco
export const depositPolicyFindQueryValidator = yup
  .object({
    description: yup.string().notRequired().nullable(),
    size: yup.number().positive().integer().default(10),
    page: yup.number().positive().integer().default(1),
    orderBy: yup.string().notRequired().nullable().default('createdAt'),
    orderDirection: yup
      .string()
      .oneOfEnum(ColumnSortDirections)
      .notRequired()
      .nullable()
      .default(ColumnSortDirections.descend)
  })
  .noUnknown();

export type IDepositPolicyFindQueryDto = yup.InferType<
  typeof depositPolicyFindQueryValidator
>;
