import axios from 'axios';
import { refreshTokenInterceptor } from './interceptors/refreshTokenInterceptor';
import { errorsInterceptor } from './interceptors/errorsInterceptor';
import { retryInterceptor } from './interceptors/retryInterceptor';
import { appConfig } from '../appConfig';
import { joinPath } from './joinPath';

export const apiClient = axios.create({
  baseURL: joinPath([appConfig.publicPath, process.env.REST_URL])
});

refreshTokenInterceptor(apiClient);
retryInterceptor(apiClient);
errorsInterceptor(apiClient);
