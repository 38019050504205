import * as yup from 'yup';

interface IOptions {
  /** Se il campo è da mantenere, rendilo obbligatorio (required) */
  required?: boolean;
}

declare module 'yup' {
  interface Schema<T> {
    /**
     * Se il campo è uguale a `value` viene mantenuto,
     * altrimenti il validatore rimuove il campo (strip).
     * Se fornito un array di valori, controlla che il campo sia presente nella lista.
     */
    keepOnlyIf(
      field: string,
      value: any | any[],
      options?: IOptions
    ): Schema<T>;
  }
}

yup.addMethod(yup.mixed, 'keepOnlyIf', function (
  field: string,
  input: any | any[],
  options?: IOptions
) {
  return this.when(field, (value: any, schema: yup.MixedSchema) => {
    let check;
    if (Array.isArray(input)) {
      check = input.some(v => v === value);
    } else {
      check = input === value;
    }

    if (check) return options?.required ? schema.required() : schema;

    return schema.notRequired().strip(true);
  });
});
