import React from 'react';
import { FormikValues } from 'formik';
import { FormItem, SingleFormItemProps } from '../FormItem';
import { AsyncSelectInputProps, AsyncSelectInput } from './AsyncSelectInput';

interface Props<T extends FormikValues, A, R, O extends Array<any>>
  extends AsyncSelectInputProps<T, A, R, O> {
  itemProps?: SingleFormItemProps<T>;
  label?: React.ReactNode;
}

/**
 * Form Item per select dinamica.
 */
export function AsyncSelectInputItem<
  T extends FormikValues,
  A,
  R,
  O extends Array<any>
>(props: Props<T, A, R, O>) {
  const { name, label, itemProps, ...otherProps } = props;

  return (
    <FormItem {...itemProps} name={name} label={label}>
      <AsyncSelectInput {...otherProps} name={name} />
    </FormItem>
  );
}
