import React from 'react';
import moment from 'moment';

import { Layout, Typography, Icon, Row, Col } from 'antd';
const { Text } = Typography;
import './TopHeader.scss';
import { CurrentUserHeader } from '../user/CurrentUserHeader';
import { LiveDate } from './live-date/LiveDate';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { resolvePublicPath } from 'client/core/appConfig';

const LogoImg = require('./image/logo-C-CdG.svg');

export interface TopHeaderProps {}

/**
 * Fascia in alto della pagina che contiene il logo
 * il nome e il ruolo dell'utente ed eventualmente il nome della UID
 */
export function TopHeader(props: TopHeaderProps) {
  const currentUser = useCurrentUser();
  return (
    <div className="top-header">
      <Row type="flex" align="middle" justify="start" className="top-name-row">
        <Col className="command-col">
          <Text strong>
            {/* {currentUser && currentUser.command && currentUser.command.name} */}
            <Text strong>Arma dei Carabinieri</Text>
          </Text>
        </Col>
        <Col className="user-col">
          <CurrentUserHeader />
        </Col>
        <div className="logo">
          <img src={resolvePublicPath(LogoImg)} alt="C-CgG" />
        </div>
        <div className="today-date-wrapper">
          <Text className="today-date">
            <LiveDate />
          </Text>
        </div>
      </Row>
    </div>
  );
}
