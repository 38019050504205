import * as React from 'react';
import { Dropdown, Menu, Avatar, Typography, Icon } from 'antd';
import { Link } from 'react-router-dom';

import './CurrentUserHeader.scss';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { Roles } from 'common/permissions/Roles';
import { LogoutButton } from 'client/components/auth/logout/LogoutButton';
const { Text } = Typography;

/**
 * Visualizza il nome ed il ruolo dell'utente loggato.
 */
export function CurrentUserHeader() {
  const currentUser = useCurrentUser();
  if (!currentUser || currentUser.strategy != 'jwt') return null;

  const role = Roles[currentUser.role];

  const userNameDisplay = (
    <div className="name-container">
      <Text className="name">{currentUser.name}</Text>
      {role == Roles.Admin && (
        <Text className="role">- {role.displayName}</Text>
      )}
    </div>
  );

  return (
    <div className="user-header">
      <Avatar icon="user" size="small" />
      {userNameDisplay}
    </div>
  );
}
