import { apiClient } from 'client/core/network/apiClient';
import { ICreateAccessTokenDto } from 'common/schema/Auth';
import { IUser } from 'common/schema/User';

export const AuthApi = {
  /** Creazione dell'access token per il login */
  createAccessToken: (data: ICreateAccessTokenDto) =>
    apiClient.post('/auth/access-token', data),
  refreshToken: () => apiClient.post('/auth/refresh-token'),
  logout: () => apiClient.post('/auth/logout'),
  findCurrentUser: (opts: {}) => apiClient.get<IUser>(`/auth/current`)
};
