import React, { useState } from 'react';
import { Button, message, Modal, Tooltip } from 'antd';
import { useApiMutationState } from 'client/core/network/hooks/useApiMutation';
import { GlobalConservationPolicyApi } from '../GlobalConservationPolicyApi';
import Paragraph from 'antd/lib/typography/Paragraph';
import { ModalFooter } from 'client/ui/modal/ModalFooter';
import { IGlobalConservationPolicy } from 'common/schema/GlobalConservationPolicy';
import { GlobalConservationPolicyLogic } from 'common/logic/GlobalConservationPolicyLogic';

export interface IChangeStateButtonProps {
  policy: IGlobalConservationPolicy;
  changeState: boolean;
}

export function ChangeStateButton(props: IChangeStateButtonProps) {
  const { policy, changeState } = props;

  const [visible, setVisible] = useState(false);

  const [activePolicy, { loading: activeLoading }] = useApiMutationState(
    GlobalConservationPolicyApi.actions.active,
    {
      invalidates: [GlobalConservationPolicyApi.list]
    }
  );

  const [disablePolicy, { loading: disableLoading }] = useApiMutationState(
    GlobalConservationPolicyApi.actions.disable,
    {
      invalidates: [GlobalConservationPolicyApi.list]
    }
  );

  const disabledButton = !GlobalConservationPolicyLogic.isProcessEnd(
    policy.lastProcess
  );

  const isActive = policy.active;

  const handleClick = async () => {
    try {
      if ((!isActive && changeState) || (!changeState && isActive)) {
        await activePolicy({ data: { id: policy.id } });
      } else {
        await disablePolicy({ data: { id: policy.id } });
      }

      setVisible(false);
      message.info(
        `Processo di ${
          (!isActive && changeState) || (!changeState && isActive)
            ? 'attivazione'
            : 'disattivazione'
        } avviato correttamente.`
      );
    } catch (e) {
      message.error(
        `Si è verificato un errore durante l'avvio del processo. Riprovare.`
      );
    }
  };

  return (
    <>
      <Tooltip title={disabledButton ? 'Un processo è ancora in corso' : ''}>
        <Button
          type="primary"
          size="small"
          loading={activeLoading || disableLoading}
          onClick={() => setVisible(true)}
          disabled={disabledButton}
        >
          {changeState ? 'Cambia Stato' : 'Reinvia'}
        </Button>
      </Tooltip>
      <Modal
        title={
          changeState ? (
            <span>
              Vuoi cambiare lo stato della policy globale di conservazione?
            </span>
          ) : (
            <span>Vuoi reinviare la policy globale di conservazione?</span>
          )
        }
        visible={visible}
        footer={null}
        onCancel={() => {
          setVisible(false);
        }}
      >
        {changeState ? (
          <Paragraph>
            La policy risulta{' '}
            <strong>{isActive ? 'Attiva' : 'Non Attiva'}</strong>. Avviando il
            processo di{' '}
            <strong>{!isActive ? 'attivazione' : 'disattivazione'}</strong> la
            policy verrà reinviata a tutte le istanze dell'archivio corrente.
            <br />
            <br />
            Il processo non potrà essere interrotto.
          </Paragraph>
        ) : (
          <Paragraph>
            La policy risulta{' '}
            <strong>{isActive ? 'Attiva' : 'Non Attiva'}</strong>. Avviando il
            processo la policy verrà reinviata a tutte le istanze dell'archivio
            corrente.
            <br />
            <br />
            Il processo non potrà essere interrotto.
          </Paragraph>
        )}

        <ModalFooter>
          <Button htmlType="reset" onClick={() => setVisible(false)}>
            Annulla
          </Button>
          <Button type="primary" onClick={handleClick}>
            {!changeState ? 'Reinvia' : isActive ? 'Disattiva' : 'Attiva'}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
