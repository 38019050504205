import useReactRouter from 'use-react-router';

/**
 * Hook per ottenere la sezione corrente.
 */
export function useRouterCurrent() {
  const { location } = useReactRouter();
  const startKey = 1;
  const mainPath = location.pathname.split('/')[startKey];
  const secondaryPath = location.pathname.split('/')[startKey + 1];
  const selectedKeys = secondaryPath
    ? [mainPath, mainPath + '/' + secondaryPath]
    : [mainPath];
  return selectedKeys;
}
