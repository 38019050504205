import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import './TopHeaderMenu.scss';

import { CcTooltip } from '../tooltip/CcTooltip';
import { LogoutButton } from 'client/components/auth/logout/LogoutButton';
import { IconVector } from '../icon/IconVector';

export interface TopHeaderMenuProps {
  children: React.ReactNode;
  // hasBack?: boolean; // DEPRECATO
  hasHelp?: boolean;
  hasLogout?: boolean;

  /**
   * Link alla pagina del pulsante "Torna" / "Indietro"
   */
  linkBack?: string;
  /**
   * Testo che segue l'icona pulsante "Torna" / "Indietro"
   * se non viene inserito mostra "Torna all'elenco"
   */
  textBack?: React.ReactNode;
}

/**
 * Container del Menu principale dell'applicazione
 */
export function TopHeaderMenu(props: TopHeaderMenuProps) {
  const hasSome = props.linkBack || props.hasHelp || props.hasLogout;

  if (!hasSome) {
    return (
      <Row className="main-menu-wrapper">
        <Col span={24} className="menu-center-column">
          {props.children}
        </Col>
      </Row>
    );
  }

  return (
    <Row className="main-menu-wrapper">
      <Col span={8} className="menu-left-column">
        {props.linkBack && (
          <div className="main-menu-button">
            <CcTooltip placement="topRight" title="Indietro">
              <Link to={props.linkBack} className="main-menu-button-link">
                <IconVector image="navigation_back" />{' '}
                {props.textBack ? props.textBack : "Torna all'elenco"}
              </Link>
            </CcTooltip>
          </div>
        )}
      </Col>

      <Col span={8} className="menu-center-column">
        {props.children}
      </Col>

      <Col span={8} className="menu-right-column">
        {props.hasHelp && (
          <div className="main-menu-button">
            <CcTooltip placement="topRight" title="Aiuto">
              <Link to="/app/admin/demo-ui" className="main-menu-button-link">
                <IconVector image="help" />
              </Link>
            </CcTooltip>
          </div>
        )}
        {props.hasLogout && (
          <div className="main-menu-button">
            <LogoutButton />
          </div>
        )}
      </Col>
    </Row>
  );
}
