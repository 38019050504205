import * as React from 'react';
import Tag, { TagProps } from 'antd/lib/tag';
import { ConservationPolicyProcessResultType } from 'common/schema/ConservationPolicyProcessResult';
import { assertNever } from 'server/utils/typings/assertNever';
import { toPairs } from 'lodash';

export interface ProcessResultStateTagProps {
  type: ConservationPolicyProcessResultType;
}

function mapToTagProps(type: ConservationPolicyProcessResultType): TagProps {
  switch (type) {
    case ConservationPolicyProcessResultType.Error:
      return { color: 'red', children: 'Errore' };
    case ConservationPolicyProcessResultType.Info:
      return { color: 'blue', children: 'Info' };
    case ConservationPolicyProcessResultType.Success:
      return { color: 'green', children: 'Successo' };
    default:
      assertNever(type);
  }
}

/**
 * Mostra lo stato della risposta all'inserimento di una policy globale
 */
export function ProcessResultStateTag(props: ProcessResultStateTagProps) {
  return <Tag {...mapToTagProps(props.type)} />;
}

export const ConservationPolicyProcessResultTypeOptions = toPairs(
  ConservationPolicyProcessResultType
).map(([key, value]) => ({
  label: <ProcessResultStateTag type={value} />,
  value: value
}));
