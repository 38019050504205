import { Col, Row } from 'antd';
import { DatePickerInputItem } from 'client/ui/form/input/DatePickerInputItem';
import { NumberInputItem } from 'client/ui/form/input/NumberInputItem';
import { PolicyInterestDateType } from 'common/schema/GlobalConservationPolicy';
import { useField } from 'formik';
import React from 'react';
import { DateOfInterestSelectInputItem } from '../DateOfInterestSelectInputItem';

export interface IPrintedAtInputFormProps {
  disabled?: boolean;
}

export function PrintedAtInputForm(props: IPrintedAtInputFormProps) {
  const { disabled } = props;
  const [typeField] = useField('printDateFilterType');
  return (
    <Row gutter={12}>
      <Col span={10}>
        <DateOfInterestSelectInputItem
          disabled={disabled}
          name="printDateFilterType"
          label="Tipo data di stampa"
          placeholder="Seleziona un tipo data..."
        />
      </Col>
      {typeField.value === PolicyInterestDateType.Range ? (
        <>
          <Col span={7}>
            <DatePickerInputItem
              disabled={disabled}
              name="printDateFrom"
              type="from"
              label="Da data di stampa"
              disabledTime={() => ({})}
              style={{ width: '100%' }}
            />
          </Col>
          <Col span={7}>
            <DatePickerInputItem
              disabled={disabled}
              name="printDateTo"
              type="to"
              label="A data di stampa"
              disabledTime={() => ({})}
              style={{ width: '100%' }}
            />
          </Col>
        </>
      ) : typeField.value === PolicyInterestDateType.DaysBefore ? (
        <Col span={7}>
          <NumberInputItem
            disabled={disabled}
            name="printDateDaysRange"
            label="Numero di giorni"
            style={{ width: '100%' }}
          />
        </Col>
      ) : typeField.value === PolicyInterestDateType.Single ? (
        <Col span={7}>
          <DatePickerInputItem
            disabled={disabled}
            name="printDateFrom"
            type="from"
            label="Da data di stampa"
            disabledTime={() => ({})}
            style={{ width: '100%' }}
          />
        </Col>
      ) : (
        <></>
      )}
    </Row>
  );
}
