import React, { useState } from 'react';
import { Button } from 'antd';
import { RouteComponentProps } from 'react-router';
import { TableScroll } from 'client/ui/table/TableScroll';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { ColumnProps } from 'antd/lib/table';
import { IDeposit } from 'common/schema/Deposit';
import { ISODate } from 'client/ui/display/ISODate';
import { IDepositFindQueryDto } from 'common/dto/DepositDto';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DepositApi } from '../DepositApi';
import { DepositBadge } from '../detail/state/DepositBadge';
import { Link } from 'react-router-dom';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import {
  createColumnFilter,
  FilterInputType,
  getDateRange
} from 'client/ui/table/TableColumnFilter';
import { DepositStateOptions } from '../detail/DepositOptions';
import { ColumnSortDirections } from 'common/dto/shared/ColumnSortDirections';

export interface DepositListPageProps extends RouteComponentProps {}

/**
 * Pagina Elenco Versamenti nell’Archivio di Deposito
 */
export function DepositListPage(props: DepositListPageProps) {
  const [query, setQuery] = useState<IDepositFindQueryDto>({
    page: 1,
    size: 10,
    orderBy: 'id',
    orderByDirection: ColumnSortDirections.descend
  });
  const { response, error, loading } = useApiQuery(DepositApi.find, {
    data: { query }
  });

  const columns: ColumnProps<IDeposit>[] = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      width: 100,
      sorter: true
    },
    {
      key: 'state',
      dataIndex: 'state',
      title: 'Stato',
      width: 240,
      render: (state, deposit) => <DepositBadge deposit={deposit} />,
      ...createColumnFilter(FilterInputType.CheckBox, {
        options: DepositStateOptions
      })
    },
    {
      key: 'policy',
      dataIndex: 'policy',
      title: 'Descrizione',
      ellipsis: true,
      ...createColumnFilter(FilterInputType.Text),
      render: (state, deposit) => deposit.policy?.description
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Data Creazione',
      width: 160,
      render: date => <ISODate date={date} />,
      sorter: true,
      ...createColumnFilter(FilterInputType.DateRange)
    },
    {
      key: 'actions',
      align: 'right',
      className: 'actions',
      width: 120,
      render: (_, deposit) => (
        <Link to={`/deposit/view/${deposit.id}`}>
          <Button size="small" icon="eye">
            Dettaglio
          </Button>
        </Link>
      )
    }
  ];

  if (error && !response)
    return (
      <AlertPage type="error" message="Impossibile caricare i versamenti" />
    );

  return (
    <PageLayout
      hasScrollable
      hasMenu
      fullTable
      title={
        <CustomCardTitle
          image="deposit"
          title="Elenco Versamenti nell’Archivio di Deposito"
        />
      }
    >
      <TableScroll
        loading={tableLoaderSpin(loading || !response)}
        dataSource={response?.data.items ?? []}
        columns={columns}
        onChange={(pagination, filters, sorter) => {
          // Date
          const createdDateRange = getDateRange(filters.createdAt);

          const policy =
            filters.policy && filters.policy.length > 0
              ? filters.policy[0].toString()
              : null;

          setQuery({
            ...query,
            page: pagination.current || query.page,
            size: pagination.pageSize || query.size,
            states: filters.state ?? [],
            orderBy: sorter.field,
            createdAtRangeFrom: createdDateRange.from,
            createdAtRangeTo: createdDateRange.to,
            policy: policy,
            orderByDirection: sorter.order
              ? ColumnSortDirections[sorter.order]
              : undefined
          });
        }}
        pagination={{
          size: 'small',
          position: 'bottom',
          showSizeChanger: true,
          total: response?.data.meta.total,
          pageSize: query.size,
          current: query.page,
          pageSizeOptions: ['5', '10', '20', '30', '40']
        }}
      />
    </PageLayout>
  );
}
