import * as React from 'react';
import { IDepositPolicy } from 'common/schema/DepositPolicy';
import { useState } from 'react';
import { DepositPolicyApi } from '../DepositPolicyApi';
import { Button, Modal } from 'antd';
import { humanFileSize } from 'common/utils/humanFileSize';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

export interface IDepositPolicyAnalyzeButtonProps {
  policy: IDepositPolicy | undefined;
  disabled?: boolean;
}

export function DepositPolicyAnalyzeButton(
  props: IDepositPolicyAnalyzeButtonProps
) {
  const { policy, disabled } = props;
  const [loading, setLoading] = useState(false);

  const handleAnalyze = async () => {
    if (!policy || loading) return;
    setLoading(true);
    try {
      const result = await DepositPolicyApi.analyze({ id: policy.id });
      setLoading(false);

      Modal.info({
        title: 'Analisi Impatto',
        content: (
          <div>
            Numero di fascicoli: <strong>{result.data.dossiersCount}</strong>
            <br />
            Dimensione fascicoli:{' '}
            <strong>{humanFileSize(result.data.dossiersSize, 'bytes')}</strong>
            <br />
          </div>
        )
      });
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  return (
    <>
      <Button
        loading={loading}
        disabled={!policy || disabled}
        type="primary"
        onClick={handleAnalyze}
      >
        Analizza Impatto
      </Button>
    </>
  );
}
