import { apiClient } from 'client/core/network/apiClient';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';
import { IDepositInstance } from 'common/schema/DepositInstance';
import {
  CreateDepositInstanceDto,
  IDepositInstanceFindQueryDto,
  IDepositInstanceFindResult
} from 'common/dto/DepositInstanceDto';

export const DepositInstanceApi = {
  find: (opts: { query: IDepositInstanceFindQueryDto }) =>
    apiClient.get<IDepositInstanceFindResult>(`/deposit-instances/first`, {
      params: opts.query
    }),

  create: (opts: { input: CreateDepositInstanceDto }) =>
    apiClient.post<IDepositInstance>(`/deposit-instances`, opts.input),

  update: (opts: { id: number; input: CreateDepositInstanceDto }) =>
    apiClient.put<IDepositInstance>(`/deposit-instances/${opts.id}`, opts.input)
};
