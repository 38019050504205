import React from 'react';
import { toPairs } from 'lodash';
import { DepositState, DepositItemState } from 'common/schema/Deposit';
import { DepositStateBadge } from './state/DepositBadge';
import { DepositItemBadge } from './state/DepositItemBadge';

export const DepositStateOptions = toPairs(DepositState).map(
  ([key, value]) => ({
    label: <DepositStateBadge state={value} />,
    value: value
  })
);

export const DepositItemStateOptions = toPairs(DepositItemState).map(
  ([key, value]) => ({
    label: <DepositItemBadge state={value} />,
    value: value
  })
);
