import { Row, Col } from 'antd';
import { DatePickerInputItem } from 'client/ui/form/input/DatePickerInputItem';
import { NumberInputItem } from 'client/ui/form/input/NumberInputItem';
import { PolicyInterestDateType } from 'common/schema/GlobalConservationPolicy';
import { useField } from 'formik';
import React from 'react';
import { DateOfInterestSelectInputItem } from '../DateOfInterestSelectInputItem';

export interface IProtocolAtInputFormProps {
  disabled?: boolean;
}

export function ProtocolAtInputForm(props: IProtocolAtInputFormProps) {
  const { disabled } = props;
  const [typeField] = useField('protocolDateFilterType');
  return (
    <Row gutter={12}>
      <Col span={10}>
        <DateOfInterestSelectInputItem
          disabled={disabled}
          name="protocolDateFilterType"
          label="Tipo data di protocollazione"
          placeholder="Seleziona un tipo data..."
        />
      </Col>
      {typeField.value === PolicyInterestDateType.Range ? (
        <>
          <Col span={7}>
            <DatePickerInputItem
              format="DD/MM/YYYY"
              disabled={disabled}
              name="protocolDateFrom"
              type="from"
              label="Da data protocollo"
              disabledTime={() => ({})}
              style={{ width: '100%' }}
            />
          </Col>
          <Col span={7}>
            <DatePickerInputItem
              format="DD/MM/YYYY"
              disabled={disabled}
              name="protocolDateTo"
              type="to"
              label="A data protocollo"
              disabledTime={() => ({})}
              style={{ width: '100%' }}
            />
          </Col>
        </>
      ) : typeField.value === PolicyInterestDateType.DaysBefore ? (
        <Col span={7}>
          <NumberInputItem
            disabled={disabled}
            name="protocolDateDaysRange"
            label="Numero di giorni"
            style={{ width: '100%' }}
          />
        </Col>
      ) : typeField.value === PolicyInterestDateType.Single ? (
        <Col span={7}>
          <DatePickerInputItem
            format="DD/MM/YYYY"
            disabled={disabled}
            name="protocolDateFrom"
            type="from"
            label="Da data protocollo"
            disabledTime={() => ({})}
            style={{ width: '100%' }}
          />
        </Col>
      ) : (
        <></>
      )}
    </Row>
  );
}
