import * as React from 'react';
import { IConservationPolicy } from 'common/schema/ConservationPolicy';
import { Button } from 'antd';
import { useApiMutationState } from 'client/core/network/hooks/useApiMutation';
import { ConservationPolicyApi } from '../ConservationPolicyApi';
import { ICurrentInstance } from 'common/schema/CurrentInstance';

export interface IConservationTogglePolicyButtonProps {
  instance: ICurrentInstance;
  policy: IConservationPolicy;
}

/**
 * Pulsante per abilitare / disabilitare una policy di conservazione
 */
export function ConservationTogglePolicyButton(
  props: IConservationTogglePolicyButtonProps
) {
  const { policy, instance } = props;
  const [runSetEnabled, { loading }] = useApiMutationState(
    ConservationPolicyApi.setEnabled,
    {
      data: {},
      invalidates: [ConservationPolicyApi.find]
    }
  );

  return policy.enabled ? (
    <Button
      size="small"
      type="danger"
      loading={loading}
      onClick={() =>
        runSetEnabled({
          data: {
            input: { instanceId: instance.id, uid: policy.uid, enabled: false }
          }
        })
      }
      style={{ width: '125px' }}
    >
      {' '}
      Disabilita
    </Button>
  ) : (
    <Button
      size="small"
      type="primary"
      loading={loading}
      onClick={() =>
        runSetEnabled({
          data: {
            input: { instanceId: instance.id, uid: policy.uid, enabled: true }
          }
        })
      }
      style={{ width: '125px' }}
    >
      {' '}
      Abilita
    </Button>
  );
}
