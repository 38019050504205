import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DepositApi } from '../../DepositApi';
import { useState } from 'react';
import { IDeposit } from 'common/schema/Deposit';
import { TableScroll } from 'client/ui/table/TableScroll';
import { IDepositListDossiersQueryDto } from 'common/dto/DepositDto';
import { IDepositDossier } from 'common/schema/DepositDossier';
import { DepositItemBadge } from '../../detail/state/DepositItemBadge';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { DepositItemStateOptions } from '../../detail/DepositOptions';

export interface TableDepositDossierListProps {
  deposit: IDeposit;
  label?: React.ReactNode;
}

/**
 * Tabella che mostra l'elenco dei fascicoli versati in un versamento.
 */
export function TableDepositDossierList(props: TableDepositDossierListProps) {
  const { deposit } = props;
  const [query, setQuery] = useState<IDepositListDossiersQueryDto>({
    page: 1,
    size: 20
  });

  const { response, loading, error } = useApiQuery(DepositApi.listDossiers, {
    data: { id: deposit.id, query }
  });

  const columns: ColumnProps<IDepositDossier>[] = [
    {
      title: 'UID',
      dataIndex: 'uid',
      width: 80
    },
    // {
    //   title: 'A.O.O.',
    //   dataIndex: 'areaName',
    //   width: 160
    // },
    {
      title: 'Titolo',
      dataIndex: 'title'
    },
    {
      title: 'Stato',
      width: 150,
      dataIndex: 'state',
      render: state => <DepositItemBadge state={state} />,
      ...createColumnFilter(FilterInputType.CheckBox, {
        options: DepositItemStateOptions
      })
    }
  ];

  return (
    <TableScroll
      label={props.label}
      bottomSpace={64}
      loading={tableLoaderSpin(loading || !response)}
      columns={columns}
      dataSource={response?.data.items ?? []}
      onChange={(pagination, filters, sorter) => {
        setQuery({
          ...query,
          states: filters.state ?? [],
          page: pagination.current || query.page,
          size: pagination.pageSize || query.size
        });
      }}
      pagination={{
        size: 'small',
        position: 'bottom',
        showSizeChanger: true,
        total: response?.data.meta.total,
        pageSize: query.size,
        current: query.page
      }}
    />
  );
}
