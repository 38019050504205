import * as React from 'react';
import Select, { OptionProps, SelectProps } from 'antd/lib/select';
import {
  FormikValues,
  useField,
  useFormikContext,
  FieldInputProps,
  FieldHelperProps,
  FormikContextType
} from 'formik';
import {
  ApiGet,
  ApiQueryOptions,
  useApiQuery
} from 'client/core/network/hooks/useApiQuery';
import { Spin } from 'antd';
import { useState, useCallback } from 'react';
import { debounce } from 'lodash';

type Item<R extends Array<any>> = R extends Array<infer I> ? I : never;

type AsyncSelectQueryFn<T extends FormikValues, A, R> = (
  formik: FormikContextType<T>,
  search?: string | undefined
) => ApiQueryOptions<A, R>;

interface SelectOption extends OptionProps {
  label: React.ReactNode;
}

export interface AsyncSelectInputProps<
  T extends FormikValues,
  A,
  R,
  O extends Array<any> = InferArray<R>
> extends SelectProps {
  name: string;
  /** Permette di caricare con una query le opzioni. */
  query: {
    apiFn: ApiGet<A, R>;
    options: ApiQueryOptions<A, R> | AsyncSelectQueryFn<T, A, R>;
  };
  refreshOnSearch?: boolean;
  responseTransform?: (response: R) => O;
  optionTransform: (option: Item<O>) => SelectOption;
}

type InferArray<T> = T extends any[] ? T : never;

/**
 * Select collegata direttamente a Formik.
 */
// TODO: Gestire errori
export function AsyncSelectInput<
  T extends FormikValues,
  A,
  R,
  O extends Array<any> = InferArray<R>
>(props: AsyncSelectInputProps<T, A, R, O>) {
  const {
    name,
    query,
    responseTransform,
    optionTransform,
    refreshOnSearch,
    ...otherProps
  } = props;
  const [field, meta, helpers] = useField<T>(name);
  const [search, setSearch] = useState(undefined as string | undefined);
  const formik = useFormikContext<T>();
  const { response, loading, error } = useApiQuery(
    query.apiFn,
    typeof query.options === 'function'
      ? query.options(formik, search)
      : query.options
  );

  const responseTransformFn = responseTransform ?? (i => (i as unknown) as O);

  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearch(value);
    }, 200),
    [refreshOnSearch]
  );

  return (
    <Select<any>
      {...otherProps}
      loading={loading}
      notFoundContent={loading ? <Spin size="small" /> : null}
      value={field.value}
      filterOption={!refreshOnSearch}
      onSearch={refreshOnSearch ? handleSearch : undefined}
      onChange={value => {
        helpers.setValue(value == undefined ? null : value);
      }}
    >
      {response?.data &&
        responseTransformFn(response?.data).map(item => {
          const option = optionTransform(item);
          return (
            <Select.Option key={option.value} {...option}>
              {option.label}
            </Select.Option>
          );
        })}
    </Select>
  );
}
