import React from 'react';
import { toPairs } from 'lodash';
import { GlobalPolicyType } from 'common/schema/GlobalConservationPolicy';
import { GlobalPolicyTypeBadge } from '../badge/GlobalPolicyTypeBadge';
import { GlobalPolicyStateTag } from '../badge/GlobalPolicyStateTag';

export const GlobalPolicyTypeOptions = toPairs(GlobalPolicyType).map(
  ([key, value]) => ({
    label: <GlobalPolicyTypeBadge type={value} />,
    value: value
  })
);

export const GlobalPolicyStateOptions = [
  {
    label: <GlobalPolicyStateTag active={true} />,
    value: '1'
  },
  {
    label: <GlobalPolicyStateTag active={false} />,
    value: '0'
  }
];
