import React, { useState } from 'react';
import { Typography, Row, Col, Card, Statistic, Progress, Icon } from 'antd';
const { Text, Paragraph } = Typography;
import './StatisticTitle.scss';

export interface StatisticTitleProps {
  children?: React.ReactNode;
}

/**
 * Elemento per mostrare il titolo delle statistiche
 * ATT: se non viene passato nessun elemento occupa ugualmente un spazio
 */
export function StatisticTitle(props: StatisticTitleProps) {
  return (
    <Paragraph className="statistic-title" strong>
      {props.children}
    </Paragraph>
  );
}
