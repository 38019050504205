import * as React from 'react';
import Tag, { TagProps } from 'antd/lib/tag';

export interface GlobalPolicyStateTagProps {
  active: boolean;
}

function mapToTagProps(active: boolean): TagProps {
  return active
    ? { color: 'green', children: 'Attiva' }
    : { children: 'Non Attiva' };
}

/**
 * Mostra lo stato di policy globale
 */
export function GlobalPolicyStateTag(props: GlobalPolicyStateTagProps) {
  return <Tag {...mapToTagProps(props.active)} />;
}
