import * as React from 'react';
import { Divider, Popover } from 'antd';

export interface ProcessNumberResultProps {
  process?: number;
  current?: number;
}

/**
 * Mostra il numero di AOO a cui sono state trasmesse le policy globali
 * comparate con le AOO attuali a sistema, se sono differentinle segna in rosso
 */
export function ProcessNumberResult(props: ProcessNumberResultProps) {
  const { process, current } = props;
  if (process == null || current == null) return null;
  const style = process == current ? {} : { color: 'red' };

  return (
    // <Popover
    //   content={
    //     <div style={{ textAlign: 'center' }}>
    //       Trasmesse a {process} AOO
    //       <Divider type="horizontal" />
    //       AOO attuali {current}
    //     </div>
    //   }
    // >
    <span style={style}>
      Trasmesse {process} / {current}
    </span>
    // </Popover>
  );
}
