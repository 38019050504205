import * as React from 'react';
import { IDepositPolicy } from 'common/schema/DepositPolicy';
import { useState } from 'react';
import { DepositPolicyApi } from '../DepositPolicyApi';
import { Button, Modal } from 'antd';
import { useHistory } from 'react-router';

export interface IDepositPolicyStartButtonProps {
  policy: IDepositPolicy | undefined;
  disabled?: boolean;
}

export function DepositPolicyStartButton(
  props: IDepositPolicyStartButtonProps
) {
  const { policy, disabled } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleStart = async () => {
    if (!policy || loading) return;
    setLoading(true);
    try {
      const result = await DepositPolicyApi.start({ id: policy.id });
      setLoading(false);

      Modal.info({
        title: 'Proposta di versamento creata con successo',
        cancelText: 'Chiudi',
        okText: 'Vai alla Proposta di Versamento',
        content: (
          <div>
            La proposta di versamento è stato creato con successo a partire
            dalla policy.
          </div>
        ),
        onOk: () => {
          history.push(`/deposit/view/${result.data!.id}`);
        }
      });
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  return (
    <>
      <Button
        loading={loading}
        disabled={!policy || disabled}
        type="primary"
        onClick={handleStart}
      >
        Crea Proposta di Versamento
      </Button>
    </>
  );
}
