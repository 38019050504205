import React from 'react';
import { Button, Row, Col, Timeline, Progress, Tabs, Empty } from 'antd';
const { TabPane } = Tabs;
import { RouteComponentProps } from 'react-router';
import { LabeledText } from 'client/ui/labeled-text/LabeledText';
import {
  StatisticSpaceUsedItem,
  IStatisticSpaceUsedItemProps
} from 'client/ui/statistic/StatisticSpaceUsedItem';
import { LabeledTextGroup } from 'client/ui/labeled-text/LabeledTextGroup';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { CustomCardExtra } from 'client/ui/layout/page-card/CustomCardExtra';
import { Scrollable } from 'client/ui/scroller/Scrollable';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DepositApi } from '../DepositApi';
import { ISODate } from 'client/ui/display/ISODate';
import { DepositBadge } from '../detail/state/DepositBadge';
import { DepositPolicySelectionRules } from 'client/components/deposit-policy/detail/selection-rules/SelectionRules';
import './DepositViewPage.scss';
import { DepositProcessingState } from 'common/schema/Deposit';
import { DepositRetryButton } from './DepositRetryButton';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { DepositSendButton } from './DepositSendButton';
import { TimelineCustomItem } from 'client/ui/timeline/TimelineCustomItem';
import { RowScroller } from 'client/ui/scroller/RowScroller';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import { TableDepositDocumentList } from './relations/TableDepositDocumentList';
import { TableDepositDossierList } from './relations/TableDepositDossierList';
import { resolveApiPath } from 'client/core/appConfig';
import { useInterval } from 'client/core/hooks/useInterval';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { DepositPauseButton } from './DepositPauseButton';
import { DepositLogic } from 'common/logic/DepositLogic';
import { DepositCleanupButton } from './DepositCleanupButton';

interface Params {
  id: string;
}

export interface DepositViewPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina del singolo versamento
 */
export function DepositViewPage(props: DepositViewPageProps) {
  const depositId = parseInt(props.match.params.id, 10);
  const { response, error, loading, refetch } = useApiQuery(
    DepositApi.findOne,
    {
      data: { id: depositId }
    }
  );

  const deposit = response?.data;

  // Aggiorno, se è in corso, la pagina
  useInterval(
    () => {
      refetch();
    },
    DepositLogic.shouldRefreshDeposit(deposit) ? 15_000 : null
  );

  if (error && !response)
    return (
      <AlertPage
        type="error"
        message="Impossibile caricare il versamento"
        description={getNetworkErrorMessage(error)}
      />
    );

  if (!deposit) {
    return <LoadingPage />;
  }

  const reportData: IStatisticSpaceUsedItemProps[] = [
    {
      title: 'Fascicoli',
      number: deposit.dossiersSent,
      totalNumber: deposit.dossiersCount,
      running: deposit.processing == DepositProcessingState.Running,
      progressNumber: true,
      loading: false
    },
    {
      title: 'Documenti',
      number: deposit.documentsSent,
      totalNumber: deposit.documentsTotal,
      running: deposit.processing == DepositProcessingState.Running,
      progressNumber: true,
      loading: false
    }
    // {
    //   title: 'File',
    //   number: deposit.attachmentsReceived,
    //   running: deposit.processing == DepositProcessingState.Running,
    //   progressNumber: true,
    //   loading: false
    // }
  ];

  const isDownloadable = DepositLogic.isDownloadable(deposit);

  return (
    <PageLayout
      hasMenu
      hasScrollable
      className="deposit-view-page"
      linkBack="/deposit/list"
      title={
        <CustomCardTitle
          tag={`ID: ${deposit.id}`}
          tagTheme="filled"
          title="Dettaglio del Versamento"
        />
      }
      extra={
        <CustomCardExtra
          right={
            <>
              <DepositSendButton deposit={deposit} />
              <DepositCleanupButton deposit={deposit} />
              <DepositRetryButton deposit={deposit} />
              <DepositPauseButton deposit={deposit} />
              <Button
                disabled={!isDownloadable}
                target="_blank"
                href={resolveApiPath(
                  `/deposits/${deposit.id}/attachments/deposit-report/Report_di_Versamento.xml`
                )}
              >
                Scarica Dettaglio Versamento
              </Button>
            </>
          }
        />
      }
    >
      <RowScroller>
        <ColScroller span={8} side="left" spacing="inside">
          <Row>
            <Col span={24}>
              <LabeledText
                label="Stato"
                text={<DepositBadge deposit={deposit} />}
              />
              <LabeledText
                ellipsis={false}
                label="Descrizione Policy di Versamento"
                text={deposit.policy.description}
              />
              <LabeledText
                label="Data Avvio:"
                text={<ISODate date={deposit.createdAt} />}
              />
            </Col>
          </Row>

          {/* Report istanza  */}
          <Row gutter={6}>
            {reportData.map((data, key) => (
              <StatisticSpaceUsedItem
                dataLabel="Versati"
                column={2}
                type="compact"
                data={data as any}
                key={key}
                totalProgress={Math.floor(
                  ((deposit.dossiersSent + deposit.documentsSent) /
                    (deposit.dossiersCount + deposit.documentsTotal)) *
                    100
                )}
              />
            ))}
          </Row>

          <div className="progress-bar-container">
            <Progress
              percent={Math.floor(
                (deposit.dossiersSent / deposit.dossiersCount) * 100
              )}
            />
          </div>
        </ColScroller>
        <Col span={16}>
          <Tabs
            type="card"
            className="tabs-content-scrollable"
            defaultActiveKey="history"
          >
            <TabPane
              className="spacing-tablescroll full-table"
              key="dossiers"
              tab="Elenco Fascicoli"
            >
              <TableDepositDossierList deposit={deposit} />
            </TabPane>
            <TabPane
              className="spacing-tablescroll full-table"
              key="documents"
              tab="Elenco Documenti"
            >
              {DepositLogic.shouldShowDocuments(deposit) ? (
                <TableDepositDocumentList deposit={deposit} />
              ) : (
                <Empty
                  style={{ marginTop: '12px' }}
                  description="L'elenco dei documenti è disponibile a partire dall'avvio del Versamento."
                />
              )}
            </TabPane>
            <TabPane key="policy" tab="Policy">
              <Scrollable spacing="inside">
                <Row className="policy-summary">
                  <Col span={24}>
                    <LabeledText
                      ellipsis={false}
                      label="Descrizione Policy di Versamento"
                      text={deposit.policy.description}
                    />
                    <LabeledText
                      ellipsis={false}
                      label="Regole di Selezione"
                      text={
                        <DepositPolicySelectionRules policy={deposit.policy} />
                      }
                    />
                  </Col>
                </Row>
              </Scrollable>
            </TabPane>
            <TabPane key="history" tab="Cronologia">
              <Scrollable spacing="inside">
                <Timeline>
                  {deposit.history.map((history, i) => (
                    <TimelineCustomItem
                      id={history.id}
                      type={history.type}
                      createdAt={history.createdAt}
                      author={history.author}
                      description={history.description}
                      mode="row"
                    />
                  ))}
                </Timeline>
              </Scrollable>
            </TabPane>
          </Tabs>
        </Col>
      </RowScroller>
    </PageLayout>
  );
}
