import * as React from 'react';
import Tag, { TagProps } from 'antd/lib/tag';
import { GlobalPolicyType } from 'common/schema/GlobalConservationPolicy';
import { assertNever } from 'server/utils/typings/assertNever';

export interface GlobalPolicyTypeBadgeProps {
  type?: GlobalPolicyType;
}

function mapToTagProps(type: GlobalPolicyType): TagProps {
  switch (type) {
    case GlobalPolicyType.Document:
      return { color: 'blue', children: 'Documenti' };
    case GlobalPolicyType.Stamp:
      return { color: 'orange', children: 'Stampe' };
    default:
      assertNever(type);
  }
}

/**
 * Mostra il tipo di policy globale come Tag
 */
export function GlobalPolicyTypeBadge(props: GlobalPolicyTypeBadgeProps) {
  if (!props.type) return null;
  return <Tag {...mapToTagProps(props.type)} />;
}
