import { ColumnProps } from 'antd/lib/table';
import { Input, Button, Icon, DatePicker } from 'antd';
import React, { Key } from 'react';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import moment from 'moment';

export enum FilterInputType {
  Text = 'text',
  Date = 'date',
  CheckBox = 'checkBox',
  DateRange = 'DateRange'
}

export function createColumnFilter<T extends {}>(
  inputType: FilterInputType,
  params?: { options?: CheckboxOptionType[] }
): Partial<ColumnProps<T>> {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <div>
          {inputType === FilterInputType.CheckBox && (
            <CheckboxGroup
              options={params?.options}
              value={...selectedKeys!}
              onChange={e =>
                setSelectedKeys!(e ? e.map(v => v.toString()) : [])
              }
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
          )}
          {inputType === FilterInputType.Date && (
            <DatePicker
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
              value={
                selectedKeys!.length > 0
                  ? moment(selectedKeys![0], 'DD/MM/YYYY')
                  : null
              }
              onChange={e =>
                setSelectedKeys!(e ? [e.format('DD/MM/YYYY')] : [])
              }
              disabledTime={() => ({})}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
          )}
          {inputType === FilterInputType.Text && (
            <Input
              placeholder="Cerca"
              value={selectedKeys![0]}
              onChange={e =>
                setSelectedKeys!(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => {
                confirm!();
              }}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
          )}
          {inputType === FilterInputType.DateRange && (
            <div>
              <DatePicker
                placeholder="Da: DD/MM/YYYY"
                format="DD/MM/YYYY"
                value={
                  selectedKeys && selectedKeys[0]
                    ? moment(selectedKeys[0], 'YYYY-MM-DD')
                    : null
                }
                onChange={e => {
                  const newKeys: any[] = [...selectedKeys!];
                  newKeys[0] = e?.format('YYYY-MM-DD') ?? null;
                  setSelectedKeys!(newKeys);
                }}
                disabledTime={() => ({})}
                style={{ width: 188, display: 'block', marginBottom: 8 }}
              />
              <DatePicker
                placeholder="A: DD/MM/YYYY"
                format="DD/MM/YYYY"
                value={
                  selectedKeys && selectedKeys[1]
                    ? moment(selectedKeys[1], 'YYYY-MM-DD')
                    : null
                }
                onChange={e => {
                  const newKeys: any[] = [...selectedKeys!];
                  newKeys[1] = e?.format('YYYY-MM-DD') ?? null;
                  setSelectedKeys!(newKeys);
                }}
                disabledTime={() => ({})}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
            </div>
          )}
        </div>
        <Button
          type="primary"
          onClick={() => {
            confirm!();
          }}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Cerca
        </Button>
        <Button
          onClick={() => {
            clearFilters!();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    )
  };
}

export interface IDateRangeFilter {
  from?: Date | null;
  to?: Date | null;
}

/**
 * Preso l'array dal filtro di tipo DateRange, restituisce un oggetto con il
 * range di date richiesto.
 * @param array array risultante dal filtro di tipo DateRange su una column.
 * @returns un'oggetto con il range di date nel formato fornito
 */
export function getDateRange(
  array: any[] | undefined | null
): IDateRangeFilter {
  if (!array || array.length === 0) {
    return {};
  }

  return {
    from:
      array.length > 0 && array[0]
        ? moment(array[0], 'YYYY-MM-DD').startOf('day').toDate()
        : null,
    to:
      array.length > 1 && array[1]
        ? moment(array[1], 'YYYY-MM-DD').endOf('day').toDate()
        : null
  };
}

/**
 * Ritorna il valore del filtro richiesto
 * @key chiave del campo del filtro (dataIndex)
 */
export function getFilter(
  filters: Record<string, (boolean | React.Key)[] | null>,
  key: string
): boolean | Key | null {
  return filters[key] ? filters[key]![0] : null;
}
