import { ConservationPolicyResultState } from 'server/schema/documental/bridge/services/policies/ManageConservationPolicyTypes';
import { ICurrentInstance } from './CurrentInstance';
import { IGlobalConservationPolicyProcess } from './GlobalConservationPolicyProcess';

export interface IConservationPolicyProcessResult {
  id: number;
  processId: number;
  process: IGlobalConservationPolicyProcess;
  description: string;
  type: ConservationPolicyProcessResultType;
  areaId?: number; // AOO
  areaCode?: string;
  areaName?: string;
  instanceId?: number;
  instance?: ICurrentInstance;
  created?: boolean;
  disabled?: boolean;
  activated?: boolean;
  policyState?: ConservationPolicyResultState;
  createdAt: Date;
  updatedAt: Date;
}

export enum ConservationPolicyProcessResultType {
  Success = 'success',
  Error = 'error',
  Info = 'info'
}
