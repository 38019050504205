import { Button, Col, Row, Tag, message } from 'antd';
import { ColumnProps, SorterResult } from 'antd/lib/table';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { TableScroll } from 'client/ui/table/TableScroll';
import { IGlobalConservationPolicyQueryDto } from 'common/dto/GlobalConservationPolicyDto';
import { ColumnSortDirections } from 'common/dto/shared/ColumnSortDirections';
import {
  IGlobalConservationPolicy,
  GlobalPolicyType
} from 'common/schema/GlobalConservationPolicy';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { GlobalPolicyStateTag } from '../badge/GlobalPolicyStateTag';
import { GlobalPolicyTypeBadge } from '../badge/GlobalPolicyTypeBadge';
import { GlobalPolicyDeleteButton } from '../detail/GlobalPolicyDeleteButton';
import {
  GlobalPolicyStateOptions,
  GlobalPolicyTypeOptions
} from '../detail/GlobalPolicyOptions';
import { GlobalPolicyProcessTableCell } from '../detail/GlobalPolicyProcessTableCell';
import { GlobalConservationPolicyApi } from '../GlobalConservationPolicyApi';
import { ChangeStateButton } from './ChangeStateButton';
import { GlobalConservationPolicyLogic } from 'common/logic/GlobalConservationPolicyLogic';
import { useInterval } from 'client/core/hooks/useInterval';
import { CurrentAreaApi } from 'client/components/current-area/CurrentAreaApi';
import './GlobalConservationPolicyListPage.scss';

export interface IGlobalConservationPolicyListPageProps {}

export function GlobalConservationPolicyListPage(
  props: IGlobalConservationPolicyListPageProps
) {
  const [query, setQuery] = useState<IGlobalConservationPolicyQueryDto>({
    page: 1,
    size: 10
  });
  const {
    response,
    error,
    loading,
    refetch
  } = useApiQuery(GlobalConservationPolicyApi.list, { data: { query } });

  const currentAooNumberRequest = useApiQuery(CurrentAreaApi.count, {
    data: {}
  });

  // Riaggiorno la lista ogni 10 secondi per controllare se ha finito un processo
  useInterval(() => {
    const toRefetch = response?.data.items.some(
      policy => !GlobalConservationPolicyLogic.isProcessEnd(policy.lastProcess)
    );

    if (toRefetch) {
      refetch();
    }
  }, 5000);

  const columns: ColumnProps<IGlobalConservationPolicy>[] = [
    {
      key: 'code',
      dataIndex: 'code',
      title: 'Codice',
      width: 110,
      ellipsis: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      key: 'type',
      dataIndex: 'type',
      title: 'Tipo',
      width: 95,
      render: value => <GlobalPolicyTypeBadge type={value} />,
      ...createColumnFilter(FilterInputType.CheckBox, {
        options: GlobalPolicyTypeOptions
      })
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'Descrizione',
      ellipsis: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      key: 'active',
      dataIndex: 'active',
      title: 'Stato',
      width: 95,
      render: value => <GlobalPolicyStateTag active={value} />,
      ...createColumnFilter(FilterInputType.CheckBox, {
        options: GlobalPolicyStateOptions
      })
    },
    {
      key: 'changeState',
      width: 200,
      render: (_, record) => (
        <Row type="flex" gutter={8}>
          <Col>
            <ChangeStateButton policy={record} changeState={true} />
          </Col>
          <Col>
            <ChangeStateButton policy={record} changeState={false} />
          </Col>
        </Row>
      )
    },
    {
      key: 'lastProcess',
      dataIndex: 'lastProcess',
      title: 'Ultima Esecuzione',
      width: 340,
      sorter: true,
      render: (_, record) => (
        <GlobalPolicyProcessTableCell
          process={record.lastProcess}
          currentAooNumber={currentAooNumberRequest.response?.data.count}
        />
      )
    },
    // {
    //   key: 'logs',
    //   className: 'actions',
    //   align: 'right',
    //   width: 150,
    //   render: (_, record) => (
    //     <Row type="flex" gutter={8}>
    //       <Col>
    //         <Button size="small">Log</Button>
    //       </Col>
    //       <Col>
    //         <ChangeStateButton policy={record} changeState={false} />
    //       </Col>
    //       {/* <Col>
    //         <ChangeStateButton policy={record} changeState={true} />
    //       </Col> */}
    //     </Row>
    //   )
    // },
    {
      key: 'actions',
      align: 'right',
      className: 'actions',
      width: 215,
      render: (_, record) => {
        const disabledButton = !GlobalConservationPolicyLogic.isProcessEnd(
          record.lastProcess
        );
        return (
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <Link to={`/global-conservation-policy/edit/${record.id}`}>
                <Button disabled={disabledButton} icon="eye" size="small">
                  Dettaglio
                </Button>
              </Link>
            </Col>
            <Col>
              <GlobalPolicyDeleteButton
                disabled={disabledButton}
                size="small"
                text="Elimina"
                policy={record}
              />
            </Col>
          </Row>
        );
      }
    }
  ];

  if (error && !response)
    return (
      <AlertPage
        type="error"
        message="Impossibile caricare le policy di conservazione"
      />
    );

  return (
    <PageLayout
      hasScrollable
      hasMenu
      fullTable
      title={
        <CustomCardTitle
          image="deposit"
          title="Elenco Policy globali di Conservazione"
        />
      }
      extra={
        <Link to={`/global-conservation-policy/edit/create`}>
          <Button type="primary" icon="plus">
            Nuova Policy globale di Conservazione
          </Button>
        </Link>
      }
    >
      <TableScroll
        className="global-conservation-policy-table"
        loading={tableLoaderSpin(loading && !response)}
        dataSource={response?.data.items ?? []}
        columns={columns}
        onChange={(pagination, filters, sorter) => {
          const { field, order } = sorter as SorterResult<
            IGlobalConservationPolicy
          >;
          const descriptionSearch =
            filters.description && filters.description.length > 0
              ? filters.description[0].toString()
              : undefined;

          const types =
            filters.type && filters.type.length > 0
              ? (filters.type as GlobalPolicyType[])
              : undefined;

          const state = {
            active: filters.active?.includes('1'),
            disable: filters.active?.includes('0')
          };

          setQuery({
            ...query,
            description: descriptionSearch,
            types,
            state,
            page: pagination.current || query.page,
            size: pagination.pageSize || query.size,
            orderBy: order ? field.toString() : 'createdAt',
            orderDirection: order
              ? ColumnSortDirections[order]
              : ColumnSortDirections.descend
          });
        }}
        pagination={{
          size: 'small',
          position: 'bottom',
          showSizeChanger: true,
          total: response?.data.meta.total,
          pageSize: query.size,
          current: query.page,
          pageSizeOptions: ['5', '10', '20', '30', '40']
        }}
      />
    </PageLayout>
  );
}
