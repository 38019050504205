import { Col, Row } from 'antd';
import { DatePickerInputItem } from 'client/ui/form/input/DatePickerInputItem';
import { NumberInputItem } from 'client/ui/form/input/NumberInputItem';
import { SelectInputItem } from 'client/ui/form/input/SelectInputItem';
import {
  GlobalPolicyPeriodType,
  IGlobalConservationPolicy
} from 'common/schema/GlobalConservationPolicy';
import { useField, useFormikContext } from 'formik';
import React from 'react';

export interface IPeriodicitySelectInputItemProps {
  name: string;
  disabled?: boolean;
}

export function PeriodicitySelectInputItem(
  props: IPeriodicitySelectInputItemProps
) {
  const { name, disabled } = props;
  const [typeField] = useField<GlobalPolicyPeriodType>(name);
  const span = 24;
  const formik = useFormikContext<IGlobalConservationPolicy>();
  return (
    <Row gutter={12}>
      <Col span={span}>
        <SelectInputItem
          disabled={disabled}
          label=""
          placeholder=" Seleziona Periodicità..."
          name={name}
          allowClear
          onAfterChange={() => {
            formik.setFieldValue('executionDay', undefined);
            formik.setFieldValue('executionMonth', undefined);
            formik.setFieldValue('executionDate', undefined);
          }}
          options={[
            { value: GlobalPolicyPeriodType.Daily, label: 'Giornaliera' },
            { value: GlobalPolicyPeriodType.Weekly, label: 'Settimanale' },
            { value: GlobalPolicyPeriodType.Monthly, label: 'Mensile' },
            { value: GlobalPolicyPeriodType.Yearly, label: 'Annuale' },
            { value: GlobalPolicyPeriodType.Fixed, label: 'Una Tantum' }
          ]}
        />
      </Col>
      {typeField.value === GlobalPolicyPeriodType.Weekly ? (
        <Col span={span}>
          <SelectInputItem
            disabled={disabled}
            label="Giorno della settimana"
            placeholder=" Seleziona Giorno..."
            name="executionDay"
            allowClear
            options={[
              { value: 1, label: 'Lunedì' },
              { value: 2, label: 'Martedì' },
              { value: 3, label: 'Mercoledì' },
              { value: 4, label: 'Giovedì' },
              { value: 5, label: 'Venerdì' },
              { value: 6, label: 'Sabato' },
              { value: 7, label: 'Domenica' }
            ]}
          />
        </Col>
      ) : typeField.value === GlobalPolicyPeriodType.Monthly ? (
        <Col span={span}>
          <NumberInputItem
            disabled={disabled}
            label="Giorno del mese"
            name="executionDay"
            style={{ width: '100%' }}
          />
        </Col>
      ) : typeField.value === GlobalPolicyPeriodType.Yearly ? (
        <>
          <Col span={span}>
            <SelectInputItem
              disabled={disabled}
              label="Mese dell'anno"
              placeholder=" Seleziona Mese..."
              name="executionMonth"
              allowClear
              options={[
                { value: 1, label: 'Gennaio' },
                { value: 2, label: 'Febbraio' },
                { value: 3, label: 'Marzo' },
                { value: 4, label: 'Aprile' },
                { value: 5, label: 'Maggio' },
                { value: 6, label: 'Giugno' },
                { value: 7, label: 'Luglio' },
                { value: 8, label: 'Agosto' },
                { value: 9, label: 'Settembre' },
                { value: 10, label: 'Ottobre' },
                { value: 11, label: 'Novembre' },
                { value: 12, label: 'Dicembre' }
              ]}
            />
          </Col>
          <Col span={span}>
            <NumberInputItem
              disabled={disabled}
              label="Giorno del mese"
              name="executionDay"
              style={{ width: '100%' }}
            />
          </Col>
        </>
      ) : typeField.value === GlobalPolicyPeriodType.Fixed ? (
        <Col span={span}>
          <DatePickerInputItem
            format="DD/MM/YYYY"
            disabled={disabled}
            style={{ width: '100%' }}
            name="executionDate"
            label="Data di esecuzione"
            type={'from'}
            disabledTime={() => ({})}
          />
        </Col>
      ) : (
        <></>
      )}
    </Row>
  );
}
