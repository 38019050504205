import {
  GlobalPolicyType,
  GlobalPolicyPeriodType,
  GlobalPolicyExpirationType,
  PolicyInterestDateType,
  GlobalPolicyDepositState,
  IGlobalConservationPolicy
} from 'common/schema/GlobalConservationPolicy';
import {
  IPiTreCommonBody,
  IPiTreServiceInput,
  IPiTreServiceOutput
} from '../PiTreService';

export interface IConservationPolicyInput extends IPiTreServiceInput {
  policy: IGlobalConservationPolicy;
}

export enum ConservationPolicyResultState {
  /** Attiva */
  Active = 'A',
  /** Disattiva */
  Disabled = 'D',
  /** Non Presente */
  NotPresent = 'N'
}

export interface IConservationPolicyResult {
  success: boolean;
  activated?: boolean;
  created?: boolean;
  disabled?: boolean;
  areaId?: number;
  areaName?: string;
  areaCode?: string;
  state?: ConservationPolicyResultState;
  errorCode?: string;
  errorDescription?: string;
}

export interface IConservationPolicyOutput {
  success: boolean;
  errorCode?: string;
  errorDescription?: string;
  results: IConservationPolicyResult[] | null;
}

export interface IPiTreConservationPolicyInput extends IPiTreCommonBody {
  /** Se non passato si intendono _tutti_ i registri */
  registerId?: number;
  globalPolicy: {
    id: number;
    var_codice: string;
    var_descrizione?: string;
    attiva: boolean;
    cha_periodicita?: GlobalPolicyPeriodType;
    dta_esecuzione_policy?: string;
    /** Entrata */
    tipo_proto_A: boolean;
    /** Uscita */
    tipo_proto_P: boolean;
    /** Interna */
    tipo_proto_I: boolean;
    /** Grigia */
    tipo_proto_G: boolean;
    tipologia?: string;
    formato?: string[];
    firmato: boolean | null;
    marcato: boolean | null;
    cha_scadenza_timestamp?: GlobalPolicyExpirationType;
    cha_data_creazione_tipo?: PolicyInterestDateType;
    data_creazione_from?: string;
    data_creazione_to?: string;
    cha_data_proto_tipo?: PolicyInterestDateType;
    data_proto_from?: string;
    data_proto_to?: string;
    /* n. giorno di esecuzione (da 1 a 7 oppure da 1 a 31 (?)) */
    esecuzione_giorno?: number;
    /* n. mese di esecuzione (da 1 a 12) */
    esecuzione_mese?: number;
    cha_tipo_policy?: GlobalPolicyType;
    cha_data_stampa_tipo?: PolicyInterestDateType;
    /** giorni quando tipo data creazione = n. giorni prima */
    num_giorni_data_creazione?: number;
    num_anno_stampa?: number;
    data_stampa_from?: string;
    data_stampa_to?: string;
    /** numero di giorni data protocollo se tipo di protocollo = P */
    num_giorni_data_proto?: number;
    /** numero di giorni data stampa se tipo di protocollo = P */
    num_giorni_data_stampa?: number;
    /** null (non conservato), R (rifiutato), F(fallito)*/
    cha_stato_versamento?: GlobalPolicyDepositState | null;
    data_cancellazione?: string;
  };
}

export interface IPiTreConservationPolicyOutput extends IPiTreServiceOutput {
  Operazioni: IPiTreGlobalPolicyResult[];
}

export interface IPiTreGlobalPolicyResult extends IPiTreServiceOutput {
  Attivata: boolean;
  Creata: boolean;
  Disattivata: boolean;
  IdAoo: number;
  NomeAoo: string;
  CodiceAoo: string;
  Stato: ConservationPolicyResultState;
}
