import { apiClient } from 'client/core/network/apiClient';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';
import { IDeposit } from 'common/schema/Deposit';
import {
  IDepositFindQueryDto,
  IDepositListDossiersQueryDto,
  IDepositListDocumentsQueryDto
} from 'common/dto/DepositDto';
import { IDepositDossier } from 'common/schema/DepositDossier';
import { IDepositDocument } from 'common/schema/DepositDocument';

export const DepositApi = {
  find: (opts: { query: IDepositFindQueryDto }) =>
    apiClient.get<PaginatedResult<IDeposit>>(`/deposits`, {
      params: opts.query
    }),

  findOne: (opts: { id: number }) =>
    apiClient.get<IDeposit>(`/deposits/${opts.id}`),

  listDossiers: (opts: { id: number; query: IDepositListDossiersQueryDto }) =>
    apiClient.get<PaginatedResult<IDepositDossier>>(
      `/deposits/${opts.id}/dossiers`,
      { params: opts.query }
    ),
  listDocuments: (opts: { id: number; query: IDepositListDocumentsQueryDto }) =>
    apiClient.get<PaginatedResult<IDepositDocument>>(
      `/deposits/${opts.id}/documents`,
      { params: opts.query }
    ),

  retry: (opts: { id: number }) =>
    apiClient.post(`/deposits/${opts.id}/actions/retry`),
  send: (opts: { id: number }) =>
    apiClient.post(`/deposits/${opts.id}/actions/send`),
  cleanup: (opts: { id: number }) =>
    apiClient.post(`/deposits/${opts.id}/actions/cleanup`),
  pause: (opts: { id: number }) =>
    apiClient.post(`/deposits/${opts.id}/actions/pause`)
};
