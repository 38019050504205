import { apiClient } from 'client/core/network/apiClient';
import { ICurrentInstance } from 'common/schema/CurrentInstance';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';
import { ICurrentDriver } from 'server/schema/organigram/current-instance/driver/ICurrentDriver';
import {
  ICurrentInstanceFindQueryDto,
  ICurrentInstanceFindResult,
  CurrentInstanceCreateDto,
  CurrentInstanceUpdateDto,
  ICurrentInstancesListQueryDto
} from 'common/dto/CurrentInstanceDto';
import { ICurrentAreaQueryDto } from 'common/dto/CurrentAreaDto';
import { ICurrentArea } from 'common/schema/CurrentArea';

export const CurrentInstanceApi = {
  list: (opts: { query: ICurrentInstancesListQueryDto }) =>
    apiClient.get<PaginatedResult<ICurrentInstance>>(`/current-instances`, {
      params: opts.query
    }),
  listSelectable: (opts: {}) =>
    apiClient.get<ICurrentInstance[]>(
      `/current-instances/projections/selectable`
    ),
  find: (opts: { id: string; query: ICurrentInstanceFindQueryDto }) =>
    apiClient.get<ICurrentInstanceFindResult>(`/current-instances/${opts.id}`, {
      params: opts.query
    }),
  create: (opts: { input: CurrentInstanceCreateDto }) =>
    apiClient.post<ICurrentInstance>(`/current-instances`, opts.input),
  delete: (opts: { id: number }) =>
    apiClient.delete(`/current-instances/${opts.id}`),
  update: (opts: { id: number; input: CurrentInstanceUpdateDto }) =>
    apiClient.put<ICurrentInstance>(
      `/current-instances/${opts.id}`,
      opts.input
    ),
  findAreas: (opts: { id: string; query: ICurrentAreaQueryDto }) =>
    apiClient.get<PaginatedResult<ICurrentArea>>(
      `/current-instances/${opts.id}/areas`,
      { params: opts.query }
    ),
  listAreasSelectable: (opts: { id: string }) =>
    apiClient.get<ICurrentArea[]>(
      `/current-instances/${opts.id}/areas/projections/selectable`
    ),
  /** Avvia la sincronizzazione */
  sync: (opts: { id: string }) =>
    apiClient.post<void>(`/current-instances/${opts.id}/actions/sync`)
};
