import {
  IDeposit,
  DepositProcessingState,
  DepositState
} from 'common/schema/Deposit';

export class DepositLogic {
  /**
   * Controlla se un versamento è avviabile (solo quando è in bozza).
   */
  static isSendable(deposit: IDeposit) {
    return (
      deposit.processing === DepositProcessingState.Completed &&
      deposit.state === DepositState.Draft
    );
  }

  /**
   * Controlla se un versamento è pronto per l'eliminazione (solo quando è
   * stato versato).
   */
  static isCleanable(deposit: IDeposit) {
    return (
      deposit.state === DepositState.SentComplete ||
      deposit.state === DepositState.SentPartial
    );
  }

  /**
   * Controlla se è possibile mettere in pausa un versamento
   */
  static isPausable(deposit: IDeposit) {
    return (
      deposit.processing === DepositProcessingState.Running &&
      !deposit.requestedPause
    );
  }

  /**
   * Controlla se è possibile rimettere in coda un versamento.
   */
  static isRetryable(deposit: IDeposit) {
    return (
      deposit.processing === DepositProcessingState.Failed ||
      deposit.processing === DepositProcessingState.Paused ||
      deposit.state === DepositState.SentPartial ||
      deposit.state === DepositState.CleanedPartial
    );
  }

  /**
   * Indica se è possibile visualizzare l'elenco dei documenti
   */
  static shouldShowDocuments(deposit: IDeposit) {
    return [
      DepositState.Complete,
      DepositState.SentComplete,
      DepositState.SentPartial,
      DepositState.Cleanup,
      DepositState.CleanedPartial,
      DepositState.Send
    ].includes(deposit.state);
  }

  /**
   * (UI) Specifica se il versamento deve essere ricaricato tramite polling.
   */
  static shouldRefreshDeposit(deposit: IDeposit | undefined) {
    if (!deposit) return false;
    return (
      deposit.processing === DepositProcessingState.Queued ||
      deposit.processing === DepositProcessingState.Running
    );
  }

  /**
   * Specifica se il versamento ha un XML scaricabile
   */
  static isDownloadable(deposit: IDeposit) {
    return (
      deposit.processing == DepositProcessingState.Completed ||
      deposit.processing == DepositProcessingState.Failed ||
      deposit.processing == DepositProcessingState.Paused
    );
  }
}
