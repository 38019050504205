import * as React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import './MainMenu.scss';
import { TopHeaderMenu } from 'client/ui/layout/TopHeaderMenu';
import { CcTooltip } from 'client/ui/tooltip/CcTooltip';
import { useRouterCurrent } from 'client/routes/useReactRouter';
import { IconVector } from 'client/ui/icon/IconVector';

export interface MainMenuProps {
  /**
   * Link alla pagina del pulsante "Torna" / "Indietro"
   */
  linkBack?: string;
  /**
   * Testo che segue l'icona pulsante "Torna" / "Indietro"
   * se non viene inserito mostra "Torna all'elenco"
   */
  textBack?: React.ReactNode;
}

/**
 * Menu principale dell'applicazione
 */
export function MainMenu(props: MainMenuProps) {
  const currentPathKey = useRouterCurrent();

  // console.log('currentPathKey', currentPathKey);

  return (
    <TopHeaderMenu hasLogout {...props}>
      <Menu
        theme="dark"
        mode="horizontal"
        className="main-menu"
        selectedKeys={currentPathKey}
        style={{ lineHeight: '34px' }}
      >
        <Menu.Item key="home">
          <CcTooltip placement="top" title="Home">
            <Link to="/home">
              <IconVector image="home" />
            </Link>
          </CcTooltip>
        </Menu.Item>

        {/* Archivio Corrente  */}
        <Menu.Item key="current-instance">
          <CcTooltip placement="top" title="Archivio Corrente">
            <Link to="/current-instance/list">
              <IconVector image="instance" />
            </Link>
          </CcTooltip>
        </Menu.Item>

        {/* Menu Deposito e Versamenti  */}
        <Menu.SubMenu
          key="deposit"
          title={
            <CcTooltip placement="top" title="Archivio di Deposito">
              <div>
                <IconVector image="deposit" />
              </div>
            </CcTooltip>
          }
        >
          <Menu.Item key="deposit-instance">
            <Link to="/deposit-instance/view">Overview</Link>
          </Menu.Item>
          <Menu.Item key="deposit-policy">
            <Link to="/deposit-policy/list">Policy di Versamento</Link>
          </Menu.Item>
          <Menu.Item key="deposit">
            <Link to="/deposit/list">Versamenti</Link>
          </Menu.Item>
        </Menu.SubMenu>

        {/* Menu Conservazione  */}
        <Menu.SubMenu
          title={
            <CcTooltip placement="top" title="Conservazione">
              <div>
                <IconVector image="conservation" />
              </div>
            </CcTooltip>
          }
        >
          <Menu.Item key="conservation">
            <Link to="/conservation/instance/list">Policy di versamento</Link>
          </Menu.Item>
          <Menu.Item key="global-conservation-policy">
            <Link to="/global-conservation-policy/list">
              Policy globali di versamento
            </Link>
          </Menu.Item>
          {/* <Menu.Item key="conservation/area">
            <Link to="/conservation/area/list">Elenco per A.O.O.</Link>
          </Menu.Item> */}
        </Menu.SubMenu>

        {/* Menu Gestione  */}
        <Menu.SubMenu
          title={
            <CcTooltip placement="top" title="Gestione">
              <div>
                <IconVector image="management" />
              </div>
            </CcTooltip>
          }
        >
          <Menu.Item key="users">
            <Link to="/users/list">Utenti</Link>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </TopHeaderMenu>
  );
}
