import * as React from 'react';
import { Modal, Alert, message, Button } from 'antd';
import { Formik } from 'formik';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { useHistory } from 'react-router';
import { FormikForm } from 'client/ui/form/FormikForm';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';
import { useState } from 'react';
import { ButtonType } from 'antd/lib/button';
import { ModalFooter } from 'client/ui/modal/ModalFooter';
import { DepositInstanceApi } from '../DepositInstanceApi';
import {
  createDepositInstanceValidator,
  CreateDepositInstanceDto,
  editDepositInstanceValidator
} from 'common/dto/DepositInstanceDto';

export interface IDepositInstanceFormModalProps {
  instanceId: string;
  buttonType: ButtonType;
  buttonText?: string;
  buttonIcon: string;
}

/**
 * Form Modale per la creazione e la modifica di un'istanza dell'archivio
 * di deposito. Contiene il pulsante per l'apertura.
 */
export function DepositInstanceFormModal(
  props: IDepositInstanceFormModalProps
) {
  const { instanceId } = props;
  const id = instanceId === 'create' ? null : parseInt(instanceId, 10);

  const [visible, setVisible] = useState(false);
  const actionText =
    props.buttonText != null
      ? props.buttonText
      : id == null
      ? 'Aggiungi Istanza'
      : 'Modifica Istanza';

  const { response, error, loading, refetch } = useApiQuery(
    DepositInstanceApi.find,
    {
      skip: id == null,
      data: {
        id: String(id!),
        query: {
          includeMonitoring: false
        }
      }
    }
  );

  const history = useHistory();
  const validator =
    id == null ? createDepositInstanceValidator : editDepositInstanceValidator;

  // Modifica
  const create = useApiMutation(DepositInstanceApi.create, {
    invalidates: [DepositInstanceApi.find]
  });
  const update = useApiMutation(DepositInstanceApi.update, {
    invalidates: [DepositInstanceApi.find]
  });

  // TODO: spostare render Errore qui è al posto del pulsante
  if (error)
    return (
      <Alert
        showIcon
        type="error"
        message="Impossibile caricare l'istanza'"
        description={getNetworkErrorMessage(error)}
      />
    );

  // if (id && loading) {
  //   return <LoaderSpin />;
  // }

  return (
    <>
      <Button
        type={props.buttonType}
        icon={props.buttonIcon}
        disabled={id != null && loading}
        onClick={() => setVisible(true)}
      >
        {actionText}
      </Button>
      <Formik<CreateDepositInstanceDto>
        initialValues={response?.data.instance ?? ({ name: '' } as any)}
        enableReinitialize
        validate={schemaToValidator(validator)}
        onSubmit={async (rawValues, helpers) => {
          const values = await validator.validate(rawValues);
          try {
            if (id) {
              await update({ data: { id: id!, input: values as any } });
              message.success('Istanza aggiornata con successo. ');
            } else {
              const result = await create({
                data: { input: { ...values } as any }
              });
              message.success('Istanza collegata con successo. ');
            }
            refetch();
            setVisible(false);
          } catch (e) {
            console.error('errors', e);
            message.error(
              "Si è verificato un errore durante il collegamento dell'istanza."
            );
          }
        }}
      >
        {formik => (
          <Modal
            title={actionText}
            visible={visible}
            footer={null}
            onCancel={() => {
              formik.resetForm();
              setVisible(false);
            }}
          >
            <FormikForm layout="vertical">
              <TextInputItem name="name" label="Nome" />
              <TextInputItem name="frontendUrl" label="URL Frontend" />
              <TextInputItem name="apiUrl" label="URL API" />
              <TextInputItem
                name="serviceToken"
                label="Service Token"
                itemProps={{
                  help: id
                    ? 'Il service token non viene mostrato per ragioni di sicurezza. Inserire un valore per sovrascriverlo.'
                    : undefined
                }}
              />
              <ModalFooter>
                <Button type="primary" htmlType="submit">
                  Salva
                </Button>
                <Button htmlType="reset" onClick={() => setVisible(false)}>
                  Annulla
                </Button>
              </ModalFooter>
            </FormikForm>
          </Modal>
        )}
      </Formik>
    </>
  );
}
