import * as yup from 'yup';
import moment from 'moment-timezone';

declare module 'yup' {
  interface DateSchema {
    italianFormat(): DateSchema;
  }
  // interface StringSchema {
  //   italianDate(): StringSchema;
  // }
}

const invalidDate = new Date('');

yup.addMethod(yup.date, 'italianFormat', function () {
  return this.transform(function (value: moment.Moment, originalValue) {
    value = moment.tz(
      originalValue,
      ['DD-MM-YYYY', 'DD/MM/YYYY', moment.ISO_8601],
      'Europe/Rome'
    ) as moment.Moment;

    return value.isValid() ? value.toDate() : invalidDate;
  });
});
