import React, { useState } from 'react';
import clx from 'classnames';
import { Layout, Card, Icon, Button, Drawer, Table, Divider } from 'antd';
const { Content, Sider } = Layout;
import './PageCardButtonFooter.scss';

export interface PageCardButtonFooterProps {
  leftButton?: React.ReactNode;
  children?: React.ReactNode;
  hideDivider?: boolean;
}

/**
 * Elemento finale della pagina che contiene i Pulsanti
 */
export function PageCardButtonFooter(props: PageCardButtonFooterProps) {
  return (
    <div className="page-card-button-footer">
      {!props.hideDivider && <Divider />}
      <div className="row-container">
        <div className="col-left">{props.leftButton}</div>
        <div className="col-right">{props.children}</div>
      </div>
    </div>
  );
}
