import React from 'react';
import { Row, Col, Collapse, Alert } from 'antd';
const { Panel } = Collapse;
import { RouteComponentProps } from 'react-router';
import { LabeledText } from 'client/ui/labeled-text/LabeledText';
import { StatisticSpaceUsedItem } from 'client/ui/statistic/StatisticSpaceUsedItem';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { CustomCardExtra } from 'client/ui/layout/page-card/CustomCardExtra';
import { Scrollable } from 'client/ui/scroller/Scrollable';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { CurrentInstanceApi } from '../CurrentInstanceApi';
import { createStatisticSpaceUsedFromMonitoring } from 'client/ui/statistic/createStatisticSpaceUsedFromMonitoring';
import { CurrentInstanceAreasList } from './CurrentInstanceAreasList';
import {
  CurrentInstanceSyncButton,
  ButtonTypeProps
} from './CurrentInstanceSyncButton';
import { CurrentInstanceFormModal } from './CurrentInstanceFormModal';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CurrentInstanceDeleteButton } from '../components/CurrentInstanceDeleteButton';

export interface CurrentInstanceViewPageProps
  extends RouteComponentProps<{ instanceId: string }> {}

/**
 * Pagina di dettaglio della singola istanza del corrente.
 */
export function CurrentInstanceViewPage(props: CurrentInstanceViewPageProps) {
  const instanceId = props.match.params.instanceId;
  const { response, error, loading } = useApiQuery(CurrentInstanceApi.find, {
    data: { id: instanceId, query: { includeMonitoring: true } }
  });

  const instance = response?.data.instance;
  const monitoring = response?.data.monitoring;

  // TODO: gestire errori ???

  // if (loading || !response?.data) return <LoadingPage />;

  return (
    <PageLayout
      hasScrollable
      hasMenu
      loading={loading || !response}
      linkBack="/current-instance/list"
      title={
        <CustomCardTitle
          tag={`ID: ${instance?.id || ''}`}
          titleLabel="Nome Istanza C-PROT:"
          title={instance?.name || ''}
        />
      }
      extra={
        <CustomCardExtra
          showStatus={monitoring ? true : false}
          statusOnline={monitoring?.type === 'Success'}
          right={
            instance && (
              <>
                {instance && (
                  <CurrentInstanceDeleteButton
                    targetId={instance.id}
                    redirect
                    text="Scollega Istanza"
                  />
                )}
                <CurrentInstanceFormModal
                  buttonIcon="tool"
                  buttonType="default"
                  buttonText="Configurazione"
                  // tooltipsText="Configurazione Istanza"
                  title="Configurazione Istanza"
                  instanceId={String(instance.id)}
                />
                <CurrentInstanceSyncButton
                  type={ButtonTypeProps.button}
                  instanceId={instanceId}
                />
              </>
            )
          }
        />
      }
    >
      <Scrollable spacing="inside">
        {/* Indirizzi di connessione  */}
        <Row>
          <Col span={12}>
            <LabeledText
              label="Indirizzo di Frontend dell'Istanza Applicativa"
              text={
                <a target="_blank" href={instance?.frontendUrl ?? undefined}>
                  {instance?.frontendUrl}
                </a>
              }
            />
          </Col>
          <Col span={12}>
            <LabeledText
              label="Indirizzo Tool di Amministrazione dell'Istanza"
              text={
                <a target="_blank" href={instance?.backendUrl ?? undefined}>
                  {instance?.backendUrl}
                </a>
              }
            />
          </Col>

          {/* <Col span={24}>
              <LabeledText
                label="Connessione al database SQL (i dati User e Password sono impostati tramite file di configurazione)"
                text="Data Source=nomeserver; Initial Catalog=nomedb"
              />
            </Col> */}
        </Row>

        {/* Report istanza  */}
        <Row gutter={24}>
          {/* Report errore di connessione */}
          {monitoring?.type === 'Failure' && (
            <Col>
              <Alert
                type="error"
                message="Impossibile connettersi all'istanza"
                description={`Si è verificato un errore durante la connessione all'istanza: ${monitoring.error}`}
              />
            </Col>
          )}
          {monitoring &&
            createStatisticSpaceUsedFromMonitoring(
              monitoring
            ).map((data, key) => (
              <StatisticSpaceUsedItem
                data={data}
                key={key}
                dataLabel="Totale"
              />
            ))}
        </Row>

        {/* Tabella AOO (Collassabile) */}
        <Row>
          <Col span={24}>
            <Collapse bordered={false}>
              <Panel
                header="Elenco delle A.O.O. nell'Istanza Applicativa"
                key="1"
              >
                <CurrentInstanceAreasList instanceId={instanceId} />
              </Panel>
            </Collapse>
          </Col>
        </Row>

        {/* Bottoni di fine pagina */}
        {/* <PageCardButtonFooter
          hideDivider
          leftButton={
            instance && (
              <CurrentInstanceDeleteButton
                targetId={instance.id}
                redirect
                text="Scollega Istanza"
              />
            )
          }
        >
          {instance && (
            <CurrentInstanceFormModal
              buttonIcon="tool"
              buttonType="default"
              buttonText="Configurazione Istanza"
              instanceId={String(instance.id)}
            />
          )}
          <CurrentInstanceSyncButton instanceId={instanceId} />
        </PageCardButtonFooter> */}
      </Scrollable>
    </PageLayout>
  );
}
