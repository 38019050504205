import * as React from 'react';
import Tag, { TagProps } from 'antd/lib/tag';
import { assertNever } from 'server/utils/typings/assertNever';
import { ConservationPolicyResultState } from 'server/schema/documental/bridge/services/policies/ManageConservationPolicyTypes';
import { toPairs } from 'lodash';

export interface ConservationPolicyResultStateTagProps {
  state: ConservationPolicyResultState;
}

function mapToTagProps(state: ConservationPolicyResultState): TagProps {
  switch (state) {
    case ConservationPolicyResultState.Active:
      return { color: 'green', children: 'Attiva' };
    case ConservationPolicyResultState.Disabled:
      return { children: 'Non Attiva' };
    case ConservationPolicyResultState.NotPresent:
      return { color: 'red', children: 'Non Presente' };
    default:
      assertNever(state);
  }
}

/**
 * Mostra lo stato impostato sul Documentale
 * alla fine del processo di aggiornamento della policy globale
 */
export function ConservationPolicyResultStateTag(
  props: ConservationPolicyResultStateTagProps
) {
  if (!props.state) return null;
  return <Tag {...mapToTagProps(props.state)} />;
}

export const ConservationPolicyResultStateOptions = toPairs(
  ConservationPolicyResultState
).map(([key, value]) => ({
  label: <ConservationPolicyResultStateTag state={value} />,
  value: value
}));
