import { SelectInputItem } from 'client/ui/form/input/SelectInputItem';
import { GlobalPolicyExpirationType } from 'common/schema/GlobalConservationPolicy';
import React from 'react';

export interface IExpirationTypeSelectInputItemProps {
  name: string;
  disabled?: boolean;
}

export function ExpirationTypeSelectInputItem(
  props: IExpirationTypeSelectInputItemProps
) {
  const { name, disabled } = props;
  return (
    <SelectInputItem
      disabled={disabled}
      label="Scadenza"
      placeholder="Seleziona un tipo di scadenza..."
      name={name}
      allowClear
      options={[
        { value: GlobalPolicyExpirationType.Expired, label: 'Scaduti' },
        { value: GlobalPolicyExpirationType.Month, label: "Nell'ultimo mese" },
        {
          value: GlobalPolicyExpirationType.Week,
          label: "Nell'ultima settimana "
        },
        { value: GlobalPolicyExpirationType.Year, label: "Nell'ultimo anno" }
      ]}
    />
  );
}
