import React from 'react';
import clx from 'classnames';
import { Col, Row } from 'antd';
import { ColProps } from 'antd/lib/col';
import { ColScroller } from './ColScroller';
import { RowScroller } from './RowScroller';

export interface ScrollableProps extends ColProps {
  /**
   * Parametri <RowScroller>
   *
   * fixedTop? - Per inserire un elemento non scrollabile (fisso)
   * SOPRA l'elemento scrollabile
   */
  fixedTop?: React.ReactNode;
  /**
   * fixedBottom? - Per inserire un elemento non scrollabile (fisso)
   * SOTTO l'elemento scrollabile
   */
  fixedBottom?: React.ReactNode;

  /**
   * Parametri <ColScroller>
   *
   * isCard? - Crea una <Card> intorno all'elemento scrollabile
   */
  isCard?: boolean;
  /**
   * pdfViewer? - Specifica che l'elemento nello scroll è pdfViewer
   */
  pdfViewer?: boolean;
  /**
   * spacing? - "inside"  Inserisce un padding sopra e sotto (12px) all'INTERNO dello Scroll
   * spacing? - "outside" Inserisce un padding sopra e sotto (12px) all'ESTERNO dello Scroll
   *                      Utile se il contenuto è una Card (isCard)
   */
  spacing?: 'inside' | 'outside';
}

/**
 * Wrapper per lo scrolling
 *
 * L'altezza totale dell'elemento è definita tramite css heigh: 100%
 * TODO: verificare possibilità di calcolare dinemicamente l'altezza con JS
 */
export function Scrollable(props: ScrollableProps) {
  const { fixedTop, fixedBottom, ...otherProps } = props;

  return (
    <RowScroller fixedTop={fixedTop} fixedBottom={fixedBottom}>
      <ColScroller {...otherProps} span={24} />
    </RowScroller>
  );
}
