import * as React from 'react';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { AuthApi } from '../AuthApi';
import { useAuth, AuthActions } from '../AuthModule';
import { Button, Icon } from 'antd';
import { useDispatch } from 'react-redux';

export interface ILogoutButtonProps {}

/**
 * Gestisce il logout dell'utente corrente, sia dalla sessione remota che dai
 * dati locali.
 */
export function LogoutButton(props: ILogoutButtonProps) {
  const auth = useAuth();
  const dispatch = useDispatch();
  const logout = useApiMutation(AuthApi.logout, {});

  if (!auth.logged) return null;

  const handleLogout = async () => {
    await logout({});
    dispatch(AuthActions.logout());
  };

  return (
    <a onClick={handleLogout} className="main-menu-button-link">
      <Icon type="logout" /> Logout
    </a>
  );
}
