import * as yup from 'yup';

declare module 'yup' {
  interface Schema<T> {
    /** Permette di usare strip in base a una condizione su un valore del validatore */
    stripIf(field: string, fnc: (value: any) => boolean): Schema<T>;
  }
}

yup.addMethod(yup.mixed, 'stripIf', function (field: string, fnc) {
  return this.when(field, (value: any, schema: yup.MixedSchema) =>
    schema.strip(fnc(value))
  );
});
