import { yup } from 'common/validation/initYup';
import { IDepositInstance } from 'common/schema/DepositInstance';
import { IDepositDriverMonitorInstanceResult } from 'common/driver/DepositDriverDto';

export const editDepositInstanceValidator = yup
  .object({
    name: yup.string().required().label('Nome'),
    frontendUrl: yup.string().notRequired().label('URL Frontend'),
    apiUrl: yup.string().required().label('URL API'),
    serviceToken: yup.string().notRequired()
  })
  .noUnknown();

export type EditDepositInstanceDto = yup.InferType<
  typeof editDepositInstanceValidator
>;

// Creazione
export const createDepositInstanceValidator = editDepositInstanceValidator
  .clone()
  .shape({
    serviceToken: yup.string().required().label('Service Token')
  });

export type CreateDepositInstanceDto = yup.InferType<
  typeof createDepositInstanceValidator
>;

export const depositInstanceFindQueryValidator = yup
  .object({
    includeMonitoring: yup.boolean().default(false)
  })
  .noUnknown();

export type IDepositInstanceFindQueryDto = yup.InferType<
  typeof depositInstanceFindQueryValidator
>;

export interface IDepositInstanceFindResult {
  instance: IDepositInstance;
  monitoring?: IDepositDriverMonitorInstanceResult;
}
