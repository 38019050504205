import { apiClient } from 'client/core/network/apiClient';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';
import {
  IConservationPolicyQueryDto,
  IConservationPolicySetEnabledDto
} from 'common/dto/ConservationPolicyDto';
import { IConservationPolicy } from 'common/schema/ConservationPolicy';

export const ConservationPolicyApi = {
  find: (opts: { query: IConservationPolicyQueryDto }) =>
    apiClient.get<PaginatedResult<IConservationPolicy>>(
      `/conservation-policies`,
      { params: opts.query }
    ),

  setEnabled: (opts: { input: IConservationPolicySetEnabledDto }) =>
    apiClient.post<{}>(`/conservation-policies/actions/set-enabled`, opts.input)
};
