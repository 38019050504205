import * as React from 'react';
import { IDeposit, DepositProcessingState } from 'common/schema/Deposit';
import { Button, message } from 'antd';
import { useApiMutationState } from 'client/core/network/hooks/useApiMutation';
import { DepositApi } from '../DepositApi';
import { DepositLogic } from 'common/logic/DepositLogic';

export interface IDepositPauseButtonProps {
  deposit: IDeposit;
}

export function DepositPauseButton(props: IDepositPauseButtonProps) {
  const { deposit } = props;

  const [pause, { loading }] = useApiMutationState(DepositApi.pause, {
    data: { id: deposit.id },
    invalidates: [DepositApi.findOne]
  });

  const handlePause = async () => {
    try {
      await pause({});
      message.success('Il versamento è stato messo in pausa.');
    } catch (e) {
      message.error("Si è verificato un errore durante l'operazione.");
      console.error(e);
    }
  };

  if (!DepositLogic.isPausable(deposit)) return null;

  return (
    <>
      <Button icon="pause-circle" loading={loading} onClick={handlePause}>
        Metti in Pausa
      </Button>
    </>
  );
}
