import { yup } from 'common/validation/initYup';
import { ICurrentInstance } from 'common/schema/CurrentInstance';
import { ICurrentDriverMonitorInstanceResult } from 'common/driver/CurrentDriverDto';
import { ColumnSortDirections } from './shared/ColumnSortDirections';

export const currentInstancesListQueryValidator = yup
  .object({
    page: yup
      .number()
      .integer()
      .positive()
      .default(1)
      .label('Numero di Pagina'),
    size: yup
      .number()
      .integer()
      .min(10)
      .max(50)
      .default(20)
      .label('Istanze per Pagina'),
    name: yup.string().notRequired().label('Nome Policy'),
    createdAtFrom: yup
      .date()
      .notRequired()
      .nullable()
      .label('Da data di creazione'),
    createdAtTo: yup
      .date()
      .notRequired()
      .nullable()
      .label('A data di creazione'),
    orderBy: yup.string().default('createdAt').notRequired().nullable(),
    orderDirection: yup
      .string()
      .oneOfEnum(ColumnSortDirections)
      .default(ColumnSortDirections.descend) as yup.StringSchema<
      ColumnSortDirections
    >
  })
  .noUnknown();

export type ICurrentInstancesListQueryDto = yup.InferType<
  typeof currentInstancesListQueryValidator
>;

export const currentInstanceCreateValidator = yup
  .object({
    name: yup.string().required().label('Nome'),
    frontendUrl: yup
      .string()
      .nullable()
      .notRequired()
      .label("Indirizzo di Frontend dell'Istanza Applicativa"),
    backendUrl: yup
      .string()
      .nullable()
      .notRequired()
      .label("Indirizzo Tool di Amministrazione dell'Istanza"),
    apiUrl: yup
      .string()
      .required()
      .label("Indirizzo API/WebService dell'Istanza Applicativa"),
    connectionString: yup.string().required()
  })
  .noUnknown();

export type CurrentInstanceCreateDto = yup.InferType<
  typeof currentInstanceCreateValidator
>;

export const currentInstanceUpdateValidator = yup
  .object({
    name: yup.string().required().label('Nome'),
    frontendUrl: yup
      .string()
      .nullable()
      .notRequired()
      .label("Indirizzo di Frontend dell'Istanza Applicativa"),
    backendUrl: yup
      .string()
      .nullable()
      .notRequired()
      .label("Indirizzo Tool di Amministrazione dell'Istanza"),
    apiUrl: yup
      .string()
      .required()
      .label("Indirizzo API/WebService dell'Istanza Applicativa"),
    connectionString: yup.string().notRequired()
  })
  .noUnknown();

export type CurrentInstanceUpdateDto = yup.InferType<
  typeof currentInstanceUpdateValidator
>;

// Dettaglio istanza: Query
export const currentInstanceFindQueryValidator = yup
  .object({
    includeMonitoring: yup.boolean().default(false)
  })
  .noUnknown();

export type ICurrentInstanceFindQueryDto = yup.InferType<
  typeof currentInstanceFindQueryValidator
>;

// Dettaglio istanza: Result
export interface ICurrentInstanceFindResult {
  instance: ICurrentInstance;
  monitoring?: ICurrentDriverMonitorInstanceResult;
}
