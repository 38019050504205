import React from 'react';
import { SelectInputItem } from 'client/ui/form/input/SelectInputItem';

export interface IFormatSelectInputItemProps {
  disabled?: boolean;
}

export function FormatSelectInputItem(props: IFormatSelectInputItemProps) {
  const { disabled } = props;
  return (
    <SelectInputItem
      disabled={disabled}
      name="format"
      mode="multiple"
      label="Estensione del file"
      showSearch
      options={[
        { label: 'PDF', value: 'PDF' },
        { label: 'TIFF', value: 'TIFF' },
        { label: 'DOC/DOCX', value: 'DOC/DOCX' },
        { label: 'ODT', value: 'ODT' },
        { label: 'XLS/XLSX', value: 'XLS/XLSX' },
        { label: 'ODS', value: 'ODS' },
        { label: 'PPT/PPTX', value: 'PPT/PPTX' },
        { label: 'MSG', value: 'MSG' },
        { label: 'EML', value: 'EML' },
        { label: 'P7M', value: 'P7M' },
        { label: 'XML', value: 'XML' },
        { label: 'JPG', value: 'JPG' },
        { label: 'PNG', value: 'PNG' },
        { label: 'GIF', value: 'GIF' },
        { label: 'BMP', value: 'BMP' },
        { label: 'RAR', value: 'RAR' },
        { label: 'ZIP', value: 'ZIP' },
        { label: '7Zip', value: '7Zip' },
        { label: 'gzip', value: 'gzip' },
        { label: 'Tar', value: 'Tar' },
        { label: 'TXT', value: 'TXT' },
        { label: 'RTF', value: 'RTF' }
      ]}
    />
  );
}
