import { SelectInputItem } from 'client/ui/form/input/SelectInputItem';
import { GlobalPolicyType } from 'common/schema/GlobalConservationPolicy';
import React from 'react';

export interface IPolicyTypeSelectInputProps {
  name: string;
  disabled?: boolean;
}

export function PolicyTypeSelectInput(props: IPolicyTypeSelectInputProps) {
  const { name, disabled } = props;
  return (
    <SelectInputItem
      name={name}
      allowClear
      disabled={disabled}
      // label="Tipo policy"
      placeholder="Seleziona Tipo di Policy..."
      options={[
        { label: 'Documento', value: GlobalPolicyType.Document },
        { label: 'Stampa', value: GlobalPolicyType.Stamp }
      ]}
    />
  );
}
