import * as React from 'react';
import { ISODate } from 'client/ui/display/ISODate';
import { ProcessNumberResult } from './ProcessNumberResult';
import { IGlobalConservationPolicyProcess } from 'common/schema/GlobalConservationPolicyProcess';
import { GlobalConservationPolicyLogic } from 'common/logic/GlobalConservationPolicyLogic';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { Spin, Icon, Tag, Row, Col, Button } from 'antd';
import { GlobalConservationPolicyProcessStateTag } from '../list/GlobalConservationPolicyProcessStateTag';
import { ChangeStateButton } from '../list/ChangeStateButton';
import { Link } from 'react-router-dom';

export interface GlobalPolicyProcessTableCellProps {
  process: IGlobalConservationPolicyProcess | undefined | null;
  currentAooNumber: number | null | undefined;
}

/**
 * Mostra i dati dell'ultimo processo eseguito
 * nella tabella di elenco delle policy globali di conservazione
 */
export function GlobalPolicyProcessTableCell(
  props: GlobalPolicyProcessTableCellProps
) {
  const { process, currentAooNumber } = props;
  if (!process) return null;

  if (!GlobalConservationPolicyLogic.isProcessEnd(process)) {
    return <GlobalConservationPolicyProcessStateTag process={process} />;
  }

  // collegare numero attuale AOO
  const currentProcessed = (process.failure ?? 0) + (process.success ?? 0);

  return (
    <Row gutter={12} align="middle" type="flex">
      <Col style={{ lineHeight: 1.2 }}>
        <div>
          <ISODate date={process.startedAt} format="DD/MM/YYYY HH:MM:SS" />
        </div>
        {process.completedAt ? (
          <div>
            <ProcessNumberResult
              process={currentProcessed}
              current={currentProcessed} // TODO HI: reimpostare a: currentAooNumber ?? undefined
            />
          </div>
        ) : (
          <div>
            <GlobalConservationPolicyProcessStateTag process={process} />
          </div>
        )}
      </Col>
      <Col style={{ lineHeight: 1.2 }}>
        <GlobalConservationPolicyProcessStateTag process={process} />
      </Col>
      <Col>
        <Link
          to={`/global-conservation-policy/process-log/${process.policyId}`}
        >
          <Button size="small">Log</Button>
        </Link>
      </Col>
    </Row>
  );
}
