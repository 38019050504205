import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { Button, Icon, Tag } from 'antd';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import { toPairs } from 'lodash';

export function createLogOperationFilter<T extends {}>(): Partial<
  ColumnProps<T>
> {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <div>
          <CheckboxGroup
            options={[
              {
                label: <Tag>Attivata</Tag>,
                value: 'activated'
              },
              {
                label: <Tag>Disattivata</Tag>,
                value: 'disabled'
              },
              {
                label: <Tag>Creata</Tag>,
                value: 'created'
              },
              {
                label: <Tag>Nessuna</Tag>,
                value: 'none'
              }
            ]}
            value={...selectedKeys!}
            onChange={e => setSelectedKeys!(e ? e.map(v => v.toString()) : [])}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
        </div>
        <Button
          type="primary"
          onClick={() => {
            confirm!();
          }}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Cerca
        </Button>
        <Button
          onClick={() => {
            clearFilters!();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    )
  };
}

export function getLogOperationFilter(
  filters: Record<string, (boolean | React.Key)[] | null>
) {
  return {
    activated: filters.operations?.includes('activated'),
    disabled: filters.operations?.includes('disabled'),
    created: filters.operations?.includes('created'),
    none: filters.operations?.includes('none')
  };
}
