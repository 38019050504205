import React, { useState } from 'react';
import { Button, Col, message, Row } from 'antd';
import { Link } from 'react-router-dom';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { ColumnProps, SorterResult } from 'antd/lib/table';
import { RouteComponentProps } from 'react-router';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { UserApi } from '../UserApi';
import { IUser } from 'common/schema/User';
import { IUsersQueryDto } from 'common/dto/UserDto';
import { ColumnSortDirections } from 'common/dto/shared/ColumnSortDirections';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { TableScroll } from 'client/ui/table/TableScroll';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { useApiMutationState } from 'client/core/network/hooks/useApiMutation';
import { CurrentUser } from 'server/core/params/CurrentUser';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { UserDeleteButton } from '../components/UserDeleteButton';
import {
  createColumnFilter,
  FilterInputType,
  getFilter
} from 'client/ui/table/TableColumnFilter';

interface Params {
  page: string;
}
export interface UsersListProps extends RouteComponentProps<Params> {}

/**
 * Elenco degli utenti a sistema.
 */
export function UsersListPage(props: UsersListProps) {
  const currentUser = useCurrentUser();
  const [query, setQuery] = useState<IUsersQueryDto>({
    page: 1,
    size: 20
  });

  const { response, error, loading } = useApiQuery(UserApi.findUsers, {
    data: {
      query
    }
  });

  const [deleteUser] = useApiMutationState(UserApi.delete, {
    invalidates: [UserApi.findUsers]
  });

  const handleDelete = async (id: number) => {
    try {
      await deleteUser({ data: { id } });
      message.success('Utente eliminato con successo');
    } catch (e) {
      message.error(
        "Si è verificato un errore durante l'operazione di eliminazione. Riprovare."
      );
    }
  };

  const userColumns: ColumnProps<IUser>[] = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      width: 100,
      sorter: true
    },
    {
      key: 'login',
      dataIndex: 'login',
      title: 'User',
      sorter: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Nome',
      sorter: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      key: 'serial',
      dataIndex: 'serial',
      title: 'Matricola (CIP+CIN)',
      sorter: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      key: 'actions',
      align: 'right',
      width: 210,
      render: (text, user) => (
        <Row type="flex" justify="end" gutter={8}>
          <Col>
            <Link to={`/users/edit/${user.id}`}>
              <Button size="small" icon="edit">
                Modifica
              </Button>
            </Link>
          </Col>
          <Col>
            <UserDeleteButton text="Elimina" targetId={user.id} size="small" />
          </Col>
        </Row>
      )
    }
  ];

  if (error)
    return (
      <AlertPage
        message="Impossibile caricare l'elenco degli Utenti"
        description={error.message}
        type="error"
        showIcon
      />
    );

  // if (loading || !response || !response.data) return <LoadingPage />;

  return (
    <PageLayout
      hasMenu
      hasScrollable
      fullTable
      title={<CustomCardTitle icon="user" title="Utenti" />}
      extra={
        <Link to={`/users/edit/create`}>
          <Button icon="plus" type="primary">
            Aggiungi
          </Button>
        </Link>
      }
    >
      <TableScroll<IUser>
        rowKey="id"
        loading={tableLoaderSpin(loading || !response)}
        dataSource={response?.data.items ?? []}
        columns={userColumns}
        onChange={(pagination, filters, sorter) => {
          const { field, order } = sorter as SorterResult<IUser>;

          const name = getFilter(filters, 'name')?.toString();
          const serial = getFilter(filters, 'serial')?.toString();
          const login = getFilter(filters, 'login')?.toString();

          setQuery({
            ...query,
            page: pagination.current || query.page,
            size: pagination.pageSize || query.size,
            name,
            serial,
            login,
            orderBy: sorter.field,
            orderByDirection: sorter.order
              ? ColumnSortDirections[sorter.order]
              : undefined
          });
        }}
        pagination={{
          size: 'small',
          position: 'bottom',
          showSizeChanger: true,
          total: response?.data.meta.total,
          pageSize: query.size,
          current: query.page
        }}
      />
    </PageLayout>
  );
}
