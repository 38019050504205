type SizeUnit = 'bytes' | 'kb';

/**
 * Trasforma un numero di bytes in un numero leggibile
 */
export function humanFileSize(bytes: number, input: SizeUnit = 'bytes') {
  if (input === 'kb') bytes = bytes * 1024;

  if (bytes == 0) {
    return '0.00 B';
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024));
  return (
    (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B'
  );
}
