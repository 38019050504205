import React, { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { RouteComponentProps } from 'react-router';
import { TableScroll } from 'client/ui/table/TableScroll';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { ICurrentInstancesListQueryDto } from 'common/dto/CurrentInstanceDto';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { CurrentInstanceApi } from 'client/components/current-instance/CurrentInstanceApi';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { ColumnProps, SorterResult } from 'antd/lib/table';
import { ICurrentInstance } from 'common/schema/CurrentInstance';
import { ISODate } from 'client/ui/display/ISODate';
import { Link } from 'react-router-dom';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { ColumnSortDirections } from 'common/dto/shared/ColumnSortDirections';
import {
  createColumnFilter,
  FilterInputType,
  getDateRange
} from 'client/ui/table/TableColumnFilter';

export interface ConservationInstanceListPageProps
  extends RouteComponentProps {}

/**
 * Pagina Conservazione - Vista per Istanze Applicative
 * Lista Istanze Applicative C-Prot a cui accedere alla gestione delle policy
 */
export function ConservationInstanceListPage(
  props: ConservationInstanceListPageProps
) {
  const [query, setQuery] = useState<ICurrentInstancesListQueryDto>({
    page: 1,
    size: 20,
    orderBy: 'createdAt',
    orderDirection: ColumnSortDirections.descend
  });

  const { response, error, loading } = useApiQuery(CurrentInstanceApi.list, {
    data: { query }
  });

  const columns: ColumnProps<ICurrentInstance>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 100,
      sorter: true
    },
    {
      title: 'Nome',
      className: 'bold-column',
      dataIndex: 'name',
      ...createColumnFilter(FilterInputType.Text),
      sorter: true
    },
    {
      title: 'Data Aggiunta',
      dataIndex: 'createdAt',
      width: 180,
      render: createdAt => <ISODate date={createdAt} />,
      ...createColumnFilter(FilterInputType.DateRange),
      sorter: true
    },
    {
      title: '',
      key: 'adminTool',
      align: 'right',
      width: 200,
      render: (_, record) =>
        record.backendUrl ? (
          <a href={record.backendUrl}>
            <Button size="small" icon={undefined}>
              Amministrazione
            </Button>
          </a>
        ) : (
          <Tooltip title="Nessun indirizzo di amministrazione presente">
            <Button size="small" disabled={true}>
              Amministrazione
            </Button>
          </Tooltip>
        )
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      width: 250,
      render: (_, record) => (
        <Link to={`/conservation/instance/${record.id}/policies`}>
          <Button size="small" icon="eye">
            Policy di Conservazione
          </Button>
        </Link>
      )
    }
  ];

  if (error)
    return (
      <AlertPage
        message="Impossibile caricare l'elenco delle Istanze di Archivio Corrente"
        description={error.message}
        type="error"
        showIcon
      />
    );

  return (
    <PageLayout
      hasScrollable
      hasMenu
      fullTable
      title={
        <CustomCardTitle
          image="conservation"
          title="Policy di Conservazione | Elenco Istanze applicative"
        />
      }
      // extra={<Search placeholder="Cerca Istanza" enterButton />}
    >
      <TableScroll
        loading={tableLoaderSpin(loading || !response)}
        dataSource={response?.data.items ?? []}
        columns={columns}
        onChange={(pagination, filters, sorter) => {
          const { field, order } = sorter as SorterResult<ICurrentInstance>;
          const name =
            filters.name && filters.name.length > 0
              ? filters.name[0].toString()
              : undefined;

          const createdAt = getDateRange(filters.createdAt);

          setQuery({
            ...query,
            name,
            createdAtFrom: createdAt.from,
            createdAtTo: createdAt.to,
            orderBy: order ? field.toString() : 'createdAt',
            orderDirection: order
              ? ColumnSortDirections[order]
              : ColumnSortDirections.descend,
            page: pagination.current || query.page,
            size: pagination.pageSize || query.size
          });
        }}
        pagination={{
          size: 'small',
          position: 'bottom',
          showSizeChanger: true,
          total: response?.data.meta.total,
          pageSize: query.size,
          current: query.page
        }}
      />
    </PageLayout>
  );
}
