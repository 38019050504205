import * as React from 'react';
import { DepositItemState } from 'common/schema/Deposit';
import Tag, { TagProps } from 'antd/lib/tag';

export interface IDepositItemBadgeProps {
  state: DepositItemState;
}

function mapDepositItemStateToTagProps(state: DepositItemState): TagProps {
  switch (state) {
    case DepositItemState.Prepared:
      return { color: 'purple', children: 'Proposto' };
    case DepositItemState.Running:
      return { color: 'geekblue', children: 'Versamento' };
    case DepositItemState.Partial:
      return { color: 'geekblue', children: 'Parziale' };
    case DepositItemState.Deposited:
      return { color: 'green', children: 'Versato' };
    case DepositItemState.Failed:
      return { color: 'red', children: 'Fallito' };
    case DepositItemState.Removed:
      return { color: 'lime', children: 'Versato ed Eliminato' };
  }
}

/**
 * Mostra lo stato di un elemento del versamento
 */
export function DepositItemBadge(props: IDepositItemBadgeProps) {
  return <Tag {...mapDepositItemStateToTagProps(props.state)} />;
}
