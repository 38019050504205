// Alert
export { default as WarningOutline } from '@ant-design/icons/lib/outline/WarningOutline'; // prettier-ignore

// Validazione / Confirm / Result
export { default as CheckCircleOutline } from '@ant-design/icons/lib/outline/CheckCircleOutline'; // prettier-ignore
export { default as CheckOutline } from '@ant-design/icons/lib/outline/CheckOutline'; // prettier-ignore
export { default as CloseCircleOutline } from '@ant-design/icons/lib/outline/CloseCircleOutline'; // prettier-ignore
export { default as CloseOutline } from '@ant-design/icons/lib/outline/CloseOutline'; // prettier-ignore
export { default as QuestionCircleOutline } from '@ant-design/icons/lib/outline/QuestionCircleOutline'; // prettier-ignore
export { default as InfoCircleOutline } from '@ant-design/icons/lib/outline/InfoCircleOutline'; // prettier-ignore
export { default as ExclamationCircleOutline } from '@ant-design/icons/lib/outline/ExclamationCircleOutline'; // prettier-ignore
export { default as CloseCircleFill } from '@ant-design/icons/lib/fill/CloseCircleFill'; // prettier-ignore
export { default as CheckCircleFill } from '@ant-design/icons/lib/fill/CheckCircleFill'; // prettier-ignore
export { default as InfoCircleFill } from '@ant-design/icons/lib/fill/InfoCircleFill'; // prettier-ignore
export { default as WarningFill } from '@ant-design/icons/lib/fill/WarningFill'; // prettier-ignore

// Paginazione
export { default as LeftOutline } from '@ant-design/icons/lib/outline/LeftOutline'; // prettier-ignore
export { default as RightOutline } from '@ant-design/icons/lib/outline/RightOutline'; // prettier-ignore
export { default as DoubleLeftOutline } from '@ant-design/icons/lib/outline/DoubleLeftOutline'; // prettier-ignore
export { default as DoubleRightOutline } from '@ant-design/icons/lib/outline/DoubleRightOutline'; // prettier-ignore
export { default as DownOutline } from '@ant-design/icons/lib/outline/DownOutline'; // prettier-ignore

// Tree
export { default as CaretRightOutline } from '@ant-design/icons/lib/outline/CaretRightOutline'; // prettier-ignore
export { default as CaretDownOutline } from '@ant-design/icons/lib/outline/CaretDownOutline'; // prettier-ignore
export { default as CaretUpOutline } from '@ant-design/icons/lib/outline/CaretUpOutline'; // prettier-ignore
export { default as FolderOutline } from '@ant-design/icons/lib/outline/FolderOutline'; // prettier-ignore
export { default as FolderOpenOutline } from '@ant-design/icons/lib/outline/FolderOpenOutline'; // prettier-ignore
export { default as CaretDownFill } from '@ant-design/icons/lib/fill/CaretDownFill'; // prettier-ignore
export { default as CaretUpFill } from '@ant-design/icons/lib/fill/CaretUpFill'; // prettier-ignore

// Date
export { default as CalendarOutline } from '@ant-design/icons/lib/outline/CalendarOutline'; // prettier-ignore

// Icone Custom
export { default as EyeOutline } from '@ant-design/icons/lib/outline/EyeOutline'; // prettier-ignore
export { default as UserOutline } from '@ant-design/icons/lib/outline/UserOutline'; // prettier-ignore
export { default as BarsOutline } from '@ant-design/icons/lib/outline/BarsOutline'; // prettier-ignore
export { default as EditOutline } from '@ant-design/icons/lib/outline/EditOutline'; // prettier-ignore
export { default as MailOutline } from '@ant-design/icons/lib/outline/MailOutline'; // prettier-ignore
export { default as LockOutline } from '@ant-design/icons/lib/outline/LockOutline'; // prettier-ignore
export { default as LogoutOutline } from '@ant-design/icons/lib/outline/LogoutOutline'; // prettier-ignore
export { default as LinkOutline } from '@ant-design/icons/lib/outline/LinkOutline'; // prettier-ignore
export { default as DeleteOutline } from '@ant-design/icons/lib/outline/DeleteOutline'; // prettier-ignore
export { default as SearchOutline } from '@ant-design/icons/lib/outline/SearchOutline'; // prettier-ignore
export { default as PaperClipOutline } from '@ant-design/icons/lib/outline/PaperClipOutline'; // prettier-ignore
export { default as PrinterOutline } from '@ant-design/icons/lib/outline/PrinterOutline'; // prettier-ignore
export { default as ReloadOutline } from '@ant-design/icons/lib/outline/ReloadOutline'; // prettier-ignore
export { default as ContainerOutline } from '@ant-design/icons/lib/outline/ContainerOutline'; // prettier-ignore
export { default as HomeOutline } from '@ant-design/icons/lib/outline/HomeOutline'; // prettier-ignore
export { default as DisconnectOutline } from '@ant-design/icons/lib/outline/DisconnectOutline'; // prettier-ignore
export { default as ApartmentOutline } from '@ant-design/icons/lib/outline/ApartmentOutline'; // prettier-ignore
export { default as PlusSquareOutline } from '@ant-design/icons/lib/outline/PlusSquareOutline'; // prettier-ignore
export { default as MinusSquareOutline } from '@ant-design/icons/lib/outline/MinusSquareOutline'; // prettier-ignore
export { default as SettingOutline } from '@ant-design/icons/lib/outline/SettingOutline'; // prettier-ignore
export { default as LoadingOutline } from '@ant-design/icons/lib/outline/LoadingOutline'; // prettier-ignore
export { default as Loading3QuartersOutline } from '@ant-design/icons/lib/outline/Loading3QuartersOutline'; // prettier-ignore
export { default as ArrowLeftOutline } from '@ant-design/icons/lib/outline/ArrowLeftOutline'; // prettier-ignore
export { default as CodeOutline } from '@ant-design/icons/lib/outline/CodeOutline'; // prettier-ignore
export { default as OrderedListOutline } from '@ant-design/icons/lib/outline/OrderedListOutline'; // prettier-ignore
export { default as CopyOutline } from '@ant-design/icons/lib/outline/CopyOutline'; // prettier-ignore
export { default as InboxOutline } from '@ant-design/icons/lib/outline/InboxOutline'; // prettier-ignore
export { default as ExclamationCircleFill } from '@ant-design/icons/lib/fill/ExclamationCircleFill'; // prettier-ignore
export { default as PauseCircleOutline } from '@ant-design/icons/lib/outline/PauseCircleOutline'; // prettier-ignore
export { default as ClockCircleOutline } from '@ant-design/icons/lib/outline/ClockCircleOutline'; // prettier-ignore

// File
export { default as FilePdfOutline } from '@ant-design/icons/lib/outline/FilePdfOutline'; // prettier-ignore
export { default as FilePptOutline } from '@ant-design/icons/lib/outline/FilePptOutline'; // prettier-ignore
export { default as FileExcelOutline } from '@ant-design/icons/lib/outline/FileExcelOutline'; // prettier-ignore
export { default as FileWordOutline } from '@ant-design/icons/lib/outline/FileWordOutline'; // prettier-ignore
export { default as FileFill } from '@ant-design/icons/lib/fill/FileFill'; // prettier-ignore
export { default as FileOutline } from '@ant-design/icons/lib/outline/FileOutline'; // prettier-ignore

// Centro di Gestione
export { default as PlusOutline } from '@ant-design/icons/lib/outline/PlusOutline'; // prettier-ignore
export { default as MinusOutline } from '@ant-design/icons/lib/outline/MinusOutline'; // prettier-ignore
export { default as ToolFill } from '@ant-design/icons/lib/fill/ToolFill'; // prettier-ignore
export { default as ToolOutline } from '@ant-design/icons/lib/outline/ToolOutline'; // prettier-ignore
export { default as SyncOutline } from '@ant-design/icons/lib/outline/SyncOutline'; // prettier-ignore
export { default as PlayCircleOutline } from '@ant-design/icons/lib/outline/PlayCircleOutline'; // prettier-ignore
export { default as FilterFill } from '@ant-design/icons/lib/fill/FilterFill'; // prettier-ignore
