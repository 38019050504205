import * as React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { message, Button, Col, Row } from 'antd';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { Scrollable } from 'client/ui/scroller/Scrollable';
import {
  useApiMutation,
  useApiMutationState
} from 'client/core/network/hooks/useApiMutation';
import { UserApi } from '../UserApi';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { Formik } from 'formik';
import { IUser } from 'common/schema/User';
import { FormikForm } from 'client/ui/form/FormikForm';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';
import { CheckboxInputItem } from 'client/ui/form/input/CheckboxInputItem';
import { SelectInputItem } from 'client/ui/form/input/SelectInputItem';
import { RolesOptions } from 'common/permissions/Roles';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { PageCard } from 'client/ui/layout/PageCard';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { UserDeleteButton } from '../components/UserDeleteButton';
import { CustomCardExtra } from 'client/ui/layout/page-card/CustomCardExtra';

interface Params {
  id: string;
}

export interface UserEditPageProps extends RouteComponentProps<Params> {}

export function UserEditPage(props: UserEditPageProps) {
  // Otteniamo l'ID dell'utente (o `create` per un nuovo utente)
  const id =
    props.match.params.id && props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const currentUser = useCurrentUser();
  const history = useHistory();

  const { response, error, loading, refetch } = useApiQuery(UserApi.findUser, {
    skip: id == null,
    data: {
      id: id!
    }
  });

  // Modifica
  const create = useApiMutation(UserApi.createUser, {
    invalidates: [UserApi.findUser]
  });
  const update = useApiMutation(UserApi.updateUser, {
    invalidates: [UserApi.findUser]
  });

  const [deleteUser] = useApiMutationState(UserApi.delete, {
    data: { id: id! },
    invalidates: [UserApi.findUsers]
  });

  const handleDelete = async () => {
    if (!id) return;
    try {
      await deleteUser({ data: {} });
      message.success('Utente eliminato con successo');
      history.push('/users/list');
    } catch (e) {
      message.error(
        "Si è verificato un errore durante l'operazione di eliminazione. Riprovare."
      );
    }
  };

  if (error)
    return (
      <AlertPage
        type="error"
        message="Impossibile caricare l'Utente"
        description={error.message}
      />
    );

  if (id && !response?.data) return <LoadingPage />;

  return (
    <PageLayout excludeCard hasMenu hasScrollable linkBack="/users/list">
      <Formik<IUser>
        initialValues={
          response && response.data
            ? response.data
            : ({ email: '', name: '' } as any)
        }
        onSubmit={async (values, helpers) => {
          try {
            if (id) {
              await update({
                data: { id: id!, input: values }
              });
            } else {
              const result = await create({
                data: { input: { ...values } }
              });
              props.history.push(`/users/edit/${result.data!.id}`);
            }
            refetch();
            message.success('Utente aggiornato con successo. ');
          } catch (e) {
            console.error('errors', e);
            message.error(
              "Si è verificato un errore durante l'aggiornamento dell'utente."
            );
          }
        }}
      >
        {({ isValid, dirty, submitForm, isSubmitting }) => (
          <PageCard
            loading={loading || isSubmitting}
            hasScrollable
            title={
              <CustomCardTitle
                icon="user"
                title={id ? 'Modifica Utente' : 'Nuovo Utente'}
              />
            }
            extra={
              <CustomCardExtra
                right={
                  <>
                    {id != null && (
                      <UserDeleteButton targetId={id} redirect text="Elimina" />
                    )}
                    <Button
                      htmlType="submit"
                      type="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Salva
                    </Button>
                  </>
                }
              />
            }
          >
            <Scrollable spacing="inside">
              <FormikForm layout="vertical" autoComplete="off">
                <Row gutter={16}>
                  <Col span={12}>
                    <TextInputItem name="login" label="User" />
                    <TextInputItem
                      name="name"
                      label="Nome"
                      placeholder="Nome"
                    />
                    <TextInputItem
                      name="serial"
                      label="Matricola (CIP+CIN)"
                      itemProps={{
                        extra:
                          "Deve corrispondere all'ID di Windows per l'autenticazione integrata"
                      }}
                    />
                    <SelectInputItem
                      options={RolesOptions}
                      name="role"
                      label="Ruolo"
                    />
                  </Col>
                  <Col span={12}>
                    <CheckboxInputItem
                      name="enableAccessKey"
                      title="Abilita login con Access Key"
                      itemProps={{
                        extra:
                          'Disabilitare questa opzione per forzare il login integrato di Windows'
                      }}
                    />
                    <TextInputItem
                      name="accessKey"
                      type={'pa' + 'ssword'}
                      label="Access Key"
                      autoComplete="new-password"
                      itemProps={{
                        dependsOnChecked: 'enableAccessKey'
                      }}
                    />
                  </Col>
                </Row>
              </FormikForm>
            </Scrollable>
          </PageCard>
        )}
      </Formik>
    </PageLayout>
  );
}
