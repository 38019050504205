import { Button, Col, Divider, message, Row, Tag, Popover } from 'antd';
import clx from 'classnames';
import './GlobalConservationPolicyEditPage.scss';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { ButtonConfirm } from 'client/ui/button/ButtonConfirm';
import { ISODate } from 'client/ui/display/ISODate';
import { FormikForm } from 'client/ui/form/FormikForm';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';
import { CustomCardExtra } from 'client/ui/layout/page-card/CustomCardExtra';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { PageCardButtonFooter } from 'client/ui/layout/page-card/PageCardButtonFooter';
import { PageCard } from 'client/ui/layout/PageCard';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import { RowScroller } from 'client/ui/scroller/RowScroller';
import {
  GlobalConservationPolicyDto,
  globalConservationPolicyValidator
} from 'common/dto/GlobalConservationPolicyDto';
import { Formik } from 'formik';
import React, { useCallback } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { GlobalConservationPolicyApi } from '../GlobalConservationPolicyApi';
import { GlobalConservationPolicyForm } from './components/form/GlobalConservationPolicyForm';
import { PolicyTypeSelectInput } from './components/form/PolicyTypeSelectInput';
import { PeriodicitySelectInputItem } from './components/PeriodicitySelectInputItem';
import { DatePickerInputItem } from 'client/ui/form/input/DatePickerInputItem';

interface Params {
  id: string;
}

export interface IGlobalConservationPolicyEditPageProps
  extends RouteComponentProps<Params> {}

export function GlobalConservationPolicyEditPage(
  props: IGlobalConservationPolicyEditPageProps
) {
  const id =
    props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const history = useHistory();

  const { response, error, loading, refetch } = useApiQuery(
    GlobalConservationPolicyApi.find,
    {
      skip: !id,
      data: {
        id: id!
      }
    }
  );

  const update = useApiMutation(GlobalConservationPolicyApi.update, {
    data: { id: id! },
    invalidates: [GlobalConservationPolicyApi.find]
  });

  const create = useApiMutation(GlobalConservationPolicyApi.create, {});

  const deletePolicy = useApiMutation(GlobalConservationPolicyApi.delete, {
    invalidates: [GlobalConservationPolicyApi.find]
  });

  const handleDelete = async (id: number) => {
    try {
      await deletePolicy({ data: { id } });
      message.success('Policy eliminata con successo');
      history.push('/global-conservation-policy/list');
    } catch (e) {
      message.error(
        "Si è verificato un errore durante l'operazione di eliminazione. Riprovare."
      );
    }
  };

  if (error)
    return (
      <AlertPage
        type="error"
        message="Impossibile caricare la policy"
        description={error.message}
      />
    );

  if (id && !response?.data) return <LoadingPage />;

  const policy = response?.data;
  const isDisabled = policy != null;

  return (
    <PageLayout
      excludeCard
      hasMenu
      hasScrollable
      linkBack="/global-conservation-policy/list"
    >
      <Formik<GlobalConservationPolicyDto>
        initialValues={
          policy
            ? policy
            : ({
                description: '',
                protocolIncoming: false,
                protocolOutgoing: false,
                protocolInternal: false,
                grey: false
              } as any)
        }
        enableReinitialize
        validate={schemaToValidator(globalConservationPolicyValidator)}
        onSubmit={async rawValues => {
          try {
            const values = await globalConservationPolicyValidator.validate(
              rawValues
            );

            if (id) {
              await update({
                data: { id: id!, input: values }
              });
              message.success('Policy aggiornata con successo.');
            } else {
              const result = await create({
                data: { input: values }
              });
              message.success('Policy creata con successo.');
              props.history.push(
                `/global-conservation-policy/edit/${result.data!.id}`
              );
            }
            refetch();
          } catch (e) {
            console.error('errors', e);
            message.error("Si è verificato un errore durante l'aggiornamento della Policy."); // prettier-ignore
          }
        }}
      >
        {({ isValid, dirty, submitForm, isSubmitting }) => (
          <PageCard
            className={clx('form-state-controlled', {
              blocked: isDisabled
            })}
            loading={loading || isSubmitting}
            hasScrollable
            title={
              <CustomCardTitle
                tag={policy ? `ID: ${policy.id}` : 'Nuova'}
                title={
                  <span>
                    {policy?.description || 'Policy Globale di Conservazione'}{' '}
                    {isDisabled && <Tag color="blue">Solo lettura</Tag>}
                  </span>
                }
              />
            }
            extra={
              <CustomCardExtra
                right={
                  <>
                    {id != null && (
                      <ButtonConfirm
                        type="danger"
                        ghost
                        title="Sei sicuro di voler eliminare la policy?"
                        children={<>Elimina</>}
                        okButtonProps={{ type: 'danger', ghost: true }}
                        disabled={!id}
                        onConfirm={async () => await handleDelete(id)}
                      />
                    )}

                    {id == null && (
                      <Popover
                        trigger="hover"
                        placement="topRight"
                        content={
                          <span>
                            Salvando la policy non sarà più possibile
                            modificarla.
                          </span>
                        }
                      >
                        <Button
                          htmlType="submit"
                          type="primary"
                          disabled={isSubmitting || loading || isDisabled}
                          onClick={submitForm}
                        >
                          Salva Policy Globale
                        </Button>
                      </Popover>
                    )}
                  </>
                }
              />
            }
          >
            <RowScroller>
              {/* Colonna sinistra  */}
              <ColScroller span={8} side="left" spacing="inside">
                <FormikForm layout="vertical">
                  <TextInputItem
                    name="description"
                    label="Descrizione"
                    disabled={isDisabled}
                  />
                  <TextInputItem
                    name="code"
                    label="Codice"
                    placeholder=""
                    disabled={isDisabled}
                  />
                  <div className="title-text">
                    <span className="title-text-label">Data Creazione: </span>
                    {policy ? <ISODate date={policy.createdAt} /> : 'Adesso'}
                  </div>
                  <Divider>Tipo di Policy</Divider>
                  <PolicyTypeSelectInput name="type" disabled={isDisabled} />
                  <Divider style={{ marginTop: '30px' }}>Periodicità</Divider>
                  <PeriodicitySelectInputItem
                    name="periodicity"
                    disabled={isDisabled}
                  />
                </FormikForm>
              </ColScroller>

              {/* Colonna destra | Regole */}
              <ColScroller
                span={16}
                isCard
                side="right"
                spacing="outside"
                className="discard-policy-rule-card"
              >
                <Divider>Regole di Selezione</Divider>
                <FormikForm layout="vertical">
                  <GlobalConservationPolicyForm disabled={isDisabled} />

                  {/* Bottoni di fine pagina */}
                  {/* <PageCardButtonFooter>
                    <Button disabled={isSubmitting || loading || dirty}>
                      Avvia conservazione
                    </Button>
                  </PageCardButtonFooter> */}
                </FormikForm>
              </ColScroller>
            </RowScroller>
          </PageCard>
        )}
      </Formik>
    </PageLayout>
  );
}
