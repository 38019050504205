import { IStatisticSpaceUsedItemProps } from './StatisticSpaceUsedItem';
import { ICurrentDriverMonitorInstanceResult } from 'common/driver/CurrentDriverDto';
import { IDepositDriverMonitorInstanceResult } from 'common/driver/DepositDriverDto';

export function createStatisticSpaceUsedFromMonitoring(
  monitoring:
    | ICurrentDriverMonitorInstanceResult
    | IDepositDriverMonitorInstanceResult
): IStatisticSpaceUsedItemProps[] {
  if (monitoring.type === 'Failure') return [];

  return [
    {
      title: 'Documenti',
      unit: 'Kb',
      number: monitoring.documentsCount
      // space: monitoring.documentsSize
    },
    {
      title: 'Fascicoli',
      unit: 'Kb',
      number: monitoring.dossiersCount
      // space: monitoring.dossiersSize
    },
    {
      title: 'Allegati',
      unit: 'Kb',
      number: monitoring.attachmentsCount
      // space: monitoring.attachmentsSize
    }
  ];
}
