import { apiClient } from 'client/core/network/apiClient';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';
import { IDepositInstance } from 'common/schema/DepositInstance';
import {
  IDepositPolicyFindQueryDto,
  CreateDepositPolicyDto
} from 'common/dto/DepositPolicyDto';
import { IDepositPolicy } from 'common/schema/DepositPolicy';
import { IDeposit } from 'common/schema/Deposit';

export const DepositPolicyApi = {
  find: (opts: { query: IDepositPolicyFindQueryDto }) =>
    apiClient.get<PaginatedResult<IDepositPolicy>>(`/deposit-policies`, {
      params: opts.query
    }),

  findOne: (opts: { id: number }) =>
    apiClient.get<IDepositPolicy>(`/deposit-policies/${opts.id}`),

  create: (opts: { input: CreateDepositPolicyDto }) =>
    apiClient.post<IDepositPolicy>(`/deposit-policies`, opts.input),

  delete: (opts: { id: number }) =>
    apiClient.delete(`/deposit-policies/${opts.id}`),

  update: (opts: { id: number; input: CreateDepositPolicyDto }) =>
    apiClient.put<IDepositPolicy>(`/deposit-policies/${opts.id}`, opts.input),

  analyze: (opts: { id: number }) =>
    apiClient.get<{ dossiersCount: number; dossiersSize: number }>(
      `/deposit-policies/${opts.id}/actions/analyze`
    ),

  start: (opts: { id: number }) =>
    apiClient.post<IDeposit>(`/deposit-policies/${opts.id}/actions/start`)
};
