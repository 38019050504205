import { IDepositPolicy } from './DepositPolicy';
import { IDepositHistory } from './DepositHistory';
import { assertNever } from 'server/utils/typings/assertNever';

export enum DepositState {
  /**
   * Caricamento dei documenti dall'archivio corrente
   */
  Start = 'start',
  /**
   * Proposta di scarto
   */
  Draft = 'draft',
  /**
   * Versamento dei documenti nell'archivio di deposito
   */
  Send = 'send',
  /** Invio completato parzialmente */
  SentPartial = 'sent-partial',
  /** Invio completato di tutti i fascicoli e documenti */
  SentComplete = 'sent-complete',

  /**
   * Cleanup dei documenti dall'archivio corrente
   */
  Cleanup = 'cleanup',
  /** Cleanup completato parzialmente */
  CleanedPartial = 'cleaned-partial',
  /** Cleanup completato */
  Complete = 'complete',
  Fail = 'fail'
}

export enum DepositProcessingState {
  Queued = 'queued',
  Running = 'running',
  Stalled = 'stalled',
  Completed = 'completed',
  Failed = 'failed',
  Paused = 'paused'
}

export enum DepositItemState {
  Prepared = 'P',
  Running = 'R',
  Partial = 'A',
  Deposited = 'D',
  Removed = 'X',
  Failed = 'F'
}

export function prettyItemState(state: DepositItemState): string {
  switch (state) {
    case DepositItemState.Prepared:
      return 'predisposto';
    case DepositItemState.Running:
      return 'versamento';
    case DepositItemState.Partial:
      return 'parziale';
    case DepositItemState.Deposited:
      return 'versato';
    case DepositItemState.Failed:
      return 'errore';
    case DepositItemState.Removed:
      return 'eliminato';
  }
  assertNever(state);
}

export interface IDeposit {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  completedAt?: Date;
  activityId?: number; // Collegamento all'ID dell'archivio di deposito
  description?: string;
  policy: IDepositPolicy;
  state: DepositState;
  history: IDepositHistory[];
  processing: DepositProcessingState;
  dossiersCount: number;
  requestedPause: boolean;

  // Solo DTO
  dossiersSent: number;
  dossiersRemoved: number;
  dossiersTotal: number;
  documentsSent: number;
  documentsRemoved: number;
  documentsTotal: number;
}

export enum OrderableDepositFields {
  Id = 'id',
  CreatedAt = 'createdAt'
}
