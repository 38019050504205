import * as React from 'react';
import { AsyncSelectInputProps } from 'client/ui/form/input/AsyncSelectInput';
import { FormikValues, useField } from 'formik';
import { AsyncSelectInputItem } from 'client/ui/form/input/AsyncSelectInputItem';
import { CategoryIndexApi } from 'client/components/category-index/CategoryIndexApi';
import { useEffect } from 'react';

export interface ICategoryIndexRuleInputProps {}

/**
 * Permette di selezionare uno più titolari
 */
export function CategoryIndexRuleInput(props: ICategoryIndexRuleInputProps) {
  const [field, meta, helpers] = useField('rules.titolario_in.value');
  const [aooField] = useField('rules.id_aoo_in.value');

  const disabled = !aooField.value || aooField.value.length !== 1;
  const areaCode = aooField?.value?.[0];

  useEffect(() => {
    if (disabled) helpers.setValue(undefined);
  }, [disabled]);

  return (
    <AsyncSelectInputItem
      itemProps={{
        help:
          disabled &&
          'Il filtro per titolario è disponibile per la selezione di una singola A.O.O.'
      }}
      mode="multiple"
      disabled={disabled}
      query={{
        apiFn: CategoryIndexApi.listSelectable,
        options: {
          skip: disabled,
          data: {
            query: {
              areaCode
            }
          }
        }
      }}
      optionTransform={index => ({
        label: index.title,
        value: index.uid
      })}
      name="rules.titolario_in.value"
      label="Titolario"
    />
  );
}
