import { IConservationPolicyProcessResult } from './ConservationPolicyProcessResult';
import {
  GlobalPolicyDepositState,
  IGlobalConservationPolicy
} from './GlobalConservationPolicy';

export interface IGlobalConservationPolicyProcess {
  id: number;

  createdAt: Date;
  updatedAt: Date;
  startedAt?: Date;
  completedAt?: Date;

  description?: string;

  policyId: number;
  policy: IGlobalConservationPolicy;

  state: GlobalConservationPolicyProcessState;

  results?: IConservationPolicyProcessResult[] | null;

  // Risultati aggregati
  success?: number;
  failure?: number;
}

export enum GlobalConservationPolicyProcessState {
  Queued = 'queued',
  Running = 'running',
  /** Completato senza errori */
  Complete = 'complete',
  /** Completato con errori */
  Partial = 'partial',
  Failed = 'failed'
}
