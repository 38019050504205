import { message } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ButtonConfirm } from 'client/ui/button/ButtonConfirm';
import React from 'react';
import { useHistory } from 'react-router';
import { UserApi } from '../UserApi';

export interface IUserDeleteButtonProps {
  targetId: number | null | undefined;
  redirect?: boolean;
  text: string;
  size?: ButtonProps['size'];
}

export function UserDeleteButton(props: IUserDeleteButtonProps) {
  const { targetId, redirect, text, size } = props;

  const history = useHistory();
  const currentUser = useCurrentUser();

  const deleteUser = useApiMutation(UserApi.delete, {
    data: { id: targetId! },
    invalidates: [UserApi.findUsers]
  });

  const handleDelete = async () => {
    try {
      await deleteUser({});
      message.success('Utente eliminato con successo');
      if (redirect) history.push('/users/list');
    } catch (error) {
      message.error(
        `Si è verificato un errore durante l'operazione. Riprovare.`
      );
    }
  };

  return (
    <ButtonConfirm
      type="danger"
      size={size}
      icon="delete"
      title="Sei sicuro di voler eliminare l'utente?"
      children={text}
      okButtonProps={{ type: 'danger' }}
      disabled={targetId == null || targetId === currentUser?.id}
      onConfirm={async () => await handleDelete()}
    />
  );
}
