import { joinPath } from './network/joinPath';

export const appConfig = {
  publicPath: (window as any).CMDC_PUBLIC_PATH || process.env.CMDC_PUBLIC_PATH
};

/**
 * Permette di utilizzare URL importati da webpack anche in caso di
 * sottocartelle.
 */
export function resolvePublicPath(path: any) {
  // console.log('path', path);
  // path ritorna un 'object' anche se era richiesto :string (ho messo :any)
  // per questo l'URL non veniva generato correttamente

  // @todo per ora l'ho modificato così ???
  // ma forse è una configurazione di webpack...
  let localPath: string = path;
  if (typeof path == 'object') {
    localPath = path.default;
  }
  return joinPath([appConfig.publicPath, localPath]);
}

/**
 * Permette di ottenere il link alle API risolvendo sottocartelle e URL
 * delle API.
 */
export function resolveApiPath(path: string) {
  return joinPath([appConfig.publicPath, process.env.REST_URL, path]);
}
