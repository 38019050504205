import React, { useCallback } from 'react';
import { Radio } from 'antd';
import { FormikValues, useField, useFormikContext } from 'formik';
import { RadioChangeEvent, RadioGroupProps } from 'antd/lib/radio';
import './RadioInput.scss';

export interface RadioInputProps<T extends FormikValues>
  extends Omit<RadioGroupProps, 'name'> {
  name: keyof T & string;
}

/**
 * Campo per input di tipo Radio.
 */
export function RadioInput<T extends FormikValues>(props: RadioInputProps<T>) {
  const { name } = props;
  const [field, meta, helpers] = useField<boolean>(name);
  const formik = useFormikContext<T>();
  const handleChange = useCallback(
    (e: RadioChangeEvent) => helpers.setValue(e.target.value),
    []
  );

  return (
    <Radio.Group
      {...props}
      {...field}
      value={field.value}
      onChange={handleChange}
    />
  );
}
