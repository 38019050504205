import { Button, message, Tooltip } from 'antd';
import {
  useApiMutation,
  useApiMutationState
} from 'client/core/network/hooks/useApiMutation';
import { ButtonConfirm } from 'client/ui/button/ButtonConfirm';
import React from 'react';
import { useHistory } from 'react-router';
import { GlobalConservationPolicyApi } from '../GlobalConservationPolicyApi';
import { GlobalConservationPolicyLogic } from 'common/logic/GlobalConservationPolicyLogic';
import { IGlobalConservationPolicy } from 'common/schema/GlobalConservationPolicy';
import { ButtonProps } from 'antd/lib/button';

export interface IGlobalPolicyDeleteButtonProps {
  policy: IGlobalConservationPolicy;
  text: string;
  disabled?: boolean;
  size?: ButtonProps['size'];
}

export function GlobalPolicyDeleteButton(
  props: IGlobalPolicyDeleteButtonProps
) {
  const { policy, text, disabled, size } = props;

  const [deletePolicy] = useApiMutationState(
    GlobalConservationPolicyApi.delete,
    { invalidates: [GlobalConservationPolicyApi.list] }
  );

  const handleDelete = async (id: number | null | undefined) => {
    if (id == null) return;
    try {
      await deletePolicy({ data: { id } });
      message.success('Policy eliminata con successo');
    } catch (e) {
      message.error(
        "Si è verificato un errore durante l'operazione di eliminazione. Riprovare."
      );
    }
  };

  const deleteCheck = GlobalConservationPolicyLogic.canBeDeleted(policy);

  return (
    <Tooltip title={!deleteCheck.allowed ? deleteCheck.message : null}>
      <ButtonConfirm
        disabled={disabled || !policy.id || !deleteCheck.allowed}
        type="danger"
        size={size}
        icon="delete"
        title="Sei sicuro di voler eliminare la policy?"
        children={text}
        okButtonProps={{ type: 'danger', ghost: true }}
        onConfirm={async () => await handleDelete(policy.id)}
      />
    </Tooltip>
  );
}
