import React, { useState } from 'react';
import { Button, Alert, Row, Col, message } from 'antd';
import { RouteComponentProps } from 'react-router';
import { TableScroll } from 'client/ui/table/TableScroll';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { IDepositPolicyFindQueryDto } from 'common/dto/DepositPolicyDto';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DepositPolicyApi } from '../DepositPolicyApi';
import { ColumnProps, SorterResult } from 'antd/lib/table';
import { IDepositPolicy } from 'common/schema/DepositPolicy';
import { ISODate } from 'client/ui/display/ISODate';
import { Link } from 'react-router-dom';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { ColumnSortDirections } from 'common/dto/shared/ColumnSortDirections';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { useApiMutationState } from 'client/core/network/hooks/useApiMutation';
import { DepositPolicyDeleteButton } from '../edit/components/DepositPolicyDeleteButton';

export interface DepositPolicyListPageProps extends RouteComponentProps {}

/**
 * Pagina Con l'elenco dei Versamenti
 */
export function DepositPolicyListPage(props: DepositPolicyListPageProps) {
  const [query, setQuery] = useState<IDepositPolicyFindQueryDto>({
    page: 1,
    size: 10
  });
  const { response, error, loading } = useApiQuery(DepositPolicyApi.find, {
    data: { query }
  });

  const [deletePolicy] = useApiMutationState(DepositPolicyApi.delete, {
    invalidates: [DepositPolicyApi.find]
  });

  const handleDelete = async (id: number) => {
    try {
      await deletePolicy({ data: { id } });
      message.success('Policy eliminata con successo');
    } catch (e) {
      message.error(
        "Si è verificato un errore durante l'operazione di eliminazione. Riprovare."
      );
    }
  };

  const columns: ColumnProps<IDepositPolicy>[] = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      width: 120,
      sorter: true
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'Descrizione',
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Data di Creazione',
      sorter: true,
      render: value => <ISODate date={value} />
    },
    {
      key: 'actions',
      align: 'right',
      className: 'actions',
      width: 220,
      render: (_, policy) => (
        <Row type="flex" justify="end" gutter={8}>
          <Col>
            <Link to={`/deposit-policy/edit/${policy.id}`}>
              <Button size="small" icon="eye">
                Dettaglio
              </Button>
            </Link>
          </Col>
          <Col>
            <DepositPolicyDeleteButton
              size="small"
              text="Elimina"
              targetId={policy.id}
            />
          </Col>
        </Row>
      )
    }
  ];

  if (error && !response)
    return (
      <AlertPage
        type="error"
        message="Impossibile caricare le policy di versamento"
      />
    );

  return (
    <PageLayout
      hasScrollable
      hasMenu
      fullTable
      title={
        <CustomCardTitle image="deposit" title="Elenco Policy di Versamento" />
      }
      extra={
        <Link to={`/deposit-policy/edit/create`}>
          <Button type="primary" icon="plus">
            Nuova Policy di Versamento
          </Button>
        </Link>
      }
    >
      <TableScroll
        loading={tableLoaderSpin(loading || !response)}
        dataSource={response?.data.items ?? []}
        columns={columns}
        onChange={(pagination, filters, sorter) => {
          const { field, order } = sorter as SorterResult<IDepositPolicy>;
          const descriptionSearch =
            filters.description && filters.description.length > 0
              ? filters.description[0].toString()
              : undefined;
          setQuery({
            ...query,
            description: descriptionSearch,
            page: pagination.current || query.page,
            size: pagination.pageSize || query.size,
            orderBy: order ? field.toString() : 'createdAt',
            orderDirection: order
              ? ColumnSortDirections[order]
              : ColumnSortDirections.descend
          });
        }}
        pagination={{
          size: 'small',
          position: 'bottom',
          showSizeChanger: true,
          total: response?.data.meta.total,
          pageSize: query.size,
          current: query.page,
          pageSizeOptions: ['5', '10', '20', '30', '40']
        }}
      />
    </PageLayout>
  );
}
