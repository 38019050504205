import * as React from 'react';
import { Alert } from 'antd';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { CurrentInstanceApi } from '../CurrentInstanceApi';
import { useState } from 'react';
import { ICurrentAreaQueryDto } from 'common/dto/CurrentAreaDto';
import { ColumnProps } from 'antd/lib/table';
import { ICurrentArea } from 'common/schema/CurrentArea';
import { ColumnSortDirections } from 'common/dto/shared/ColumnSortDirections';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { Table } from 'client/ui/table/Table';
import { AlertPage } from 'client/ui/alert/AlertPage';

export interface ICurrentInstanceAreasProps {
  instanceId: string;
}

/**
 * Elenco delle A.O.O. di un'istanza
 */
export function CurrentInstanceAreasList(props: ICurrentInstanceAreasProps) {
  const [query, setQuery] = useState<ICurrentAreaQueryDto>({
    page: 1,
    size: 20
  });
  const { response, error, loading } = useApiQuery(
    CurrentInstanceApi.findAreas,
    {
      data: {
        id: props.instanceId,
        query
      }
    }
  );

  const columns: ColumnProps<ICurrentArea>[] = [
    {
      key: 'code',
      dataIndex: 'code',
      title: 'Codice',
      width: 220,
      sorter: true
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Nome',
      sorter: true
    },
    {
      key: 'organization.name',
      dataIndex: 'organization.name',
      title: 'Amministrazione',
      sorter: true
    }
  ];

  if (error && !response)
    return <AlertPage type="error" message="Impossibile caricare le A.O.O." />;

  return (
    <Table
      loading={tableLoaderSpin(loading || !response)}
      dataSource={response?.data.items ?? []}
      columns={columns}
      onChange={(pagination, filters, sorter) => {
        setQuery({
          ...query,
          page: pagination.current || query.page,
          size: pagination.pageSize || query.size,
          orderBy: sorter.field,
          orderByDirection: sorter.order
            ? ColumnSortDirections[sorter.order]
            : undefined
        });
      }}
      pagination={{
        size: 'small',
        position: 'bottom',
        showSizeChanger: true,
        total: response ? response.data.meta.total : 0,
        pageSize: query.size,
        current: query.page
      }}
    />
  );
}
