import { SelectInputItem } from 'client/ui/form/input/SelectInputItem';
import { PolicyInterestDateType } from 'common/schema/GlobalConservationPolicy';
import React from 'react';

export interface IDateOfInterestSelectInputItemProps {
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
}

export function DateOfInterestSelectInputItem(
  props: IDateOfInterestSelectInputItemProps
) {
  const { name, label, placeholder, disabled } = props;
  return (
    <SelectInputItem
      disabled={disabled}
      label={label}
      name={name}
      placeholder={placeholder}
      allowClear
      options={[
        { value: PolicyInterestDateType.Single, label: 'Valore Singolo' },
        { value: PolicyInterestDateType.Range, label: 'Intervallo' },
        { value: PolicyInterestDateType.Today, label: 'Giorno corrente' },
        { value: PolicyInterestDateType.Week, label: 'Settimana corrente' },
        { value: PolicyInterestDateType.Month, label: 'Mese corrente' },
        { value: PolicyInterestDateType.Year, label: 'Anno corrente' },
        { value: PolicyInterestDateType.Yesterday, label: 'Giorno precedente' },
        { value: PolicyInterestDateType.LastWeek, label: 'Settimana precedente' }, // prettier-ignore
        { value: PolicyInterestDateType.LastMonth, label: 'Mese precedente' },
        { value: PolicyInterestDateType.LastYear, label: 'Anno precedente' },
        { value: PolicyInterestDateType.DaysBefore, label: 'Giorni precedenti' }
      ]}
    />
  );
}
