import * as React from 'react';
import { IDeposit } from 'common/schema/Deposit';
import { Button, message, Popconfirm } from 'antd';
import { useApiMutationState } from 'client/core/network/hooks/useApiMutation';
import { DepositApi } from '../DepositApi';
import { DepositLogic } from 'common/logic/DepositLogic';

export interface IDepositCleanupButtonProps {
  deposit: IDeposit;
}

/**
 * Avvia l'eliminazione dei documenti dal corrente
 */
export function DepositCleanupButton(props: IDepositCleanupButtonProps) {
  const { deposit } = props;

  const [cleanup, { loading }] = useApiMutationState(DepositApi.cleanup, {
    data: { id: deposit.id },
    invalidates: [DepositApi.findOne]
  });

  const handleCleanup = async () => {
    try {
      await cleanup({});
      message.success("L'eliminazione è stata avviata con successo.");
    } catch (e) {
      message.error(
        "Si è verificato un errore durante l'avvio dell'eliminazione."
      );
      console.error(e);
    }
  };

  if (!DepositLogic.isCleanable(deposit)) return null;

  return (
    <>
      <Popconfirm
        placement="top"
        title={
          <div style={{ width: '400px' }}>
            Confermi di voler avviare l'eliminazione dall'Archivio Corrente dei
            documenti e dei fascicoli versati con il versamento #{deposit.id}{' '}
            riguardante {deposit.dossiersCount} fascicoli?
          </div>
        }
        okText="Conferma e Avvia Eliminazione"
        cancelText="Annulla"
        okButtonProps={{ type: 'danger' }}
        onConfirm={handleCleanup}
      >
        <Button type="danger" icon="play-circle" loading={loading}>
          Avvia Eliminazione
        </Button>
      </Popconfirm>
    </>
  );
}
