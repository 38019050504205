import React from 'react';
import { FormikValues } from 'formik';
import { FormItem, SingleFormItemProps } from '../FormItem';
import { NumberInput, NumberInputProps } from './NumberInput';

interface Props<T extends FormikValues> extends NumberInputProps<T> {
  itemProps?: SingleFormItemProps<T>;
  label?: React.ReactNode;
}

/**
 * Form Item per input numerico.
 */
export function NumberInputItem<T extends FormikValues>(props: Props<T>) {
  const { name, label, itemProps, ...otherProps } = props;

  return (
    <FormItem {...itemProps} name={name} label={label}>
      <NumberInput {...otherProps} name={name} />
    </FormItem>
  );
}
