import * as React from 'react';
import { IDeposit, DepositProcessingState } from 'common/schema/Deposit';
import { Button, message } from 'antd';
import { useApiMutationState } from 'client/core/network/hooks/useApiMutation';
import { DepositApi } from '../DepositApi';
import { DepositLogic } from 'common/logic/DepositLogic';

export interface IDepositRetryButtonProps {
  deposit: IDeposit;
}

export function DepositRetryButton(props: IDepositRetryButtonProps) {
  const { deposit } = props;

  const [retry, { loading }] = useApiMutationState(DepositApi.retry, {
    data: { id: deposit.id },
    invalidates: [DepositApi.findOne]
  });

  const handleRetry = async () => {
    try {
      await retry({});
      message.success('Il versamento è stato riavviato con successo.');
    } catch (e) {
      message.error('Si è verificato un errore durante il riavvio.');
      console.error(e);
    }
  };

  if (!DepositLogic.isRetryable(deposit)) return null;

  return (
    <>
      <Button
        type="primary"
        icon="reload"
        loading={loading}
        onClick={handleRetry}
      >
        Riavvia
      </Button>
    </>
  );
}
