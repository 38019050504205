import * as React from 'react';
import { IDepositPolicy, DepositPolicyRule } from 'common/schema/DepositPolicy';
import { SelectionRule } from '../selection-rule/SelectionRule';
import { ISODate } from 'client/ui/display/ISODate';

export interface ISelectionRulesProps {
  policy: IDepositPolicy;
}

// TODO: Ottenere i dati dinamici (label)
export function DepositPolicySelectionRules(props: ISelectionRulesProps) {
  const { rules } = props.policy;
  return (
    <div>
      {rules.id_instance_eq?.value && (
        <SelectionRule
          label="Istanza"
          rule="è"
          parameter={getRuleValue(rules.id_instance_eq)}
        />
      )}
      {rules.id_aoo_eq?.value && (
        <SelectionRule
          label="A.O.O."
          rule="è"
          parameter={getRuleValue(rules.id_aoo_eq)}
        />
      )}
      {rules.id_aoo_in?.value && (
        <SelectionRule
          label="A.O.O."
          rule="in"
          parameter={getRuleValue(rules.id_aoo_in)}
        />
      )}
      {rules.titolario_in?.value && (
        <SelectionRule
          label="Titolario"
          rule="in"
          parameter={getRuleValue(rules.titolario_in)}
        />
      )}
      {rules.chiusura_dopo_il?.value && (
        <SelectionRule
          label="Data di Creazione"
          rule="dopo il"
          parameter={getRuleValue(rules.chiusura_dopo_il)}
        />
      )}
      {rules.chiusura_prima_del?.value && (
        <SelectionRule
          label="Data di Creazione"
          rule="prima del"
          parameter={getRuleValue(rules.chiusura_prima_del)}
        />
      )}
    </div>
  );
}

function getRuleValue(rule: DepositPolicyRule) {
  return rule.displayValue ?? rule.value;
}
