import React from 'react';
import { SelectInputItem } from 'client/ui/form/input/SelectInputItem';

export interface ITypologySelectInputItemProps {
  disabled?: boolean;
}

export function TypologySelectInputItem(props: ITypologySelectInputItemProps) {
  const { disabled } = props;
  return (
    <SelectInputItem
      disabled={disabled}
      label="Tipologia"
      name="typology"
      options={[
        { label: 'Memoriale del servizio', value: 'Memoriale del servizio' },
        { label: 'Ordine di servizio', value: 'Ordine di servizio' },
        {
          label: 'Domande concessioni licenza',
          value: 'Domande concessioni licenza'
        },
        {
          label: 'Documenti matricolari unici',
          value: 'Documenti matricolari unici'
        },
        {
          label: 'Documenti impego mezzi (DIM)',
          value: 'Documenti impego mezzi (DIM)'
        },
        { label: 'Registro di protocollo', value: 'Registro di protocollo' },
        { label: 'Fascicolo del personale', value: 'Fascicolo del personale' },
        { label: 'Fattura', value: 'Fattura' },
        { label: 'Contratto', value: 'Contratto' },
        { label: 'Note caratteristiche', value: 'Note caratteristiche' },
        { label: 'Sigma', value: 'Sigma' },
        { label: 'Gamma', value: 'Gamma' }
      ]}
    />
  );
}
