import { apiClient } from 'client/core/network/apiClient';
import { IUser } from 'common/schema/User';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';
import { IUsersQueryDto } from 'common/dto/UserDto';

export interface CreateServiceTokenResult {
  serviceToken: string;
  serviceTokenJwt: string;
}

export const UserApi = {
  findUser: (opts: { id: number }) => apiClient.get<IUser>(`/users/${opts.id}`),
  createUser: (opts: { input: IUser }) =>
    apiClient.post<IUser>(`/users`, opts.input),
  updateUser: (opts: { id: number; input: IUser }) =>
    apiClient.put<IUser>(`/users/${opts.id}`, opts.input),
  /**
   * TODO: Aggiungere paginazione
   */
  findUsers: (opts: { query: IUsersQueryDto }) =>
    apiClient.get<PaginatedResult<IUser>>(`/users`, {
      params: opts.query
    }),

  delete: (opts: { id: number }) =>
    apiClient.delete<{ result: boolean }>(`/users/${opts.id}`)
};
