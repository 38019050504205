import React from 'react';
import { Button, Divider, Typography, Row, Col, message } from 'antd';
const { Paragraph, Text } = Typography;
import { RouteComponentProps, useHistory } from 'react-router';
import { PageCardButtonFooter } from 'client/ui/layout/page-card/PageCardButtonFooter';
import { FormikForm } from 'client/ui/form/FormikForm';
import { Formik } from 'formik';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';
import { DatePickerInputItem } from 'client/ui/form/input/DatePickerInputItem';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { CustomCardExtra } from 'client/ui/layout/page-card/CustomCardExtra';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { DepositPolicyApi } from '../DepositPolicyApi';
import { ISODate } from 'client/ui/display/ISODate';
import {
  CreateDepositPolicyDto,
  createDepositPolicyValidator
} from 'common/dto/DepositPolicyDto';
import { AsyncSelectInputItem } from 'client/ui/form/input/AsyncSelectInputItem';
import { CurrentInstanceApi } from 'client/components/current-instance/CurrentInstanceApi';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { DepositPolicyAnalyzeButton } from './DepositPolicyAnalyzeButton';
import { DepositPolicyStartButton } from './DepositPolicyStartButton';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CategoryIndexRuleInput } from './inputs/CategoryIndexRuleInput';
import { PageCard } from 'client/ui/layout/PageCard';
import { DepositPolicyDeleteButton } from './components/DepositPolicyDeleteButton';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import { RowScroller } from 'client/ui/scroller/RowScroller';

interface Params {
  id: string;
}

export interface DepositPolicyEditPageProps
  extends RouteComponentProps<Params> {}

/**
 * Pagina della Singola Policy di Versamento. Ne permette la creazione e la
 * modifica.
 */
export function DepositPolicyEditPage(props: DepositPolicyEditPageProps) {
  const id =
    props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const history = useHistory();

  const { response, error, loading, refetch } = useApiQuery(
    DepositPolicyApi.findOne,
    {
      skip: !id,
      data: {
        id: id!
      }
    }
  );

  const update = useApiMutation(DepositPolicyApi.update, {
    data: { id: id! }
  });

  const create = useApiMutation(DepositPolicyApi.create, {});

  const deletePolicy = useApiMutation(DepositPolicyApi.delete, {
    invalidates: [DepositPolicyApi.find]
  });

  const handleDelete = async (id: number) => {
    try {
      await deletePolicy({ data: { id } });
      message.success('Policy eliminata con successo');
      history.push('/deposit-policy/list');
    } catch (e) {
      message.error(
        "Si è verificato un errore durante l'operazione di eliminazione. Riprovare."
      );
    }
  };

  if (error)
    return (
      <AlertPage
        type="error"
        message="Impossibile caricare la policy"
        description={error.message}
      />
    );

  if (id && !response?.data) return <LoadingPage />;

  const policy = response?.data;

  return (
    <PageLayout
      excludeCard
      hasMenu
      hasScrollable
      linkBack="/deposit-policy/list"
    >
      <Formik<CreateDepositPolicyDto>
        initialValues={
          policy ? policy : ({ description: '', rules: {} } as any)
        }
        enableReinitialize
        validate={schemaToValidator(createDepositPolicyValidator)}
        onSubmit={async (rawValues, helpers) => {
          const values = await createDepositPolicyValidator.validate(rawValues);

          try {
            if (id) {
              await update({
                data: { id: id!, input: values }
              });
            } else {
              const result = await create({
                data: { input: values }
              });
              props.history.push(`/deposit-policy/edit/${result.data!.id}`);
            }
            refetch();
            message.success('Policy aggiornata con successo.');
          } catch (e) {
            console.error('errors', e);
            message.error("Si è verificato un errore durante l'aggiornamento della Policy."); // prettier-ignore
          }
        }}
      >
        {({ isValid, dirty, submitForm, isSubmitting }) => (
          <PageCard
            loading={loading || isSubmitting}
            hasScrollable
            title={
              <CustomCardTitle
                tag={policy ? `ID: ${policy.id}` : 'Nuova'}
                title={policy?.description || 'Policy di Versamento'}
              />
            }
            extra={
              <CustomCardExtra
                right={
                  <>
                    {/* <div className="title-text">
                      <span className="title-text-label">Data Creazione: </span>
                      {policy ? <ISODate date={policy.createdAt} /> : 'Adesso'}
                    </div> */}
                    {id != null && (
                      <DepositPolicyDeleteButton
                        text="Elimina"
                        targetId={id}
                        redirect
                      />
                    )}
                    <DepositPolicyStartButton
                      policy={policy}
                      disabled={isSubmitting || loading || dirty}
                    />
                    {/* <Divider type="vertical"></Divider> */}
                    <Button
                      htmlType="submit"
                      type="primary"
                      disabled={isSubmitting || loading}
                      onClick={submitForm}
                    >
                      Salva Policy
                    </Button>
                  </>
                }
              />
            }
          >
            <RowScroller>
              {/* Colonna sinistra  */}
              <ColScroller span={8} side="left" spacing="inside">
                <FormikForm layout="vertical">
                  <TextInputItem
                    name="description"
                    label="Descrizione"
                    placeholder="Descrizione"
                  />
                  <div className="title-text">
                    <span className="title-text-label">Data Creazione: </span>
                    {policy ? <ISODate date={policy.createdAt} /> : 'Adesso'}
                  </div>
                </FormikForm>
              </ColScroller>

              {/* Colonna destra | Regole */}
              <ColScroller
                span={16}
                isCard
                side="right"
                spacing="outside"
                className="discard-policy-rule-card"
              >
                <Divider>Regole di Selezione</Divider>
                <FormikForm layout="vertical">
                  <Paragraph type="secondary">
                    Regole di Selezione dei Fascicoli da Versare in Archivio di
                    Deposito.{' '}
                    <Text strong>
                      Si verificano tutte le seguenti condizioni:
                    </Text>
                  </Paragraph>
                  <Row gutter={12}>
                    <Col span={6}>
                      <AsyncSelectInputItem
                        query={{
                          apiFn: CurrentInstanceApi.listSelectable,
                          options: { data: {} }
                        }}
                        optionTransform={instance => ({
                          label: instance.name,
                          value: instance.id
                        })}
                        name="rules.id_instance_eq.value"
                        label="Istanza C-PROT"
                      />
                    </Col>
                    <Col span={18}>
                      <AsyncSelectInputItem
                        mode="multiple"
                        query={{
                          apiFn: CurrentInstanceApi.listAreasSelectable,
                          options: formik => {
                            let rule = formik.values.rules.id_instance_eq;
                            return {
                              skip: !rule || !rule.value,
                              data: {
                                id: rule?.value as string
                              }
                            };
                          }
                        }}
                        optionTransform={area => ({
                          label: area.name,
                          value: area.code
                        })}
                        name="rules.id_aoo_in.value"
                        label="AOO (Lasciare il campo vuoto per Tutte le voci)"
                      />
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col span={6}></Col>
                    <Col span={18}>
                      <CategoryIndexRuleInput />
                      {/* <SelectInputItem
                        mode="tags"
                        options={[]}
                        name="rules.classifica_in.value"
                        label="Classifica (Lasciare il campo vuoto per Tutte le voci)"
                      /> */}
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col span={9} offset={6}>
                      <DatePickerInputItem
                        type="from"
                        label="Chiuso dopo il:"
                        name="rules.chiusura_dopo_il.value"
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        disabledTime={() => ({})}
                        style={{ width: '100%' }}
                      />
                    </Col>
                    <Col span={9}>
                      <DatePickerInputItem
                        type="to"
                        label="Chiuso prima del:"
                        name="rules.chiusura_prima_del.value"
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        disabledTime={() => ({})}
                        style={{ width: '100%' }}
                      />
                    </Col>
                  </Row>

                  {/* Bottoni di fine pagina */}
                  <PageCardButtonFooter>
                    <DepositPolicyAnalyzeButton
                      policy={policy}
                      disabled={isSubmitting || loading || dirty}
                    />
                  </PageCardButtonFooter>
                </FormikForm>
              </ColScroller>
            </RowScroller>
          </PageCard>
        )}
      </Formik>
    </PageLayout>
  );
}
