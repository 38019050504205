import React from 'react';
import { Row, Col, Alert, Card } from 'antd';
import { CardDashboard } from 'client/ui/card/CardDashboard';
import { StatisticSpaceUsedItem } from 'client/ui/statistic/StatisticSpaceUsedItem';
import { StatisticTitle } from 'client/ui/statistic/StatisticTitle';
import { StatisticCC } from 'client/ui/statistic/StatisticCC';
import moment from 'moment';
import { IconVector } from 'client/ui/icon/IconVector';
import { IDashboardDepositData } from 'common/schema/Dashboard';

export interface DashboardDepositDetailProps {
  depositDetail: IDashboardDepositData;
}

/**
 * Elemento per mostrare i dati dell'archivio di deposito nella dashboard
 *
 */
export function DashboardDepositDetail(props: DashboardDepositDetailProps) {
  const { depositDetail } = props;

  console.log(depositDetail);
  return (
    <CardDashboard
      title={
        <span>
          <IconVector image="deposit" /> Archivio di Deposito
        </span>
      }
    >
      <Row gutter={12}>
        <StatisticSpaceUsedItem
          data={{
            title: 'Fascicoli',
            number: depositDetail.dossiers
          }}
          type="compact"
          key={1}
          dataLabel="Totale"
          column={3}
        />
        <StatisticSpaceUsedItem
          data={{
            title: 'Documenti',
            number: depositDetail.documents
          }}
          type="compact"
          key={2}
          dataLabel="Totale"
          column={3}
        />
        <StatisticSpaceUsedItem
          data={{
            title: 'Allegati',
            number: depositDetail.attachments
          }}
          type="compact"
          key={3}
          dataLabel="Totale"
          column={3}
        />
      </Row>
      <Card size="small" style={{ margin: '12px 0' }}>
        <Row gutter={48}>
          <Col span={24}>
            <StatisticTitle>Versamenti in Deposito</StatisticTitle>
          </Col>
          <Col span={8}>
            <StatisticCC
              title="Completati"
              value={depositDetail.depositState.completed}
            />
          </Col>
          <Col span={8}>
            <StatisticCC
              title="In Corso"
              value={depositDetail.depositState.running}
            />
          </Col>
          <Col span={8}>
            <StatisticCC
              title="Ultimo Versamento"
              value={
                depositDetail.depositState.lastDeposit
                  ? moment(depositDetail.depositState.lastDeposit).format(
                      'DD/MM/YYYY'
                    )
                  : '--'
              }
            />
          </Col>
        </Row>
      </Card>
    </CardDashboard>
  );
}
