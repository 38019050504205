import { Divider, Tag } from 'antd';
import { ColumnProps, SorterResult } from 'antd/lib/table';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { TableScroll } from 'client/ui/table/TableScroll';
import { ColumnSortDirections } from 'common/dto/shared/ColumnSortDirections';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IConservationPolicyProcessResult } from 'common/schema/ConservationPolicyProcessResult';
import { GlobalConservationPolicyProcessStateTag } from '../list/GlobalConservationPolicyProcessStateTag';
import {
  ProcessResultStateTag,
  ConservationPolicyProcessResultTypeOptions
} from './badge/ProcessResultStateTag';
import {
  ConservationPolicyResultStateTag,
  ConservationPolicyResultStateOptions
} from './badge/ConservationPolicyResultStateTag';
import { GlobalConservationPolicyProcessResultQueryDto } from 'common/dto/GlobalConservationPolicyProcessResultDto';
import { GlobalConservationPolicyApi } from '../GlobalConservationPolicyApi';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { LogOperationTag } from './badge/LogOperationTag';
import {
  createLogOperationFilter,
  getLogOperationFilter
} from './filter/createLogOperationFilter';

interface Params {
  id: string;
}

export interface IGlobalConservationPolicyProcessLogListPageProps
  extends RouteComponentProps<Params> {}

export function GlobalConservationPolicyProcessLogListPage(
  props: IGlobalConservationPolicyProcessLogListPageProps
) {
  const id = parseInt(props.match.params.id, 10);

  const [query, setQuery] = useState<
    GlobalConservationPolicyProcessResultQueryDto
  >({
    page: 1,
    size: 10
  });

  const policyRequest = useApiQuery(GlobalConservationPolicyApi.find, {
    data: { id }
  });

  const policy = policyRequest.response?.data;

  const { response, error, loading } = useApiQuery(
    GlobalConservationPolicyApi.listLogs,
    {
      data: { policyId: id, processId: policy?.lastProcess?.id!, query },
      skip: !policy?.lastProcess?.id
    }
  );

  const logs = response?.data.items;

  const columns: ColumnProps<IConservationPolicyProcessResult>[] = [
    {
      key: 'type',
      dataIndex: 'type',
      title: 'Esito',
      width: 110,
      sorter: true,
      render: value => <ProcessResultStateTag type={value} />,
      ...createColumnFilter(FilterInputType.CheckBox, {
        options: ConservationPolicyProcessResultTypeOptions
      })
    },
    {
      key: 'operations',
      dataIndex: 'operations',
      title: 'Operazioni',
      width: 170,
      ...createLogOperationFilter(),
      render: (_, record) => <LogOperationTag log={record} />
    },

    {
      key: 'description',
      dataIndex: 'description',
      title: 'Descrizione',
      ellipsis: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      key: 'policyState',
      dataIndex: 'policyState',
      title: 'Stato',
      width: 110,
      sorter: true,
      render: value => <ConservationPolicyResultStateTag state={value} />,
      ...createColumnFilter(FilterInputType.CheckBox, {
        options: ConservationPolicyResultStateOptions
      })
    },
    {
      key: 'instanceId',
      dataIndex: 'instanceId',
      title: 'Istanza',
      width: 140,
      ellipsis: true,
      sorter: true,
      render: (_, record) => record.instance?.name,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      key: 'areaCode',
      dataIndex: 'areaCode',
      title: 'Codice AOO',
      width: 140,
      ellipsis: true,
      sorter: true,
      render: (_, record) => record.areaCode,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      key: 'areaName',
      dataIndex: 'areaName',
      title: 'Nome AOO',
      ellipsis: true,
      render: (_, record) => record.areaName,
      ...createColumnFilter(FilterInputType.Text)
    }
  ];

  if (!policy?.lastProcess || policyRequest.loading) {
    return <LoadingPage />;
  }

  if (error && !response)
    return (
      <AlertPage
        type="error"
        message="Impossibile caricare le policy di conservazione"
      />
    );

  return (
    <PageLayout
      linkBack="/global-conservation-policy/list"
      hasScrollable
      hasMenu
      fullTable
      title={
        <CustomCardTitle
          image="conservation"
          title={
            <span>
              Log Processo "{policy.description}"{' '}
              <GlobalConservationPolicyProcessStateTag
                process={policy.lastProcess}
              />
            </span>
          }
        />
      }
    >
      <TableScroll
        loading={tableLoaderSpin(loading || !response)}
        dataSource={logs ?? []}
        rowKey="id"
        columns={columns}
        onChange={(pagination, filters, sorter) => {
          const { field, order } = sorter as SorterResult<
            IConservationPolicyProcessResult
          >;

          const descriptionSearch =
            filters.description && filters.description.length > 0
              ? filters.description[0].toString()
              : undefined;

          const instanceSearch =
            filters.instance && filters.instance.length > 0
              ? filters.instance[0].toString()
              : undefined;

          const aooCodeSearch =
            filters.areaCode && filters.areaCode.length > 0
              ? filters.areaCode[0].toString()
              : undefined;

          const aooNameSearch =
            filters.areaName && filters.areaName.length > 0
              ? filters.areaName[0].toString()
              : undefined;

          const states =
            filters.policyState && filters.policyState.length > 0
              ? filters.policyState
              : undefined;

          const types =
            filters.type && filters.type.length > 0 ? filters.type : undefined;

          const operationFilter = getLogOperationFilter(filters);

          setQuery({
            ...query,
            description: descriptionSearch,
            states,
            types,
            instance: instanceSearch,
            aooCode: aooCodeSearch,
            aooName: aooNameSearch,
            operationActivated: operationFilter.activated,
            operationCreated: operationFilter.created,
            operationDisabled: operationFilter.disabled,
            operationNone: operationFilter.none,
            page: pagination.current || query.page,
            size: pagination.pageSize || query.size,
            orderBy: order ? field.toString() : 'createdAt',
            orderDirection: order
              ? ColumnSortDirections[order]
              : ColumnSortDirections.descend
          });
        }}
        pagination={{
          size: 'small',
          position: 'bottom',
          showSizeChanger: true,
          total: response?.data.meta.total,
          pageSize: query.size,
          current: query.page,
          pageSizeOptions: ['5', '10', '20', '30', '40']
        }}
      />
    </PageLayout>
  );
}
