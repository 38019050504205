import React from 'react';
import { Formik } from 'formik';
import { Card, Icon, Button } from 'antd';
import { FormikForm } from 'client/ui/form/FormikForm';
import { useDispatch } from 'react-redux';
import { AuthActions } from '../AuthModule';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { AuthApi } from '../AuthApi';
import { ICreateAccessTokenDto } from 'common/schema/Auth';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';

interface Props {}

export function LoginForm(props: Props) {
  const login = useApiMutation(AuthApi.createAccessToken, {});
  const dispatch = useDispatch();

  return (
    <Formik<ICreateAccessTokenDto>
      initialValues={{ login: '', accessKey: '' }}
      onSubmit={async (values, helpers) => {
        const result = await login({ data: values });
        dispatch(AuthActions.loginSucceed(result.data.accessToken));
      }}
    >
      <Card bordered={false}>
        <FormikForm layout="vertical">
          <TextInputItem<ICreateAccessTokenDto>
            name="login"
            autoComplete="username"
            prefix={<Icon type="mail" />}
            placeholder="User"
          />
          <TextInputItem<ICreateAccessTokenDto>
            name="accessKey"
            type={'pa' + 'ssword'}
            autoComplete="current-accessKey"
            prefix={<Icon type="lock" />}
            placeholder="Access Key"
          />
          <Button
            data-testid="submit"
            htmlType="submit"
            type={'primary' as any}
            style={{ width: '100%' }}
          >
            Accedi
          </Button>
        </FormikForm>
      </Card>
    </Formik>
  );
}
