import React from 'react';
import { Row, Col, Alert } from 'antd';
import { RouteComponentProps } from 'react-router';
import { LabeledText } from 'client/ui/labeled-text/LabeledText';
import { StatisticSpaceUsedItem } from 'client/ui/statistic/StatisticSpaceUsedItem';
import { PageCardButtonFooter } from 'client/ui/layout/page-card/PageCardButtonFooter';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { CustomCardExtra } from 'client/ui/layout/page-card/CustomCardExtra';
import { Scrollable } from 'client/ui/scroller/Scrollable';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DepositInstanceApi } from '../DepositInstanceApi';
import { DepositInstanceFormModal } from './DepositInstanceFormModal';
import { createStatisticSpaceUsedFromMonitoring } from 'client/ui/statistic/createStatisticSpaceUsedFromMonitoring';
import { PageLayout } from 'client/ui/layout/PageLayout';

export interface DepositInstanceViewPageProps extends RouteComponentProps {}

/**
 * Pagina dell'istanza dell'archivio di Deposito (C-Deposito)
 */
export function DepositInstanceViewPage(props: DepositInstanceViewPageProps) {
  const { response, error, loading } = useApiQuery(DepositInstanceApi.find, {
    data: { query: { includeMonitoring: true } }
  });

  const instance = response?.data.instance;
  const monitoring = response?.data.monitoring;

  // TODO: gestire errori ???

  return (
    <PageLayout
      hasMenu
      hasScrollable
      loading={loading || !response}
      title={
        <CustomCardTitle
          tag={`ID: ${instance?.id ?? ''}`}
          titleLabel="Nome Istanza Archivio di Deposito:"
          title={instance?.name ?? ''}
          tagTheme="filled"
        />
      }
      extra={
        instance ? (
          <CustomCardExtra
            showStatus
            statusOnline={monitoring?.type === 'Success'}
            right={
              <DepositInstanceFormModal
                buttonIcon="tool"
                buttonType="default"
                buttonText="Configurazione"
                instanceId={String(instance.id)}
              />
            }
          />
        ) : loading ? null : (
          <DepositInstanceFormModal
            buttonType="primary"
            buttonIcon="plus"
            instanceId="create"
          />
        )
      }
    >
      <Scrollable spacing="inside">
        {/* Indirizzi di connessione  */}
        <Row>
          <Col span={12}>
            <LabeledText
              label="Indirizzo di Frontend dell'Istanza Applicativa"
              text={
                <a target="_blank" href={instance?.frontendUrl}>
                  {instance?.frontendUrl}
                </a>
              }
            />
          </Col>
          <Col span={12}>
            <LabeledText
              label="Indirizzo delle API dell'Istanza Applicativa"
              text={
                <a target="_blank" href={instance?.apiUrl}>
                  {instance?.apiUrl}
                </a>
              }
            />
          </Col>
        </Row>

        {/* Report istanza  */}
        <Row gutter={24}>
          {monitoring?.type === 'Failure' && (
            <Col span={24}>
              <Alert
                type="error"
                message="Collegamento non riuscito"
                description={monitoring.error}
              />
            </Col>
          )}
          {monitoring &&
            createStatisticSpaceUsedFromMonitoring(
              monitoring
            ).map((data, key) => (
              <StatisticSpaceUsedItem
                data={data}
                key={key}
                dataLabel="Totale"
              />
            ))}
        </Row>
      </Scrollable>
    </PageLayout>
  );
}
