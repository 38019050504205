import { Col, Row } from 'antd';
import { RadioInputItem } from 'client/ui/form/input/RadioInputItem';
import { GlobalPolicyDepositState } from 'common/schema/GlobalConservationPolicy';
import React from 'react';

export interface IConservationStateRadioInputProps {
  name: string;
  disabled?: boolean;
}

export function ConservationStateRadioInput(
  props: IConservationStateRadioInputProps
) {
  const { name, disabled } = props;

  return (
    <RadioInputItem
      disabled={disabled}
      name={name}
      label="Stato Conservazione"
      options={[
        {
          value: GlobalPolicyDepositState.NotPreserved,
          label: 'Non Conservato'
        },
        { value: GlobalPolicyDepositState.Reject, label: 'Rifiutato' },
        {
          value: GlobalPolicyDepositState.Failed,
          label: 'Versamento Fallito'
        }
      ]}
    />
  );
}
