import React from 'react';
import clx from 'classnames';
import { Icon } from 'antd';
import './CustomCardTitle.scss';
import { IconVectorType, IconVector } from 'client/ui/icon/IconVector';

export interface CustomCardTitleProps {
  image?: IconVectorType;
  icon?: string;
  tag?: string;
  tagTheme?: 'outline' | 'filled';
  title?: React.ReactNode;
  titleLabel?: string;
}

/**
 * Elemento per gestire il titolo della PageCard (Titolo e/o altri elementi)
 */
export function CustomCardTitle(props: CustomCardTitleProps) {
  const theme = props.tagTheme ? props.tagTheme : 'outline';
  return (
    <div className="custom-card-title">
      <div className="row-container">
        {props.tag && (
          <div className="col-left">
            <div
              className={clx('title-tag', {
                'title-tag-filled': theme == 'filled'
              })}
            >
              {props.tag}
            </div>
          </div>
        )}
        {props.image && !props.icon && (
          <div className="col-left col-icon">
            <IconVector static image={props.image} />
          </div>
        )}
        {props.icon && (
          <div className="col-left col-icon">
            <Icon type={props.icon} className="header-icon" />
          </div>
        )}
        <div className="col-left">
          <div className="title-text">
            {props.titleLabel && (
              <span className="title-text-label">{props.titleLabel} </span>
            )}
            {props.title}
          </div>
        </div>
      </div>
    </div>
  );
}
