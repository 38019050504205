import * as React from 'react';
import { IDeposit } from 'common/schema/Deposit';
import { Button, message, Popconfirm } from 'antd';
import { useApiMutationState } from 'client/core/network/hooks/useApiMutation';
import { DepositApi } from '../DepositApi';
import { DepositLogic } from 'common/logic/DepositLogic';

export interface IDepositSendButtonProps {
  deposit: IDeposit;
}

/**
 * Avvia il versamento
 */
export function DepositSendButton(props: IDepositSendButtonProps) {
  const { deposit } = props;

  const [send, { loading }] = useApiMutationState(DepositApi.send, {
    data: { id: deposit.id },
    invalidates: [DepositApi.findOne]
  });

  const handleSend = async () => {
    try {
      await send({});
      message.success('Il versamento è stato avviato con successo.');
    } catch (e) {
      message.error("Si è verificato un errore durante l'avvio.");
      console.error(e);
    }
  };

  if (!DepositLogic.isSendable(deposit)) return null;

  return (
    <>
      <Popconfirm
        placement="top"
        title={`Confermi di voler avviare il versamento #${deposit.id} riguardante ${deposit.dossiersCount} fascicoli?`}
        okText="Conferma e Avvia"
        cancelText="Annulla"
        okButtonProps={{ type: 'primary' }}
        onConfirm={handleSend}
      >
        <Button type="primary" icon="play-circle" loading={loading}>
          Avvia Versamento
        </Button>
      </Popconfirm>
    </>
  );
}
