import * as React from 'react';
import { Button, notification, Icon } from 'antd';
import { useApiMutationState } from 'client/core/network/hooks/useApiMutation';
import { CurrentInstanceApi } from '../CurrentInstanceApi';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { CcTooltip } from 'client/ui/tooltip/CcTooltip';

export enum ButtonTypeProps {
  icon = 'icon',
  button = 'button'
}
export interface ICurrentInstanceSyncButtonProps {
  instanceId: string;
  type?: ButtonTypeProps;
}

export function CurrentInstanceSyncButton(
  props: ICurrentInstanceSyncButtonProps
) {
  const { instanceId } = props;
  const notificationKey = `sync-current-${instanceId}`;

  const [sync, syncState] = useApiMutationState(CurrentInstanceApi.sync, {
    data: { id: instanceId },
    invalidates: [CurrentInstanceApi.find, CurrentInstanceApi.findAreas]
  });

  const handleSync = async () => {
    try {
      notification.info({
        key: notificationKey,
        duration: 0,
        message: 'Sincronizzazione',
        description: `E' in corso la sincronizzazione dell'istanza...`,
        icon: <Icon type="loading-3-quarters" spin />
      });
      await sleep(2000);
      await sync({});
      notification.success({
        key: notificationKey,
        duration: 3,
        message: 'Sincronizzazione',
        description: 'Sincronizzazione completata'
      });
    } catch (e) {
      console.error(e);
      notification.error({
        key: notificationKey,
        duration: 3,
        message: 'Sincronizzazione',
        description: `Impossibile completare l'aggiornamento. Si è verificato un errore. ${getNetworkErrorMessage(e)}` // prettier-ignore
      });
    }
  };

  if (props.type && props.type == ButtonTypeProps.icon) {
    return (
      <CcTooltip placement="topLeft" title="Aggiorna Dati">
        <Button icon="sync" disabled={syncState.loading} onClick={handleSync} />
      </CcTooltip>
    );
  }

  return (
    <Button
      icon="sync"
      type="primary"
      loading={syncState.loading}
      onClick={handleSync}
    >
      Aggiorna Dati
    </Button>
  );
}

const sleep = (ms: number) =>
  new Promise(resolve => {
    setTimeout(resolve, ms);
  });
