import React from 'react';
import { LoaderSpinProps, LoaderSpin } from './LoaderSpin';
import { PageLayout } from '../layout/PageLayout';

export interface LoadingPageProps extends LoaderSpinProps {}

export function LoadingPage(props: LoadingPageProps) {
  const { ...otherProps } = props;

  return (
    <PageLayout hasMenu hasScrollable>
      <LoaderSpin {...otherProps} transparent />
    </PageLayout>
  );
}
