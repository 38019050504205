import React from 'react';
import './RowScroller.scss';
import { Col, Row } from 'antd';

export interface RowScrollerProps {
  /**
   * fixedTop? - Per inserire un elemento non scrollabile (fisso)
   * SOPRA l'elemento sceollabile
   */
  fixedTop?: React.ReactNode;
  /**
   * fixedBottom? - Per inserire un elemento non scrollabile (fisso)
   * SOTTO l'elemento sceollabile
   */
  fixedBottom?: React.ReactNode;
  /**
   * children - Richiesto
   */
  children: React.ReactNode;
}

/**
 * Wrapper per lo scrolling
 * Contiene solo la Row che contiene la Col scrollabile
 * Permette di gestire più colonne affiancate
 * invece di una sola come nel caso di Srollable
 *
 * L'altezza totale dell'elemento è definita tramite css heigh: 100%
 */
export function RowScroller(props: RowScrollerProps) {
  return (
    <>
      {props.fixedTop && (
        <Row type="flex">
          <Col span={24}>{props.fixedTop}</Col>
        </Row>
      )}
      <Row type="flex" className="scrollable-row">
        {props.children}
      </Row>
      {props.fixedBottom && (
        <Row type="flex">
          <Col span={24}>{props.fixedBottom}</Col>
        </Row>
      )}
    </>
  );
}
