import { PermissionsType } from './Permissions';

export class Role<T extends string> {
  constructor(
    public name: T,
    public displayName: string,
    public permissions: PermissionsType[]
  ) {}

  /**
   * Controlla se un ruolo ha a disposizione il permesso specificato.
   */
  public hasPermission(permission: PermissionsType) {
    return this.permissions.includes(permission);
  }
}
