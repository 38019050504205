import React from 'react';
import { Row, Col, Alert } from 'antd';
import { CardDashboard } from 'client/ui/card/CardDashboard';
import { StatisticSpaceUsedItem } from 'client/ui/statistic/StatisticSpaceUsedItem';
import { IconVector } from 'client/ui/icon/IconVector';
import { IDashboardCurrentInstanceData } from 'common/schema/Dashboard';

export interface DashboardCurrentDetailProps {
  currentDetail: IDashboardCurrentInstanceData;
}

/**
 * Elemento per mostrare i dati dell'archivio corrente nella dashboard
 *
 */
export function DashboardCurrentDetail(props: DashboardCurrentDetailProps) {
  const { currentDetail } = props;
  return (
    <CardDashboard
      title={
        <span>
          <IconVector image="instance" /> Archivio Corrente
        </span>
      }
    >
      <Row gutter={12}>
        <Col span={12}>
          <Row gutter={12}>
            <StatisticSpaceUsedItem
              data={{
                title: 'Istanze',
                number: currentDetail.totalInstance
              }}
              type="compact"
              key={1}
              dataLabel="Totale"
              column={1}
            />
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={12}>
            <StatisticSpaceUsedItem
              data={{
                title: 'A.O.O.',
                number: currentDetail.totalAoo
              }}
              type="compact"
              key={2}
              dataLabel="Totale"
              column={1}
            />
          </Row>
        </Col>
      </Row>
    </CardDashboard>
  );
}
