import React from 'react';
import { Row, Col, Collapse, Alert, Card, Statistic, Divider } from 'antd';
const { Panel } = Collapse;
import { RouteComponentProps } from 'react-router';
import { LabeledText } from 'client/ui/labeled-text/LabeledText';
import { StatisticSpaceUsedItem } from 'client/ui/statistic/StatisticSpaceUsedItem';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { CustomCardExtra } from 'client/ui/layout/page-card/CustomCardExtra';
import { Scrollable } from 'client/ui/scroller/Scrollable';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { createStatisticSpaceUsedFromMonitoring } from 'client/ui/statistic/createStatisticSpaceUsedFromMonitoring';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { DashboardCurrentDetail } from './detail/DashboardCurrentDetail';
import { DashboardDepositDetail } from './detail/DashboardDepositDetail';
import { DashboardConservationDetail } from './detail/DashboardConservationDetail';
import { DashboardApi } from './DashboardApi';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { AlertPage } from 'client/ui/alert/AlertPage';

export interface DashboardPageProps
  extends RouteComponentProps<{ instanceId: string }> {}

/**
 * Pagina Dashboard del Centro di Gestione
 */
export function DashboardPage(props: DashboardPageProps) {
  // const instanceId = props.match.params.instanceId;
  const { response, error, loading } = useApiQuery(DashboardApi.getData, {
    data: {}
  });

  const result = response?.data;

  // const instance = response?.data.instance;
  // const monitoring = response?.data.monitoring;

  if (error) {
    return (
      <AlertPage
        type="error"
        message="Impossibile caricare la dashboard. Riprovare."
      />
    );
  }

  if (loading || !result) return <LoadingPage />;

  return (
    <PageLayout
      hasScrollable
      hasMenu
      loading={loading || !result}
      // title={<CustomCardTitle image="home" title="Dashboard" />}
    >
      <Scrollable spacing="inside">
        <div style={{ padding: '24px' }}>
          <Row gutter={36}>
            <Col span={12}>
              <DashboardCurrentDetail currentDetail={result.instances} />
            </Col>
            <Col span={12}>
              <DashboardConservationDetail
                conservationDetail={result.conservation}
              />
            </Col>
          </Row>

          {/* <Row gutter={36}>
            <Col span={6}>
              <DashboardCurrentDetail currentDetail={result.instances} />
            </Col>
            <Col span={18}>
              <DashboardDepositDetail depositDetail={result.deposit} />
            </Col>
          </Row> */}

          <Row gutter={36} style={{ marginTop: '36px' }}>
            <Col span={24}>
              <DashboardDepositDetail depositDetail={result.deposit} />
            </Col>
          </Row>
        </div>
      </Scrollable>
    </PageLayout>
  );
}
