import { IGlobalConservationPolicyProcess } from './GlobalConservationPolicyProcess';

export interface IGlobalConservationPolicy {
  id: number;
  description: string;

  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;

  processes?: IGlobalConservationPolicyProcess[];

  lastProcessId?: number | null;
  lastProcess?: IGlobalConservationPolicyProcess | null;

  // Campi parallelo P3
  code: string;
  active: boolean;
  periodicity?: GlobalPolicyPeriodType;
  executionDate?: Date;
  /** (A) Protocollo in Arrivo */
  protocolIncoming: boolean;
  /** (P) Protocollo in uscita */
  protocolOutgoing: boolean;
  /** Protocollata interno */
  protocolInternal: boolean;
  /** Grigio */
  grey: boolean;
  typology?: string;
  format?: string[];

  signed: boolean;
  marked: boolean;
  notSigned: boolean;
  notMarked: boolean;

  expirationDateFilterType?: GlobalPolicyExpirationType;

  createdAtFilterType?: PolicyInterestDateType;
  createdAtFrom?: Date;
  createdAtTo?: Date;
  /** giorni quando tipo data creazione = n. giorni prima */
  createdAtDaysRange?: number;

  protocolDateFilterType?: PolicyInterestDateType;
  protocolDateFrom?: Date;
  protocolDateTo?: Date;
  /** numero di giorni data protocollo se tipo di protocollo = P */
  protocolDateDaysRange?: number;

  /* n. giorno di esecuzione (da 1 a 7 oppure da 1 a 31 (?)) */
  executionDay?: number;
  /* n. mese di esecuzione (da 1 a 12) */
  executionMonth?: number;

  type?: GlobalPolicyType;

  printDateFilterType?: PolicyInterestDateType;
  printDateFrom?: Date;
  printDateTo?: Date;
  printYear?: number;
  /** numero di giorni data stampa se tipo di protocollo = P */
  printDateDaysRange?: number;

  /** null (non conservato), R (rifiutato), F(fallito)*/
  depositState?: GlobalPolicyDepositState | null;
}

export enum GlobalPolicyType {
  /** Documento */
  Document = 'D',
  /** Stampa */
  Stamp = 'S'
}

export enum GlobalPolicyPeriodType {
  /** Giornaliera */
  Daily = 'D',
  /** Settimanale */
  Weekly = 'W',
  /** Mensile */
  Monthly = 'M',
  /** Annuale */
  Yearly = 'Y',
  /** Data Fissata */
  Fixed = 'O'
}

export enum GlobalPolicyExpirationType {
  /** Scaduti */
  Expired = 'E',
  /** Entro la settimana corrente */
  Week = 'W',
  /** Entro il mese corrente */
  Month = 'M',
  /** Entro l'anno corrente */
  Year = 'Y'
}

export enum PolicyInterestDateType {
  /* Valore Singolo */
  Single = 'S',
  /* Intervallo */
  Range = 'R',
  /* Oggi */
  Today = 'T',
  /* Settimana Corrente */
  Week = 'W',
  /* Mese Corrente */
  Month = 'M',
  /* Anno Corrente */
  Year = 'Y',
  /* Ieri */
  Yesterday = 'B',
  /* Settimana Precedente */
  LastWeek = 'V',
  /* Mese Precedente */
  LastMonth = 'N',
  /* Anno Precedente */
  LastYear = 'X',
  /* n giorni prima */
  DaysBefore = 'P'
}

export enum GlobalPolicyDepositState {
  /** Non conservato (null su P3) */
  NotPreserved = 'NotPreserved',
  /** Rifiutato */
  Reject = 'R',
  /** Fallito */
  Failed = 'F'
}
