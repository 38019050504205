import React, { useCallback } from 'react';
import { FormikValues, useField, useFormikContext } from 'formik';
import Select, { SelectProps, OptionProps, SelectValue } from 'antd/lib/select';

interface SelectOption extends OptionProps {
  label: React.ReactNode;
}

export interface SelectInputProps<T extends FormikValues>
  extends Omit<SelectProps, 'name'> {
  name: keyof T & string;
  options?: SelectOption[];
  children?: React.ReactNode;
  onAfterChange?: (value?: any) => void;
}

/**
 * Campo per input di tipo select, con opzioni fornibili direttamente.
 */
export function SelectInput<T extends FormikValues>(
  props: SelectInputProps<T>
) {
  const { name, options, children, onAfterChange, ...otherProps } = props;
  const [field, meta] = useField(name);
  const formik = useFormikContext<T>();
  const handleChange = useCallback(
    (value: SelectValue) => {
      formik.setFieldValue(name, value);
      onAfterChange?.(value);
    },
    [name]
  );
  const handleBlur = () => formik.setFieldTouched(name);

  return (
    <Select
      name={name}
      {...otherProps}
      {...field}
      onChange={handleChange}
      onBlur={handleBlur}
    >
      {options &&
        options.map(option => (
          <Select.Option key={option.value} {...option}>
            {option.label}
          </Select.Option>
        ))}
      {children}
    </Select>
  );
}
